@import '../../../variables';

$copy-field-Bg: $bg_clr0;

.copy-field-container {
  display: inline-block;
  position: relative;
  overflow: hidden;
  opacity: 0;

  &.label-right {
    align-self: flex-end;
  }

  > div:not(.cb-txt) {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    padding: 5px;
    height: 100%;
    background-color: $copy-field-Bg;
    font-family: Roboto-Light, sans-serif;
    font-size: 0.9rem;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33em;
    text-decoration: underline;

    &:hover {
      color: $txt_clr8;
    }
  }

  input {
    position: absolute;
    z-index: 1;
    width: 1px;
    height: 1px;
    //left: 200vh;
  }

  .cb-txt {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    color: $txt_clr0;
    border-radius: 4px;
    font-family: Roboto-Light, sans-serif;
    font-size: 0.9rem;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33em;
    opacity: 0;
  }

  &.copied {
    .cb-txt {
      opacity: 1;
      z-index: 3;
    }
  }
}
