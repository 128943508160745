@import '../../../variables.scss';

.custom-modal.tax-bill-modal {
  .modal-body {
    max-width: 500px;
    width: 100%;
    color: $txt_clr4;

    p {
      margin-bottom: 0;
    }
  }

  .present-description {
    display: flex;
    flex-direction: column;
    white-space: pre-line;

    .modal-header {
      margin-bottom: 16px;
      word-break: break-word;
    }

    .name-service {
      @include font(Roboto-Medium, 1.25rem, 500, normal, normal, 1.75rem, normal);
      margin-bottom: 24px;
      word-break: break-word;
    }

    .txt-service {
      margin-bottom: 0;

      p {
        margin-bottom: 0;
      }
    }
  }
}
