@import '../../../variables';

.main-header {
  position: relative;
  z-index: $headerMenuZ;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 76px;
  width: 100%;
  box-shadow: 0 4px 6px 0 rgba(5, 11, 51, 0.11);

  &.no-shadow {
    box-shadow: none;
  }

  .default-select-list .custom-select .ant-select-arrow {
    top: 43%;
  }

  .custom-hint {
    display: none;
  }

  .mobile-view {
    display: none;
  }

  nav {
    width: $pcBlock;
    background: $bg_clr0;
    height: 100%;
    display: flex;
    flex-direction: row;

    .dropdown-menu {
      position: relative;
      cursor: pointer;

      &:hover {
        .dropdown-block {
          visibility: initial;
        }
      }

      .dropdown-block {
        visibility: hidden;
        position: absolute;
        top: 76px;
        left: -10px;
        display: flex;
        flex-direction: column;
        background-color: $bg_clr0;
        z-index: 100;
        min-height: 524px;
        box-shadow: 0px 2px 3px rgba(5, 11, 51, 0.2);
        border: 1px solid $brdr_clr18;
        padding-top: 12px;

        @media only screen and (max-width: $tabletScreen) {
          left: -168px;
        }

        .dropdown-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: $bg_clr0;
          min-height: 50px;
          max-height: 50px;
          width: 274px;
          padding: 0 20px;

          .country-item {
            display: flex;
            flex-direction: column;

            .item-title {
              @include font(Roboto-Regular, 1rem, 400, normal, normal, 1.125rem, normal);
              color: $txt_clr4;
              margin-bottom: 4px;
            }
          }

          .count-hotels {
            @include font(Roboto-Regular, 0.8125rem, 400, normal, normal, 1rem, normal);
            color: $txt_clr22;
          }

          p {
            margin-bottom: 0;
          }

          svg {
            display: none;
          }

          &:hover {
            background-color: $bg_clr13;

            svg {
              display: initial;
            }
          }

          &:hover > .regions-list {
            display: flex;
          }

          .regions-list {
            position: absolute;
            left: 100%;
            top: -1px;
            background-color: $bg_clr0;
            min-width: 270px;
            min-height: 100%;
            display: none;
            flex-direction: row;
            border: 1px solid $brdr_clr18;

            .left-col {
              border-right: 1px solid $brdr_clr18;
            }

            .list-col {
              display: flex;
              flex-direction: column;
              padding-top: 12px;
            }

            .region-item {
              margin-left: 0;
              background-color: $bg_clr0;
              height: 50px;
              max-height: 50px;
              width: 270px;
              max-width: 270px;
              transition: background-color 300ms;
              -webkit-transition: background-color 300ms;

              &:hover {
                background-color: $bg_clr13;
                transition: background-color 300ms;
                -webkit-transition: background-color 300ms;
                cursor: pointer;

                a {
                  color: $txt_clr9;
                  transition: color 300ms;
                  -webkit-transition: color 300ms;

                  .count-hotels {
                    transition: color 300ms;
                    -webkit-transition: color 300ms;
                  }
                }
              }

              a {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                height: 100%;
                transition: color 300ms;
                min-width: 0;
                padding: 0 20px;
                word-break: break-word;
                -webkit-transition: color 300ms;
                @include font(Roboto-Regular, 0.875rem, 400, normal, normal, 1.125rem, normal);

                .title-item {
                  margin-bottom: 4px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  max-width: 215px;
                  white-space: nowrap;
                }

                .count-hotels {
                  display: flex;
                  align-items: center;
                  @include font(Roboto-Regular, 0.8125rem, 400, normal, normal, 1rem, normal);
                  width: 100%;
                  transition: color 300ms;
                  padding-top: 2px;
                  -webkit-transition: color 300ms;
                }
              }
            }
          }
        }
      }
    }
  }

  .default-select-list {
    margin: 0;

    .custom-select {
      &:after {
        right: 0;
      }

      .ant-select-selector {
        box-shadow: none;
        min-height: initial;
        margin: 0 22px 0 0;
        padding: 0;
      }
    }
  }

  .header-drop-down {
    box-shadow: none;

    &.ant-select-disabled {
      &:after {
        display: none;
      }

      .ant-select-selector {
        background-color: transparent;
      }
    }
  }

  .main-logo {
    @include centerItems();
    width: 204px;
    height: 100%;
    cursor: pointer;
    margin-right: auto;
  }

  ul {
    display: flex;
    flex-direction: row;
    margin: 0;

    li {
      &:first-child {
        margin: 0;
      }

      height: 100%;
      margin-left: 24px;
      display: flex;
      align-items: center;
    }

    .link-delimiter {
      flex-direction: column;
      align-items: center;
      margin: 0 102px 0 102px;
      padding-bottom: 16px;

      .phone-container {
        margin-top: auto;
        display: flex;
        flex-direction: row;
        align-items: center;

        a {
          @include centerItems();
        }

        .menu-phone {
          font-family: Roboto-bold, sans-serif;
          font-size: 1.125rem;
          line-height: 1.17em;
          white-space: pre;
        }

        + span {
          font-family: Roboto-Light, sans-serif;
          font-size: 0.75rem;
          line-height: 1.17em;
          font-weight: 300;
        }
      }
    }
  }
}

@media only screen and (max-width: $tabletScreen) {
  .main-header {
    .custom-hint {
      display: flex;
    }

    .main-logo {
      width: initial;
      justify-content: flex-start;

      svg {
        height: 20px;
        width: 160px;
      }
    }

    nav {
      width: $tabletBlock;
    }

    ul {
      .link-delimiter {
        margin: 0 28px 0 35px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .phone-container {
          height: 100%;

          + span {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mobileScreen) {
  .main-header {
    width: 100%;
    height: 49px;

    .main-logo {
      margin-right: initial;
      width: initial;

      svg {
        height: 20px;
        width: 150px;
      }
    }

    .mobile-menu {
      height: 20px;
      font-size: 20px;
    }

    .mobile-view {
      display: block;
    }

    nav {
      width: 100%;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }

    ul {
      li {
        display: none;
      }

      .link-delimiter {
        display: flex;
        margin: 0;
        padding: 0;

        .phone-container {
          .menu-phone {
            margin-top: 2px;
            font-size: 1.12rem;
            line-height: 1.166em;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .main-header {
    .main-logo {
      svg {
        height: 14px;
        width: 100px;
      }
    }

    .mobile-menu {
      height: 20px;
      font-size: 20px;
    }

    ul {
      .link-delimiter {
        .phone-container {
          .menu-phone {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}

.regions-list-menu {
  width: 200px !important;
}

.discount-menu-container {
  position: relative;
  cursor: pointer;

  > div > svg {
    margin-left: 10px;
    transition: all 0.5s ease;
  }

  &:hover {
    .discount-menu {
      height: 124px;
      max-height: initial;
    }
  }
}

.discount-menu {
  position: absolute;
  left: -10px;
  top: 76px;
  z-index: 100;

  width: 274px;
  padding: 0 1px;
  height: 0;
  overflow: hidden;
  box-shadow: 0 2px 3px rgba(5, 11, 51, 0.2), inset 0 0 0 1px $brdr_clr18;
  background-color: $bg_clr0;

  display: flex;
  flex-direction: column;
  transition: height 0.3s;

  a {
    @include font(Roboto-Regular, 1rem, 400, normal, normal, 1.125rem, normal);

    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
    padding: 0 20px;
    color: $txt_clr4;
    width: 100%;

    &:first-of-type {
      margin-top: 12px;
    }

    &:last-of-type {
      margin-bottom: 12px;
    }

    svg {
      display: none;
      margin-left: 16px;
    }

    &:hover {
      background-color: $bg_clr13;
      color: $txt_clr9;

      svg {
        display: initial;
      }
    }
  }
}

.center-container {
  padding: 5px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white1;
}

.link-contact {
  display: flex;
  align-items: center;

  .employee-img {
    min-width: 48px;
    min-height: 48px;
    border-radius: 50%;
    background-color: #e3eff7;
    margin-right: 18px;
    position: relative;
    overflow: hidden;

    img {
      min-width: 48px;
      max-width: 48px;
      min-height: 48px;
      max-height: 48px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .contact-data {
    .phone-number {
      display: flex;
      align-items: center;
      font-size: 1.12rem;
      color: $txt_clr10;
    }

    .working-time {
      font-size: 0.81rem;
      color: $txt_clr4;
      margin: 0;
    }
  }
}
