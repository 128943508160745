@import '../../variables';

.cancelInfo {
  margin: 0 0 6px 0;
  position: relative;
  padding-left: 18px;
  @include font(Roboto-Regular, 0.9rem, normal, normal, normal, 1.27em, normal);
  color: $txt_clr13;

  &-bold {
    font-weight: bold;
    color: $txt_clr13;
  }

  &-withIcon {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      background-image: url('../../images/icons/green-arrow.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      @include fixed-size(15px);
    }
  }

  &-item {
    margin-bottom: 6px;
  }
}
