@import '../../../variables.scss';

.cancel-reservation-page,
.payment-result-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  background-color: $bg_clr2;

  .cancel-card {
    position: relative;
    display: flex;
    flex-direction: row;
    width: $pcBlock;
    min-height: 500px;
    margin-top: 40px;
    box-shadow: 0 2px 16px 0 rgba(21, 31, 99, 0.22);
    border-radius: $inputRadius;
    overflow: hidden;
    margin-bottom: 20px;

    &.order-done {
      margin: 40px 0;

      .left-section h3 {
        margin-bottom: 0;
      }

      h3.mr {
        margin-bottom: 1rem;
      }
    }

    .preloader-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0;
      min-height: initial;
      background: rgba(0, 0, 0, 0.5);
    }

    .left-section {
      padding: 24px;
      min-width: 384px;
      max-width: 384px;
      background-color: $bg_clr1;

      h2 {
        font-family: Roboto-Medium, sans-serif;
        font-size: 1.5rem;
        line-height: 1.666em;
        color: $txt_clr5;
        margin-bottom: 16px;
      }

      h3 {
        font-family: Roboto-Medium, sans-serif;
        font-size: 1.125rem;
        line-height: 1.127em;
        color: $txt_clr6;
        padding-top: 16px;

        > :last-child {
          font-family: Roboto-Light, sans-serif;
        }

        > :first-child {
          font-family: Roboto-Medium, sans-serif;
          margin-bottom: 0;
        }

        & + p {
          padding-top: 3px;
          font-size: 1.125rem;
          line-height: 1.127em;
          color: $txt_clr6;
        }
      }

      .main-txt {
        font-size: 1.25rem;
        line-height: 1.3em;
        color: $txt_clr6;
      }
    }

    .right-section {
      max-width: 816px;
      flex-grow: 1;
      padding: 24px;
      background-color: $bg_clr0;

      .reservation-data-form {
        margin-top: 24px;

        .inputs-row {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;

          .custom-input {
            width: 372px;

            .custom-input-title {
              margin-bottom: 10px;
              font-size: 1.1rem;
            }

            .custom-input-description {
              margin-top: 10px;
              font-size: 0.8rem;
              color: $txt_clr2;
            }
          }
        }

        .custom-btn {
          height: 48px;
          margin-top: 26px;
          min-width: 200px;
        }
      }

      .sections-txt {
        font-size: 1rem;
        line-height: 1.312em;
        color: $txt_clr5;

        h4 {
          font-family: Roboto-Medium, sans-serif;
          margin-top: 10px;
          margin-bottom: 23px;

          &.m-0 {
            margin: 0;
          }

          & + div {
            display: flex;
            flex-direction: column;
            margin-bottom: 45px;

            span,
            p {
              line-height: 1.5em;
            }
          }
        }

        &.cancel-results {
          text-align: center;

          a {
            color: $txt_clr9;
            text-decoration: underline;
          }
        }
      }

      .sections-footer {
        padding-top: 5px;

        h4 {
          font-family: Roboto-Medium, sans-serif;
          font-size: 1.125rem;
          line-height: 1.277em;
          color: $txt_clr6;
          margin-bottom: 16px;
        }

        .custom-empty-btn {
          margin-right: 24px;
        }

        button {
          min-width: 200px;
          height: 48px;
          padding: 0 20px;
        }
      }
    }

    .cancelation-fees {
      p {
        margin-bottom: 0;
      }

      :first-child {
        margin-bottom: 1rem;
      }
    }
  }

  .help-msg-container {
    width: $pcBlock;
    color: $txt_clr10;
    margin-top: 20px;
    padding: 24px;

    :last-child {
      margin-bottom: 0;
    }

    h3 {
      color: $txt_clr10;
      font-size: 1.25rem;
      line-height: 1.4em;
      margin-bottom: 5px;
    }

    p {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 1rem;
      line-height: 1.437em;
      margin-bottom: 9px;

      .icon-container {
        @include centerItems();
      }

      span.fat-font {
        font-family: Roboto-Medium, sans-serif;
        margin-left: 5px;
      }

      .letter {
        border: 1px solid blue;
        margin: 0 10px 0 32px;
      }
    }
  }
}

.payment-result-page {
  .cancel-card {
    .left-section {
      h2 {
        margin-bottom: 5px;
      }

      h3 {
        padding-top: 4px;
      }
    }

    .right-section {
      position: relative;

      .preloader-bg {
        margin: 0;
        top: 0;
        left: 0;
      }

      h2.color {
        display: flex;
        flex-direction: column;
        font-family: Roboto-Regular, sans-serif;
        color: $txt_clr9;
        font-size: 1.5rem;
        line-height: 1.136em;
        margin-bottom: 20px;

        > :first-child {
          font-family: Roboto-Medium, sans-serif;
          margin-bottom: 15px;
        }
      }

      .sections-txt {
        > :last-child {
          font-family: Roboto-Regular, sans-serif;
          margin-bottom: 0;
        }
      }

      .info-text {
        font-size: 1.2rem;
        line-height: 1.7em;
        margin-bottom: 12px;
      }
    }
  }
}

@media only screen and (max-width: $tabletScreen) {
  .cancel-reservation-page,
  .payment-result-page {
    .cancel-card {
      width: $tabletBlock;

      .right-section {
        max-width: 591px;

        .reservation-data-form {
          .inputs-row {
            flex-direction: column;

            .custom-input {
              width: 100%;
            }

            > :last-child {
              margin-top: 20px;
            }
          }
        }

        .sections-txt {
          font-size: 0.937rem;

          h4 + p span {
            line-height: 1.266em;
          }
        }
      }
    }

    .help-msg-container {
      width: $tabletBlock;
    }
  }
}

@media only screen and (max-width: $mobileScreen) {
  .cancel-reservation-page,
  .payment-result-page {
    .mobile-hide {
      display: none;
    }

    .cancel-card {
      width: 100%;
      padding: 0 16px;
      margin-top: 26px;
      min-height: initial;
      box-shadow: none;
      background: transparent;
      flex-direction: column-reverse;

      .preloader-bg {
        padding: 0 16px;
        right: 0;
        top: 0;
      }

      .left-section,
      .right-section {
        width: 100%;
        padding: 0;
        min-width: initial;
        max-width: initial;
      }

      .left-section {
        background: transparent;

        h2 {
          font-size: 1.375rem;
          line-height: 1.136em;
          margin-bottom: 8px;

          & + h3 {
            padding-top: 0;
          }
        }

        .main-txt {
          font-size: 1.125rem;
          line-height: 1.166em;
        }
      }

      .right-section {
        .reservation-data-form {
          .inputs-row {
            flex-wrap: wrap;

            > :first-child {
              margin-bottom: 22px;
            }

            .custom-input {
              width: 100%;

              .custom-input-description {
                color: $txt_clr4;
              }
            }
          }

          .custom-btn {
            width: 100%;
            padding: 0 15px;
            min-height: 48px;
            height: initial;
            min-width: initial;
          }
        }

        .sections-txt {
          font-size: 0.875rem;

          h4 + div {
            line-height: 1.357em;

            span {
              line-height: 1.357em;
            }
          }
        }

        &.user-info {
          background: transparent;
          margin-bottom: 24px;
        }

        &.details,
        &.apply-cancellation {
          margin-bottom: 24px;
          padding: 24px;
          border-radius: $inputRadius;

          .sections-footer {
            flex-direction: column;

            .custom-empty-btn {
              margin: 0 0 22px 0;
            }

            button {
              width: 100%;
              padding: 0 15px;
              min-height: 48px;
              height: initial;
              min-width: initial;
            }
          }
        }

        &.apply-cancellation {
          margin-top: 0;
          padding: 24px;

          .sections-footer {
            flex-wrap: wrap;

            h4 {
              text-align: center;
            }
          }
        }
      }
    }

    .help-msg-container {
      width: 100%;

      h3 {
        text-align: center;
      }

      p {
        flex-direction: column;
        word-break: break-word;

        .letter {
          margin: 0 5px 0 0;
        }
      }
    }
  }
  .payment-result-page {
    .cancel-card {
      .right-section {
        h2.color {
          font-size: 1.375rem;
          margin-bottom: 20px;
        }

        .info-text {
          font-size: 1.125rem;
          margin-bottom: 15px;
        }
      }
    }
  }
}
