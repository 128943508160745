@import '../../../variables.scss';

.contacts-page {
  .footer-block {
    padding-top: 80px;
  }
}

@media only screen and (max-width: $mobileScreen) {
  .contacts-page {
    .employee-card {
      display: none;
    }

    .footer-block {
      padding-top: 20px;
      .right-block {
        .main-block-header {
          font-size: 1.125rem;
          line-height: 1.125em;
        }
      }
    }
  }
}
