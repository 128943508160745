@import '../../../variables.scss';

.error-border {
  .ant-input {
    border: 1px solid $brdr_clr11 !important;
  }
}

.custom-input {
  $defaultInpHeight: 48px;

  .ant-input {
    height: $defaultInpHeight;
    border: 1px solid $brdr_clr3;
    border-radius: 2px;

    &:hover {
      border-color: $brdr_clr3;
    }

    &:focus {
      box-shadow: none;
    }
  }
}
