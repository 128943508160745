@import '../../../variables.scss';

.check-box-wrapper {
  .ant-checkbox-wrapper {
    border: none;
    @include centerItems();
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: none;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border: none;
  }

  &.sm.hide_txt {
    margin-right: 11px;
  }

  &.hide_txt {
    .ant-checkbox + span {
      padding: 0;
    }
  }

  &.sm {
    //line-height: 12px;
    height: auto;

    .ant-checkbox {
      width: 14px;
      height: 14px;
    }

    .ant-checkbox-wrapper {
      display: initial;
    }

    .ant-checkbox-inner {
      width: 100%;
      height: 100%;

      &:after {
        top: 46%;
        width: 5px;
        height: 9px;
      }
    }
  }

  .ant-checkbox {
    border-radius: 2px;
    margin-left: 1px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 2px 0 $brdr_clr14;
  }

  &.box-error {
    .ant-checkbox {
      box-shadow: none;
      border: solid 4px $brdr_clr11;
    }
  }

  .ant-checkbox-inner {
    transition: none;
    background: transparent;
    border-radius: initial;
    border: none;

    width: 100%;
    height: 100%;

    &:after {
      top: 43%;
      width: 7px;
      height: 15px;
    }
  }

  .ant-checkbox-checked {
    border-color: $brdr_clr10;
  }

  .ant-checkbox-checked::after {
    border-color: $brdr_clr10;
    border-radius: initial;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $brdr_clr10;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $bg_clr11;
    border-color: $brdr_clr10;
  }
}
