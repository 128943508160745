.offer-room {
  & .offer-date {
    display: flex;
    width: 100%;
    color: $txt_clr9;
    font-weight: bold;
    font-size: 2rem;
    line-height: 100%;
    text-align: left;

    & span {
      display: block;
      margin-right: 10px;
    }

    @media only screen and (max-width: $tabletScreen) {
      font-size: 1.75rem;
      flex-direction: column;

      & span {
        margin-bottom: 3px;
      }
    }

    @media only screen and (max-width: $mobileScreen) {
      font-size: 1.7rem;
    }
  }

  & .offer-hotel {
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    color: $txt_clr6;
    font-size: 2rem;
    line-height: 100%;

    @media only screen and (max-width: $mobileScreen) {
      font-size: 1.7rem;
    }
  }

  & .offer-packages {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: $txt_clr6;

    & .packages-hotel {
      margin: 0;
      display: flex;
      align-items: center;

      @media only screen and (max-width: $mobileScreen) {
        align-items: flex-start;
        justify-content: flex-start;
      }
    }
  }

  & .banner-profit {
    padding: 6px 10px;
    margin-right: 5px;
    width: max-content;
    position: relative;
    top: 0;
    left: 0;
    color: $txt_clr6;
    font-weight: bold;
  }

  & .hotel-name-rating {
    margin: 0;
    color: $txt_clr6;
    font-size: 2rem;
    line-height: 100%;

    @media only screen and (max-width: $tabletScreen) {
      font-size: 1.75rem;
    }

    @media only screen and (max-width: $mobileScreen) {
      font-size: 1.3rem;
    }
  }

  & .offer-detail {
    margin-bottom: 5px;
    padding: 15px;
    border: 1px solid #dae5ec;
    border-radius: 6px;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;

    @media only screen and (max-width: $mobileScreen) {
      padding: 0;
      border: none;
      box-shadow: none;
    }

    & .detail-title {
      width: 100%;
      color: $txt_clr9;
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 2rem;
      text-align: left;

      @media only screen and (max-width: $mobileScreen) {
        font-size: 1.2rem;
      }
    }

    & .detail-services {
      margin-bottom: 8px;
      width: 100%;
      color: $txt_clr6;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1rem;
      text-align: left;

      @media only screen and (max-width: $mobileScreen) {
        font-size: 0.8rem;
      }
    }

    & .detail-item {
      padding: 4px 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media only screen and (max-width: $mobileScreen) {
        font-size: 0.75rem;
      }

      & .detail-room {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media only screen and (max-width: $mobileScreen) {
          flex-wrap: wrap;
        }
      }

      &.marked {
        position: relative;
        padding-left: 24px;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          background-image: url('../../../images/icons/check_new.svg');
          background-position: center;
          background-repeat: no-repeat;
          display: block;
          min-width: 18px;
          width: 18px;
          min-height: 18px;
          height: 100%;
        }
      }

      & svg {
        margin-right: 8px;
      }

      & .detail-subItem {
        margin-right: 5px;

        &.room-name {
          margin-right: 10px;
          font-weight: bold;
        }
      }

      &:last-child {
        flex-direction: column;
        align-items: flex-start;
      }

      & .actions-popup-wrapper {
        span {
          text-decoration: underline;
        }
      }

      & .popup-action {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & .detail-meal {
        font-weight: bold;
      }

      & .detail-transfer {
        font-weight: bold;
      }

      & .price-block {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        & .tax-bill {
          display: flex;
          font-size: 0.85rem;

          @media only screen and (max-width: $mobileScreen) {
            font-size: 0.6rem;
          }

          & > span {
            margin-right: 5px;
          }

          & .popup-action {
            margin-right: 0;
            text-decoration: underline;
          }
        }

        & .total-price-block {
          padding: 6px 0;
          width: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          border-top: 3px solid #dae5ec;

          & .title-block,
          & .final-sum {
            font-weight: bold;
            font-size: 1.2rem;

            @media only screen and (max-width: $mobileScreen) {
              font-size: 1rem;
            }
          }

          & .final-sum {
            border: none;

            & .current-price,
            & .old-price {
              span {
                font-size: 1.35rem !important;

                @media only screen and (max-width: $mobileScreen) {
                  font-size: 1.2rem !important;
                }
              }
            }
          }

          & .final-cost {
            padding-left: 10px;
            min-width: 140px;
            display: block;
            text-align: left;

            @media only screen and (max-width: $mobileScreen) {
              min-width: 100px;
            }
          }
        }
      }
    }

    .detail-service {
      margin-top: 1rem;
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 4px 0;

      @media only screen and (max-width: $mobileScreen) {
        font-size: 0.75rem;
      }

      & svg {
        margin-right: 8px;
      }

      & .detail-service-title {
        font-weight: bold;
        color: $txt_clr9;
      }

      & .detail-service-package {
        font-weight: bold;
        font-size: 1.1rem;
      }
    }

    & .detail-item-price {
      padding: 0;
    }

    & .room-info {
      flex-direction: column;
      align-items: flex-start;
    }

    & .room-info-block {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: bold;
    }

    & .room-cost {
      width: 100%;
      padding-left: 20px;
      border-top: 1px solid #dae5ec;

      & > span {
        padding-top: 6px;
        display: block;
        font-weight: bold;
      }

      & .guests-count-block {
        padding: 6px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: $mobileScreen) {
          padding: 0;
        }

        &:not(:last-child) {
          border-bottom: 1px solid #dae5ec;
        }
      }

      & .prise-for-guest {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .current-price {
          span {
            font-weight: bold;
          }
        }

        .current-price,
        .old-price {
          span {
            font-size: 1rem !important;

            @media only screen and (max-width: $mobileScreen) {
              font-size: 0.85rem !important;
            }
          }
        }

        & .final-sum {
          border: none;
        }

        & .price-descr {
          padding-left: 10px;
          min-width: 140px;
          display: block;
          text-align: left;

          @media only screen and (max-width: $mobileScreen) {
            min-width: 100px;
          }
        }
      }
    }

    & .detail-days {
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: bold;

      @media only screen and (max-width: $mobileScreen) {
        margin-right: 3px;
      }
    }

    & .transfer-info {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      font-weight: bold;

      & .guest-count {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      }

      & .transfer-icon {
        display: block;
        padding-top: 2px;
      }

      & .transfer-details {
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      & .prise-for-guest {
        display: flex;
        align-items: center;

        span:first-child {
          @media only screen and (max-width: $mobileScreen) {
            font-size: 0.85rem;
          }
        }

        & .final-cost {
          padding-left: 10px;
          min-width: 140px;
          display: block;
          text-align: left;
          font-weight: normal;

          @media only screen and (max-width: $mobileScreen) {
            min-width: 100px;
          }
        }
      }
    }
  }

  & .offer-contacts {
    padding-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media only screen and (max-width: $tabletScreen) {
      padding: 20px 15px 15px;
      flex-direction: column;
    }

    @media only screen and (max-width: $mobileScreen) {
      padding-bottom: 0;
    }

    & .offer-tel {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media only screen and (max-width: $mobileScreen) {
        flex-direction: column;

        & span {
          margin-bottom: 5px;
        }
      }

      & a {
        margin-bottom: 15px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
      }

      @media only screen and (max-width: $tabletScreen) {
        padding-top: 20px;
        width: 100%;
        order: 2;
      }

      & .offer-contacts-info {
        margin-bottom: 15px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        font-size: 0.95rem;

        & .contact-img {
          height: 100%;

          img {
            max-height: 100px;
            height: 100%;
            width: auto;
          }
        }

        & .contacts-info {
          span {
            display: block;

            &:first-child {
              font-weight: bold;
            }
          }
        }
      }

      & .contacts-notice {
        font-size: 0.95rem;
        text-align: left;
      }
    }

    & .offer-online {
      width: 50%;

      @media only screen and (max-width: $tabletScreen) {
        width: 100%;
      }

      & .custom-empty-btn {
        margin-bottom: 15px;
        color: #ffffff;
        font-weight: bold;
        background-color: $txt_clr9;

        &:hover {
          background-color: $bg_clr6;
          border-color: $brdr_clr7;
        }
      }

      & .offer-promo {
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 0.95rem;
        min-height: 100px;

        @media only screen and (max-width: $tabletScreen) {
          min-height: auto;
        }
      }

      & .offer-notice {
        margin: 0;
        color: #d73f3f;
        font-weight: bold;
      }

      & .offer-code {
        margin: 0;

        & .offer-code-red {
          font-weight: bold;
          color: #d73f3f;
        }
      }

      .room-pros {
        .payment-list {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .payment-item {
          margin-bottom: 5px;
          position: relative;
          padding-left: 18px;
          @include font(Roboto-Regular, 0.95rem, normal, normal, normal, 1.27em, normal);
          color: $txt_clr13;

          &-bold {
            font-weight: bold;
          }

          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            background-image: url('../../../images/icons/green-arrow.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            @include fixed-size(15px);
          }

          &:last-child {
            &:before {
              background-image: url('../../../images/icons/flag.png');
              background-size: cover;
            }
          }
        }
      }
    }
  }
}

.service-list {
  padding: 3px 0 0 0;

  ul {
    padding-top: 12px;

    li {
      font-size: 1rem;
      line-height: 1rem;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      position: relative;
      padding-left: 26px;
      padding-right: 24px;
      font-family: Roboto-Regular, sans-serif;
      word-break: break-word;
      color: $txt_clr4;
      margin-bottom: 12px;

      @media only screen and (max-width: $mobileScreen) {
        font-size: 0.75rem;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background-image: url('../../../images/icons/check_new.svg');
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        min-width: 18px;
        width: 18px;
        min-height: 18px;
        height: 18px;
      }
    }
  }
}

.expander {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 30px;
  @include font(Roboto-Regular, 0.8125rem, 500, normal, normal, 1rem, normal);
  color: $txt_clr22;

  > svg {
    margin-left: 8px;
  }

  &:not(.active) {
    margin-top: 10px;
  }

  span {
    text-decoration: underline;
    cursor: pointer;
  }

  &.active {
    align-items: center;
    padding: 20px 24px 0 0;
    @media only screen and (min-width: $tabletBlock +1px) {
      span {
        &:hover {
          color: $txt_clr22;
        }
      }
    }

    > svg {
      transform: rotate(180deg);
    }
  }
}

.opened-description {
  padding-top: 10px;
  margin: 0;

  .room-notice {
    margin: 0;
    padding: 0;
    border: none;

    p {
      margin: 0;
      font-size: 0.9rem;

      @media only screen and (max-width: $mobileScreen) {
        font-size: 0.75rem;
      }
    }
  }
}
