@import '../../../variables.scss';

.suggestions-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  .hotel-search {
    @include centerItems();
    flex-direction: column;
    position: relative;
    background-color: $bg_clr1;
    width: 100%;
    max-width: $fullHd;

    .hotel-location-menu {
      & + .image-bord {
        top: 24px;
      }
    }

    .without-bg {
      margin-top: 24px;
    }

    .default-color {
      color: $txt_clr4;
    }

    .image-bord {
      top: 0;
      position: absolute;
      background-image: url('../../../images/suggestions-top-bg.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 463px;
      width: 100%;
      max-width: $fullHd;
    }
  }

  .search-form {
    position: relative;
    padding-bottom: 40px;
    width: $pcBlock;

    h1 {
      @include font(Roboto-Medium, 1.5rem, normal, normal, normal, 1.666em, normal);
      color: $txt_clr0;
      margin: 274px 0 24px 0;
    }

    .search-error {
      font-size: 1.5rem;
      color: $txt_clr14;
      text-align: center;
    }

    .main-inputs-form {
      margin-bottom: 24px;
      .default-select-list.lg.type-check {
        &.hide-tags {
          .custom-select .ant-select-selection-item:nth-child(2) {
            background-color: #ffffff;
          }
        }
        .custom-select {
          .ant-select-selector {
            padding-top: 16px;
            padding-bottom: 16px;
            .ant-select-selection-item {
              max-height: 24px;
              max-width: 150px;
              .ant-select-selection-item-remove {
                margin-top: 4px;
                margin-right: 6px;
              }
              .ant-select-selection-item-content {
                max-width: 130px;
                padding-top: 4px;
              }
              @media only screen and (max-width: $mobileScreen) {
                max-width: 200px;
                .ant-select-selection-item-content {
                  max-width: 180px;
                }
              }
            }
            .ant-select-selection-placeholder {
              margin-right: 21px;
            }
          }

          .ant-select-arrow {
            top: 30px;
          }
        }
      }
    }
  }

  .hotels-gallery {
    background-color: $bg_clr0;
  }
}

@media only screen and (max-width: $tabletScreen) {
  .suggestions-page {
    .hotel-search {
      max-width: initial;
    }

    .search-form {
      width: $tabletBlock;
    }
  }
  .suggestions-page {
    .grid-suggestions {
      .suggestions-results {
        .results-card {
          .footer-btns.tablet.solo-btn {
            a {
              margin-left: auto;
              width: initial;
            }

            .show-all {
              width: 396px;
              margin-left: auto;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mobileScreen) {
  .suggestions-page {
    .hotel-location-menu {
      display: none;
    }

    .search-form {
      width: 100%;
      padding: 0;

      h1 {
        font-size: 1.125rem;
        line-height: 1.166em;
        margin: 18px 0 10px 0;
        color: $txt_clr6;
      }

      .main-inputs-form {
        margin-bottom: 0;
        border-radius: 0;

        &.form-region {
          border-radius: 0 0 8px 8px;
          padding: 16px;

          .period-inputs {
            padding: 0;
          }

          .transfer-options {
            padding: 0;
          }
        }
      }

      .form {
        padding: 0;

        .period-inputs {
          padding: 16px 16px 0 16px;
        }

        .transfer-options {
          padding: 0 16px;
        }

        .btn-color-wrapper {
          padding: 0 16px 16px 16px;
          background-color: $bg_clr8;
          border-radius: 0;
        }
      }
    }

    .hotel-search {
      + .employee-card-wrapper {
        display: none;
      }

      .image-bord {
        display: none;
      }
    }

    .hotels-cards-block {
      width: 100%;
      padding-top: 0;
    }

    .hotels-gallery {
      display: none;
    }

    .hotel-search ~ .employee-card {
      margin-top: 80px;
    }
  }
  .suggestions-page {
    .grid-suggestions {
      .suggestions-results {
        min-height: 624px;
        padding: 0 16px;

        .results-card {
          .footer-btns.tablet.solo-btn {
            .show-all {
              background-color: $bg_clr7;
              color: $txt_clr0;
              @include font(Roboto-Medium, 1.12rem, normal, normal, normal, 1.6em, normal);
              width: 100%;

              &:hover {
                background-color: $bg_clr6;
              }
            }
          }
        }
      }
    }
  }
}
