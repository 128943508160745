@import '../../../variables.scss';

.main-inputs-form {
  &.left-alignment {
    $verticalPad: 12px;
    $formPad: 0 12px;

    .form {
      padding: 20px 0 0 0;

      .inputs-direction-wrapper {
        flex-direction: column;

        .transfer-options {
          margin: 24px 0 0 0;
          padding: $formPad;
        }

        .btn-apply {
          margin: 0;
          width: 100%;
        }
      }

      .period-inputs {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: 100%;
        padding: $formPad;

        .transfer-options {
          margin-bottom: 12px;
        }

        .default-select-list.lg {
          width: 100%;
          margin-right: 0;
          margin-bottom: $verticalPad;
        }

        .rangepicker-wrapper {
          @media only screen and (min-width: $mobileScreen +1px) {
            .headers-delimiter {
              display: none;
            }
            > :first-child {
              display: flex;
              align-items: flex-start;
              line-height: initial;

              > :last-child {
                display: none;
              }
            }
          }
        }

        .inputs-direction-wrapper {
          flex-direction: column;
          width: 100%;

          .other-parameters {
            display: block;
            width: 100%;

            .guests-form-wrapper {
              width: 100%;
              max-width: initial;
              margin-right: 0;
              margin-bottom: $verticalPad;
            }
          }
        }

        .DateRangePickerInput {
          @media only screen and (min-width: $mobileScreen +1px) {
            .DateRangePickerInput_arrow {
              width: 100%;
              display: block;

              &:before {
                content: 'Abreisedatum';
                display: block;
                @include font(Roboto-Medium, 0.87rem, 500, normal, normal, 1.14em, normal);
                color: #222222;
                padding: 0 0 8px 15px;
              }
            }
          }

          .DateInput {
            margin-bottom: $verticalPad;
            width: 100%;
          }
        }
      }

      .default-select-list.md {
        width: 100%;
        margin-right: 0;
        //padding: $formPad;
      }
    }

    .btn-color-wrapper {
      padding: 24px 12px 12px 12px;
      background-color: $bg_clr8;
      border-radius: 0 0 8px 8px;
    }
  }

  .full-size {
    &.btn-apply {
      //flex-grow: 1;
      //width: initial;
      //margin-left: auto;
      //max-width: 576px;
      //@media only screen and (max-width: $tabletScreen) {
      //  width: 100%;
      //}
      //@media only screen and (max-width: $mobileScreen) {
      //  width: 100%;
      //  max-width: initial;
      //}
    }

    &.transfer-options {
      @media only screen and (max-width: 1235px) {
        min-width: auto;
        flex-direction: column;
        margin-bottom: 24px;
      }

      @media only screen and (max-width: $mobileScreen) {
        max-width: initial;
        width: 100%;
        padding: 0;
        flex-direction: row;
        margin-top: 24px;
        height: initial;
        :last-child {
          margin-right: 0;
        }
        .custom-radio {
          flex-grow: 1;
          max-width: calc(50% - 8px);
          margin-bottom: 0;
        }
      }
      @media only screen and (max-width: 365px) {
        flex-direction: column;
        > :first-child {
          margin-bottom: 12px;
        }
        .custom-radio {
          max-width: 100%;
        }
      }
    }
  }

  .mobile-view {
    display: none;
  }

  .default-select-list .custom-select.ant-select-open .ant-select-selector {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.14);
  }

  .default-select-list {
    .ant-select-selection-placeholder {
      opacity: 1;
    }

    &.lg {
      .ant-select-selection-placeholder {
        margin-left: 6px;
        margin-right: 15px;
      }
    }

    .custom-select .ant-select-selector {
      padding-left: 15px;
    }
  }

  .btn-spinner {
    color: $txt_clr11;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  background-color: $bg_clr8;
  border-radius: 8px;
  //box-shadow: 0 9px 12px -5px $bx_shdw2;
  //overflow: hidden;
  .rangepicker-wrapper {
    .rangepicker-header {
      @include font(Roboto-Medium, 0.87rem, 500, normal, normal, 1.14em, normal);
      display: inline-block;
      width: 140px;
      color: #222222;
      padding: 0 0 8px 15px;
    }

    .headers-delimiter {
      display: inline-block;
      width: 8px;
    }

    .DayPicker__withBorder {
      border-radius: 0;
    }

    .CalendarMonth_table {
      .CalendarDay__default {
        border: 0;
      }
    }

    .CalendarMonthGrid__horizontal {
      max-height: 95%;
    }

    &.transfer {
      .CalendarMonth_table {
        .CalendarDay__default {
          border: 0;
          color: $txt_clr17;
          background: $bg_clr1;
          font-weight: 700;
        }

        .CalendarDay__blocked_calendar {
          background: $bg_clr0;
          border: 0;
          color: $txt_clr18;
        }

        .CalendarDay__blocked_out_of_range {
          background: $bg_clr0;
          border: 0;
          color: $txt_clr18;
        }

        .CalendarDay__hovered_span {
          background: $bg_clr8;
          border: 0;
          color: $txt_clr0;
        }

        .CalendarDay__hovered_span:hover {
          background: $bg_clr9;
          border: 0;
          color: $txt_clr0;
        }

        .CalendarDay__selected_span {
          background: $bg_clr8;
          color: $txt_clr0;
          border: 0;
          transition: background 350ms, color 300ms;
          -webkit-transition: background 350ms, color 300ms;
        }

        .CalendarDay__selected {
          background: $bg_clr9;
          border: 0;
          color: $txt_clr0;
          transition: background 350ms, color 300ms;
          -webkit-transition: background 350ms, color 300ms;
        }

        .CalendarDay__selected:hover,
        .CalendarDay__selected_span:hover {
          cursor: pointer;
          background: $bg_clr17;
          color: $black0;
          transition: background 350ms, color 300ms;
          -webkit-transition: background 350ms, color 300ms;
        }
      }
    }
  }

  .radio-switch {
    @include centerItems();
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    flex-direction: row;
    background-color: $bg_clr9;

    > div {
      width: 50%;
      min-height: 64px;

      .custom-radio {
        margin: 0;
        text-align: left;
        font-size: 1.25rem;
        line-height: 64px;
        padding-left: 24px;
        display: flex;
        align-items: center;
      }
    }

    .radio-selected {
      background-color: $bg_clr8;
      font-family: Roboto-bold, sans-serif;
    }
  }

  .form {
    padding: 24px;

    .period-inputs,
    .other-parameters {
      padding-bottom: 20px;
    }

    .other-parameters {
      .default-select-list {
        &.sm,
        &.sm-md {
          margin-right: 0;
          margin-left: 8px;
        }
      }

      .children-age-selector {
        .ant-select-open .ant-select-selector {
          box-shadow: 0 0 0 2px $bx_shdw0 !important;
        }
      }

      .options-line {
        :first-child {
          margin-left: 0;
        }
      }

      &.error-view {
        .empty-value .ant-select-selector {
          box-shadow: 0 0 0 2px $brdr_clr13;
        }

        .guests-form-wrapper {
          &.open {
            .guests-form {
              box-shadow: 0 0 0 2px $brdr_clr13;
            }
          }
        }
      }

      .children-age-wrapper {
        padding-left: 8px;

        .select-list-row {
          > :first-child {
            margin-left: 0;
          }

          > :last-child {
            margin-right: 0;
          }
        }
      }
    }

    .other-parameters.hide-title {
      > .default-select-list:first-of-type {
        margin-top: 23px;

        .custom-select-header {
          display: none;
        }
      }
    }

    .period-inputs .DateRangePickerInput .DateInput {
      &.unfocused {
        opacity: 0.6;
      }

      .DateInput_input__focused {
        border: 2px solid #1c33c8;
      }
    }
  }

  .period-inputs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: $tabletScreen) {
      flex-wrap: wrap;
    }

    @media only screen and (max-width: $mobileScreen) {
      flex-wrap: nowrap;
    }

    .default-select-list.lg.disabled {
      opacity: 0.5;
    }

    .default-select-list.lg.type-check {
      margin-top: 6px;

      .ant-select-open {
        .ant-select-selection-search {
          width: 60% !important;
        }
      }

      .ant-select-selector {
        padding-right: 40px;

        .ant-select-selection-search {
          width: 10px;
        }

        .ant-select-selection-item {
          border-radius: 60px;
          min-width: 0;
          max-width: 210px;

          .ant-select-selection-item-content {
            font-family: Roboto-Regular, sans-serif;
            padding-right: 6px;
            display: initial;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-right: 0;

            .more-counter {
              @include font(Roboto-Regular, 0.813rem, normal, normal, normal, normal, 1rem);
              color: $blue2;

              span {
                padding-left: 4px;
              }
            }
          }

          .ant-select-selection-item-remove {
            margin-top: 7.5px;
          }
        }
      }
    }

    &:first-child {
      min-width: 568px;
    }

    .ant-calendar-picker > div {
      width: 270px;
    }
  }

  .inputs-direction-wrapper {
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    user-select: none;

    @media only screen and (max-width: 365px) {
      &.size-watch {
        flex-direction: column;

        .transfer-options,
        .transfer-input.custom-input {
          width: 100%;
        }

        .transfer-input {
          order: 1;
        }

        .transfer-options {
          margin-top: 10px;
        }

        .btn-apply {
          order: 3;
        }
      }
    }

    .guests-form-wrapper {
      display: flex;
      flex-direction: column;
      width: 100px;
      max-width: 214px;
      //margin-right: 8px;
      //margin-top: 6px;

      &.open {
        .guests-form {
          box-shadow: 0 0 0 2px $bx_shdw0;
        }

        .guests-options {
          position: absolute;
          width: 100%;
          max-width: 100%;
          top: $input + 4px;
          left: 0;
          z-index: 4;
          display: table;
          //display: flex;
          //align-items: flex-start;
        }
      }

      .custom-select-header {
        align-self: flex-start;
        color: $txt_clr4;
        padding: 0;
        margin: 0 0 8px 15px;
        @include font(Roboto-Medium, 0.87rem, 500, normal, normal, 1.14em, normal);
      }

      .options-line {
        display: flex;
        flex-direction: row;
      }

      .guests-form {
        display: flex;
        align-items: center;
        background-color: $bg_clr0;
        width: 100%;
        height: $input;
        min-height: $input;
        border-radius: 4px;
        padding: 0 16px;
        position: relative;
        cursor: pointer;

        .remove-ic {
          position: absolute;
          right: 15px;
        }
      }

      .guests-options {
        width: 100%;
        display: none;

        .content-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          background-color: $bg_clr0;
          box-shadow: 0px 4px 6px rgba(5, 11, 51, 0.107982);
          border-radius: 6px;
          padding: 24px;
          max-width: 100%;

          @media only screen and (max-width: $mobileScreen) {
            width: 100%;
            flex-direction: column;
          }

          .select-wrapper {
            display: flex;
            @media only screen and (max-width: $mobileScreen) {
              width: 100%;
              flex-direction: column;
            }
          }

          .select-txt {
            @include font(Roboto-Regular, 0.81rem, 400, normal, normal, 1rem, normal);
            color: $txt_clr22;
            margin-top: 12px;
          }
        }
      }
    }

    .children-age-wrapper {
      @include font(Roboto-Regular, 0.87rem, 500, normal, normal, 1.14em, normal);
      display: flex;
      flex-direction: column;

      .ant-select-selection-placeholder {
        font-family: Roboto-Regular, sans-serif;
        font-size: 1rem;
        color: #222222;
        margin-right: 10px;
      }

      .custom-select-header {
        align-self: flex-start;
        font-family: Roboto-Medium, sans-serif;
        color: $txt_clr4;
        padding: 0;
        margin: 0 0 8px 15px;
      }

      .select-list-row {
        display: flex;
        flex-direction: row;
        position: relative;
      }
    }

    .row-title {
      padding-top: 23px;
      margin-right: 24px;
      width: 172px;
      @include centerItems();
    }
  }

  .default-select-list.md {
    padding-top: 5px;
    width: 160px;

    //@media only screen and (max-width: $tabletScreen) {
    //  width: 251px;
    //}
  }

  .transfer-options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 56px;
    margin-top: auto;
    //padding-left: 8px;
    position: relative;

    @media only screen and (max-width: $tabletScreen) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      min-width: 700px;
      margin-top: 0;
      height: auto;
    }

    @media only screen and (max-width: $mobileScreen) {
      min-width: max-content;
    }

    .hint-icon {
      width: 18px;
      height: 18px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 13px;
      cursor: default;
    }

    > :first-child {
      margin-bottom: 16px;
    }

    &.in-datepicker {
      height: initial;
      padding: 24px 24px 0 24px;

      .calendar-elevator-title {
        @include font(Roboto-Regular, 1rem, 600, normal, normal, 1.06rem, normal);
      }

      .calendar-elevator-input {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }

      .calendar-elevator-descr {
        @include font(Roboto-Regular, 0.87rem, 400, normal, normal, 1.14rem, normal);
        margin-bottom: 0;

        &.mb {
          margin-bottom: 10px;
        }
      }

      .calendar-elevator-red {
        font-weight: 600;
        font-size: 0.9rem;
        color: $txt_clr23;
      }

      .calendar-elevator-link {
        text-decoration: underline;
        font-weight: 700;
      }

      .calendar-elevator-input,
      .calendar-transfer-txt {
        @include font(Roboto-Regular, 0.81rem, 400, normal, normal, 1.06rem, normal);
      }

      .calendar-elevator-icon {
        padding-top: 8px;
        margin-right: 6px;
        width: 40px;
        height: 40px;
        color: $txt_clr4;
      }

      .calendar-elevator-icon-red {
        color: $txt_clr23;
      }

      .calendar-transfer-txt {
        button {
          color: $blue2;
          text-decoration: underline;
          background: none;

          &:hover {
            text-decoration: none;
            cursor: pointer;
          }
        }
      }

      .custom-input {
        height: 48px;
        margin-left: initial;
        width: 78px;
        min-width: 78px;
        border-radius: 2px;
        border: 1px solid $brdr_clr12;

        input {
          height: 100%;
        }

        input::placeholder {
          color: $txt_clr16;
        }
      }
    }
  }

  .hint-text {
    color: $black0;
    padding-left: 38px;
    margin-top: 12px;
    width: 100%;
    @include font(Roboto-Regular, 0.81rem, 400, normal, normal, 1.06rem, normal);

    &.pr {
      padding-right: 12px;
    }

    .transfer-txt {
      button {
        color: $blue2;
        text-decoration: underline;
        background: none;

        &:hover {
          text-decoration: none;
          cursor: pointer;
        }
      }
    }

    @media only screen and (max-width: $mobileScreen) {
      padding-left: 46px;

      &.pr {
        padding-right: 16px;
      }

      &.home {
        padding-right: 0;
        padding-left: 34px;
      }
    }
  }

  .transfer-input.custom-input {
    width: 214px;
    margin-left: auto;
    margin-right: 8px;
    font-family: Roboto-Regular, sans-serif;
    font-size: 1rem;

    .custom-input-title {
      align-self: flex-start;
      color: $txt_clr4;
      padding: 0;
      margin: 0 0 8px 15px;
      @include font(Roboto-Medium, 0.87rem, 500, normal, normal, 1.14em, normal);
    }

    input {
      height: 56px;
      border-radius: 4px;
      border: none;
      padding-left: 16px;
      padding-right: 16px;
      font-family: Roboto-Regular, sans-serif;
      font-size: 1rem;
      color: $txt_clr4;

      &::placeholder {
        font-family: Roboto-Regular, sans-serif;
        font-size: 1rem;
        color: $txt_clr4;
      }
    }

    &.disabled {
      opacity: 0.3;
    }

    &.must-be-filled {
      border: 2px solid $brdr_clr13;
      border-radius: 6px;
    }

    &.focus-transfer {
      border: 3px solid $txt_clr23;
      border-radius: 6px;
    }
  }

  .other-parameters {
    display: flex;
    flex-direction: row;
    position: relative;

    &:hover {
      .custom-icon-close {
        display: block;
      }
    }

    .custom-icon-close {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      cursor: pointer;
    }

    &:last-of-type {
      padding: 0;
    }
  }

  .inputs-direction-wrapper {
    > :first-child {
      .custom-icon-close {
        display: none;
      }
    }
  }

  .btn-apply {
    margin-top: auto;
    //margin-left: auto;
  }

  &.short-form {
    border-radius: 0 0 8px 8px;

    .form {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      padding-top: 12px;

      .rangepicker-wrapper {
        order: 1;
        margin-right: 8px;
      }

      .period-inputs {
        min-width: initial;
        padding-bottom: 0;

        .inputs-direction-wrapper {
          order: 2;
        }
      }

      .guests-options {
        z-index: 1;
      }

      .DateInput,
      .rangepicker-wrapper .rangepicker-header {
        width: 199px;
      }

      .custom-btn-md {
        width: 179px;
      }

      .guests-form-wrapper {
        .content-wrapper {
          .children-age-wrapper {
            .default-select-list.sm-md {
              width: 115px;
            }
          }
        }
      }

      .default-select-list.sm {
        width: 115px;
      }

      .default-select-list.md {
        max-width: 238px;
      }

      .inputs-direction-wrapper {
        .guests-form-wrapper {
          width: 289px;
          max-width: 289px;
        }
      }

      .default-select-list.md {
        margin-right: 24px;
        @media only screen and (max-width: $tabletScreen) {
          margin-right: 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: $tabletScreen) {
  .main-inputs-form {
    border-radius: 0 0 8px 8px;

    .rangepicker-wrapper {
      .rangepicker-header {
        display: inline-block;
        width: 142px;
      }

      .headers-delimiter {
        display: inline-block;
        width: 8px;
      }
    }

    //.default-select-list.lg {
    //  width: 452px;
    //}

    .period-inputs {
      //&:first-child {
      //  min-width: 471px;
      //}

      .ant-calendar-picker > div {
        width: 214px;
      }
    }

    .transfer-input.custom-input {
      width: 166px;
    }

    .inputs-direction-wrapper {
      .guests-form-wrapper {
        //width: 166px;
        //max-width: 166px;

        .guests-options {
          .content-wrapper {
            padding: 24px;
            @media only screen and (max-width: $tabletScreen) {
              padding: 24px 18px;
            }
          }
        }
      }

      .other-parameters:not(:first-child) {
        .custom-icon-close {
          display: block;
        }
      }

      .row-title {
        width: 134px;
      }
    }

    &.short-form {
      .form {
        .default-select-list .custom-select-header,
        .inputs-direction-wrapper .children-age-wrapper .custom-select-header {
          margin-left: 5px;
        }

        .rangepicker-wrapper .rangepicker-header {
          padding-left: 5px;
        }

        .DateInput,
        .rangepicker-wrapper .rangepicker-header {
          width: 152px;
        }

        .default-select-list.md {
          width: 161px;
        }

        .custom-btn-md {
          width: 168px;
        }

        .inputs-direction-wrapper {
          .guests-form-wrapper {
            width: 258px; //258
            .content-wrapper {
              .children-age-wrapper {
                .default-select-list.sm-md {
                  width: 100px;
                }
              }
            }
          }
        }

        //.default-select-list.sm, .children-age-wrapper {
        .default-select-list.sm {
          width: 100px;
        }
      }
    }
  }
}

@media only screen and (max-width: $mobileScreen) {
  .mobile_calendar_open {
    overflow: hidden;
    min-height: 0 !important;
    max-height: 300px;
  }
  .CalendarMonthGrid {
    margin-top: 130px;

    &.transfer_offset {
      margin-top: 205px;
    }
  }

  .main-inputs-form {
    //border-radius: 0;

    .transfer-options {
      width: 100%;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      height: auto;
    }
    .transfer-input.custom-input {
      width: calc(50% - 6px);
    }

    .transfer-input.custom-input {
      margin: 0 0 0 auto;
    }

    .mobile-view {
      display: flex;
    }

    .desktop-view {
      display: none;
    }

    &.short-form {
      .form {
        display: block;

        .period-inputs {
          min-width: initial;
          padding-bottom: 0;
          margin-right: 0;
        }

        .inputs-direction-wrapper {
          .guests-form-wrapper {
            flex-grow: 1;
            max-width: calc(50% - 6px);

            .guests-options {
              .content-wrapper {
                .options-line {
                  > div {
                    width: initial;
                    flex-grow: 1;
                    min-width: initial;
                    max-width: calc(50% - 6px);
                  }
                }
              }
            }

            .children-age-wrapper {
              width: 100%;

              .select-list-row {
                justify-content: flex-start;
              }

              .default-select-list.sm-mdx {
                flex-grow: 1;
                max-width: calc(50% - 6px);
              }

              .default-select-list.sm-md {
                flex-grow: 1;
                max-width: 50%;
              }
            }
          }
        }

        .rangepicker-wrapper {
          margin-right: 0;

          .rangepicker-header {
            padding-left: 12px;
          }
        }

        .default-select-list .custom-select-header,
        .inputs-direction-wrapper .children-age-wrapper .custom-select-header {
          margin-left: 12px;
        }

        .DateInput,
        .rangepicker-wrapper .rangepicker-header,
        .default-select-list.sm,
        .children-age-wrapper {
          width: 49%;
        }

        .DateRangePickerInput_arrow,
        .rangepicker-wrapper .headers-delimiter {
          width: 2%;
        }

        .default-select-list.md {
          flex-grow: 1;
          max-width: calc(50% - 6px);
        }

        .custom-btn-md {
          width: 100%;
        }

        .default-select-list.sm {
          width: 47%;
          margin-right: 0;
        }

        .default-select-list.md {
          margin-right: 0;
        }
      }
    }

    .rangepicker-wrapper {
      width: 100%;
      margin-top: 10px;

      .rangepicker-header {
        display: inline-block;
        width: 47%;
        font-size: 0.75rem;
        line-height: 1.333em;
        padding: 0 0 4px 12px;
      }

      .headers-delimiter {
        display: inline-block;
        width: 6%;
      }
    }

    .radio-switch {
      span.ant-radio + * {
        padding-top: 2px;
      }

      > div {
        display: flex;
        align-items: center;

        .custom-radio {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding-left: 12px;
          white-space: pre-line;
          font-size: 0.875rem;
          line-height: 1.125em;
        }
      }
    }

    .form {
      padding: 16px;

      .period-inputs {
        width: 100%;
        min-width: initial;
        flex-direction: column;
        padding: 0;

        //> :first-child {
        //  width: 100%;
        //}

        .ant-calendar-picker > div {
          width: 100%;
        }
      }

      .default-select-list.lg {
        width: 100%;
        margin: 0;

        .ant-select-selector {
          padding-bottom: 0;
        }
      }

      .date-range-inputs-container {
        margin-top: 10px;
        justify-content: space-between;

        &.hidden {
          display: none;
        }

        .picker-container {
          margin: 0;
          width: 47%;

          .ant-calendar-picker {
            width: 100%;
            min-width: initial !important;
          }
        }
      }

      .inputs-direction-wrapper {
        width: 100%;

        .other-parameters {
          width: 100%;
          position: relative;

          > div {
            flex-grow: 1;
            max-width: calc(50% - 6px);
          }
        }

        .transfer-input {
          margin-top: 10px;
        }

        .guests-form-wrapper {
          width: 100%;
          max-width: 100%;
          margin-right: 0;
          margin-top: 10px;

          .custom-select-header {
            font-size: 0.75rem;
            line-height: 1.333em;
            margin: 0 0 4px 12px;
          }

          .guests-form {
            position: static;
          }

          &.open {
            .guests-options {
              display: block;
            }
          }

          .guests-options {
            z-index: 3;
            top: 90px;

            .content-wrapper {
              flex-direction: column;
              padding: 24px;

              .custom-select-header {
                font-size: 0.75rem;
                line-height: 1.333em;
                margin: 0 0 4px 12px;
              }

              .options-line {
                width: 100%;
                justify-content: flex-end;

                .default-select-list {
                  width: calc(50% - 6px);
                }

                :last-child {
                  margin-right: 0;
                }
              }
            }
          }
        }

        .children-age-wrapper {
          margin: 12px 0 0 auto;
          width: 100%;
          padding: 0;

          .select-list-row {
            justify-content: flex-end;

            .sm-mdx {
              width: 50%;
              margin-right: 50%;
            }

            .sm-md {
              width: calc(50% - 6px);
            }
          }

          @media only screen and (max-width: 360px) {
            width: 100%;
            .select-list-row {
              justify-content: flex-start;

              .sm-mdx {
                width: 47.5%;
              }
            }
          }
        }
      }

      .other-parameters {
        flex-wrap: wrap;
        justify-content: space-between;

        > .default-select-list {
          margin-top: 10px;
          padding-top: 0;
        }

        &.hide-title {
          > .default-select-list:first-of-type {
            margin-top: 29px;

            .custom-select-header {
              display: none;
            }
          }
        }

        .row-title {
          width: 100%;
          margin-right: 0;
          padding-top: 0;
          padding-bottom: 10px;

          & ~ .default-select-list.sm {
            width: 47%;
          }
        }

        //.default-select-list:first-of-type {
        //  margin-top: 19px;
        //}
      }
    }

    .btn-apply {
      height: 48px;
      margin-top: 16px;
      width: 100%;
    }
  }

  .DayPicker {
    > div {
      > :nth-child(2) {
        .DayPicker_focusRegion {
          .DayPicker_transitionContainer {
            padding: 0;

            > :first-child {
              display: none;
            }
          }
        }
      }
    }
  }
  .CalendarMonth_table {
    .CalendarDay__blocked_calendar {
      background: $bg_clr0;
      border: 0;
      color: $txt_clr18;
    }
  }
  .CalendarMonth_table .CalendarDay__selected_span {
    background: $bg_clr8;
    color: $txt_clr0;
    border: 0;
  }

  .transferIsActive {
    .DayPicker {
      > div {
        > :nth-child(2) {
          .DayPicker_focusRegion {
            .DayPicker_transitionContainer {
              //margin-top: 200px;
              //padding: 0;

              > :first-child {
                display: none;
              }

              > :last-child {
                //margin-bottom: 200px;
              }
            }
          }

          .CalendarMonth_table {
            .CalendarDay__default {
              border: 0;
              color: $txt_clr17;
              background: $bg_clr1;
            }

            .CalendarDay__blocked_calendar {
              background: $bg_clr0;
              border: 0;
              color: $txt_clr18;
            }

            .CalendarDay__blocked_out_of_range {
              background: $bg_clr0;
              border: 0;
              color: $txt_clr18;
            }

            .CalendarDay__hovered_span {
              background: $bg_clr15;
              border: 0;
              color: $txt_clr0;
            }

            .CalendarDay__hovered_span:hover {
              background: $bg_clr9;
              border: 0;
              color: $txt_clr0;
            }

            .CalendarDay__selected_span {
              background: $bg_clr8;
              color: $txt_clr0;
              border: 0;
            }

            .CalendarDay__selected {
              background: $bg_clr9;
              border: 0;
              color: $txt_clr0;
            }
          }
        }
      }
    }
  }

  .transfer-options.in-datepicker .calendar-elevator-input .custom-input {
    &.must-be-filled {
      border: 2px solid #e93208;
      border-radius: 6px;
    }
  }
}

.booking-btn {
  position: relative;

  .booking-btn-preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
  }
}

.transfer-detail {
  padding: 12px;

  .transfer-title {
    @include font(Roboto-Medium, 1.25rem, 500, normal, normal, 1.75, normal);
    color: $txt_clr4;
    margin: 0 0 30px 0;

    & .sm {
      margin-bottom: 12px;
      display: block;
      font-size: 0.875rem;
      line-height: 1rem;
    }

    & .md {
      display: block;
      font-size: 1.125rem;
      line-height: 1.25rem;
    }
  }

  .transfer-body {
    @include font(Roboto-Regular, 1rem, 400, normal, normal, 1.5rem, normal);
    white-space: initial;
    color: $txt_clr4;

    & ul {
      margin-left: 18px;
      padding-bottom: 20px;
    }

    & li {
      list-style-type: disc;
      list-style-position: outside;
      padding-bottom: 5px;
    }

    > p:not(.cancelation-fee) {
      margin-bottom: 12px;
    }
  }
}
