@import '../../../variables';

.wrapper-card-room {
  margin-top: 24px;

  .banner-attention-container {
    position: static;
    border-radius: 8px 8px 0px 0px;
    padding: 4px 12px;
    width: initial;
    display: inline-block;
    .banner-attention {
      padding: 0;
      margin: 0;
    }
  }
  @media only screen and (max-width: $tabletScreen) {
    margin-top: 12px;
    .banner-attention {
      flex-direction: row;
    }
  }
  @media only screen and (max-width: $mobileScreen) {
    .banner-attention-container {
      .banner-attention {
        @include font(Roboto-Regular, 0.75rem, normal, normal, normal, 0.875rem, normal);
        flex-direction: row;
      }
    }
  }
}
.hotel-room {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  min-height: 402px;
  border-radius: 8px;
  border: 2px solid $brdr_clr16;

  &.with-discount {
    border-radius: 0px 8px 8px 8px;
    position: relative;
    border: 2px solid $brdr_clr20;

    @media only screen and (max-width: $mobileScreen) {
      border-radius: 0px 0px 8px 8px;
    }
  }
  &:not(.with-discount) {
    .price.price-direction {
      &.for-guest {
        .current-price span {
          color: $txt_clr22;
        }
      }
      &.total-price {
        .current-price span {
          color: $txt_clr4;
        }
      }
    }
    .room-info-block .current-price span {
      font-family: Roboto-Medium, sans-serif;
    }
  }

  .mobile-view {
    display: none !important;
  }

  .minimal-days {
    text-align: center;
    white-space: pre-wrap;
    @include font(Roboto-Regular, 0.875rem, normal, normal, normal, 1.125rem, normal);
    margin-top: 12px;
    color: $txt_clr15;
  }

  .left,
  .right {
    padding: 24px;

    .guests-count-block {
      @extend %guests-count-block;
      &:not(.transfer-row) {
        padding-left: 24px;
      }
    }
  }

  .left {
    position: relative;
    height: 100%;
    background-color: $bg_clr0;
    padding: 26px 24px 24px 26px;

    .room-img-wrapper {
      position: relative;
      width: 439px;
      min-width: 439px;
      max-width: 439px;
      height: 352px;
      min-height: 352px;
      max-height: 352px;
      .img-container {
        .counter-wrapper {
          display: flex;
          flex-direction: column;
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 43%;
          .slide-counter {
            position: initial;
            margin-left: 13px;
            margin-top: 12px;
          }
        }
        .single-photo {
          position: relative;
          z-index: 1;
        }
        .slide-counter:not(.with-loupe) {
          opacity: 0;
          transition: opacity 0.3s ease-out;
          -webkit-transition: opacity 0.3s ease-out;
        }
        &:hover {
          cursor: pointer;
          .slide-counter {
            opacity: 1;
            transition: opacity 0.3s ease-out;
            -webkit-transition: opacity 0.3s ease-out;
          }
        }
        .slider-wrapper {
          &:hover {
            .slider-controllers {
              opacity: 1;
              transition: opacity 0.3s ease-out;
              -webkit-transition: opacity 0.3s ease-out;
            }
          }

          .slider-controllers {
            transition: opacity 0.3s ease-out;
            opacity: 0;
          }
        }
      }

      .slider-wrapper.sm .ant-carousel .slick-slide {
        height: 100%;
      }
      .img-container,
      .slider-wrapper,
      .ant-carousel,
      .slick-slider,
      .slick-list,
      .slick-track,
      .main-img {
        height: 100%;
      }
      .main-img {
        object-fit: cover;
      }

      .room-info {
        position: relative;
        padding: 24px 16px;
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: rgba(255, 255, 255, 0.9);
        background-color: rgba(0, 0, 0, 0.4);

        > :nth-child(1) {
          @include font(Roboto-Regular, 1.125rem, 500, normal, normal, 1.312rem, normal);
        }

        > :nth-child(2) {
          margin-top: 10px;
          color: rgba(255, 255, 255, 0.8);
          @include font(Roboto-Regular, 1rem, normal, normal, normal, 1.185rem, normal);
        }
      }

      > img,
      .empty-room-pic {
        position: absolute;
        object-fit: cover;
        background-color: $bg_clr3;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }
  }

  .actions-popup-wrapper {
    margin-top: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .popup-action {
      @include font(Roboto-Regular, 0.8125rem, normal, normal, normal, 1rem, normal);
      margin-top: auto;
      text-decoration: underline;
      color: $txt_clr22;
    }
  }

  .float-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .expander {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-right: 30px;
      @include font(Roboto-Regular, 0.8125rem, 500, normal, normal, 1rem, normal);
      color: $txt_clr22;

      > svg {
        margin-left: 8px;
      }

      &:not(.active) {
        margin-top: 16px;
      }

      span {
        text-decoration: underline;
        cursor: pointer;
      }

      &.active {
        align-items: center;
        padding: 20px 24px 24px 0px;
        @media only screen and (min-width: $tabletBlock +1px) {
          span {
            &:hover {
              color: $txt_clr22;
            }
          }
        }

        > svg {
          transform: rotate(180deg);
        }
      }
    }

    .opened-description {
      display: flex;
      flex-direction: column;

      .room-notice {
        padding-right: 24px;
        border: none;
        padding-bottom: 0;
        margin-top: 16px;

        strong {
          color: $txt_clr4;
        }

        p {
          color: $txt_clr22;
          margin-bottom: 0;
          font-family: Roboto-Light, sans-serif;
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
      }
    }

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 100%;
    }

    .options-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      height: 100%;

      &.active-collapse {
        height: initial;
      }
      .banner-profit.spa-package.room-note {
        margin-left: 0;
        align-self: flex-start;
      }
      .meal-type {
        display: flex;
        align-items: center;
        margin-bottom: 3px;

        svg {
          min-width: 18px;
          max-width: 18px;
          min-height: 18px;
          max-height: 18px;
          margin-right: 6px;
        }
        div {
          @include font(Roboto-Regular, 0.813rem, 400, normal, normal, 1rem, normal);
          span:nth-child(1) {
            padding-right: 4px;
          }
        }
      }
      .room-info {
        margin-top: 12px;
        margin-bottom: 18px;
        .room-name {
          @include font(Roboto-Medium, 1rem, 500, normal, normal, 1.5rem, normal);
        }
        .actions-popup-wrapper {
          margin-top: 6px;
          @include font(Roboto-Regular, 0.813rem, normal, normal, normal, 1rem, normal);
        }
      }
    }

    .banner-profit.room-note {
      @include font(Roboto-Medium, 0.875rem, 500, normal, normal, 1rem, normal);
      position: static;
      display: inline-block;
      color: $txt_clr4;
      border-radius: 6px;
      padding: 6px 12px;
      margin-top: 24px;
      max-width: 358px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      background: $bg_clr8;
      margin-left: 11px;
    }
    .present-row {
      margin-bottom: 10px;
    }

    .room-accordion-options {
      color: $txt_clr4;
      max-height: 149px;
      position: relative;
      overflow: hidden;

      &.open,
      &.open.with-present {
        max-height: initial;
        height: initial;
        overflow: initial;
      }
      &.with-present {
        max-height: 133px;
        height: 133px;

        @media only screen and (max-width: $mobileScreen) {
          max-height: initial;
          height: initial;
        }
      }
      .room-accordion-header {
        @include font(Roboto-Medium, 1rem, 500, normal, normal, 1.5em, normal);
        margin-bottom: 5px;
      }

      ul {
        li {
          font-size: 0.813rem;
          line-height: 1rem;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          flex-wrap: wrap;
          position: relative;
          padding-left: 26px;
          padding-right: 24px;
          font-family: Roboto-Regular, sans-serif;
          word-break: break-word;
          color: $txt_clr4;
          margin-bottom: 3px;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            background-image: url('../../../images/icons/check_new.svg');
            background-position: center;
            background-repeat: no-repeat;
            display: block;
            min-width: 18px;
            width: 18px;
            min-height: 18px;
            height: 18px;
          }
        }
      }
    }

    .right {
      background: rgba(237, 244, 248, 0.65);
      min-width: 350px;
      max-width: 350px;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 24px 24px 12px 12px;
      min-height: 402px;
      position: relative;
      z-index: 2;

      .guests-count-block {
        .final-sum {
          border-top: initial;
        }
      }

      .days-count {
        width: 100%;

        > div {
          white-space: pre-wrap;
          font-size: 0.8125rem;
          font-weight: 600;
          line-height: 1.0625rem;
        }

        + .price-block {
          padding-top: 12px;
        }
      }

      .guests-count-block {
        padding-top: 6px;
        + .price-block {
          padding-top: 12px;
        }
        svg {
          min-width: 18px;
          max-width: 18px;
          min-height: 18px;
          max-height: 18px;
          margin-right: 6px;
        }
      }

      .guests-count-block.pad {
        padding-top: 9px;
      }
      .guests-count-block.transfer-row {
        border-top: 1px dashed $brdr_clr19;
        padding-top: 12px;
        padding-bottom: 0;
        margin-top: 9px;

        .guest-count {
          display: flex;
          font-size: 0.875rem;
          line-height: 1.125rem;
        }
        .prise-for-guest {
          font-family: Roboto-Medium, sans-serif;
          font-weight: 500;
        }
      }

      .guests-count-block {
        @extend %guests-count-block;
        &:not(.transfer-row) {
          .guest-count {
            color: $txt_clr22;
          }
        }
      }

      .order-options-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 0;
        .date-from-to {
          display: flex;
          svg {
            margin-right: 6px;
          }
        }
      }
      .room-info-block {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 12px;
        .room-title {
          display: flex;
          align-items: flex-start;
          max-width: 182px;
          width: 100%;
          @include font(Roboto-Regular, 0.875rem, 400, normal, normal, 1.125rem, normal);
        }
        svg {
          min-width: 18px;
          max-width: 18px;
          min-height: 18px;
          max-height: 18px;
          margin-right: 6px;
        }
        .final-sum {
          border-top: initial;
          .current-price .with-discount-price-color,
          .current-price .with-discount-price-color span {
            font-family: Roboto-Medium, sans-serif;
            font-weight: 500;
          }
          .price.price-direction {
            line-height: 1rem;
          }
        }
      }

      .room-btns {
        margin: auto 0 6px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .custom-empty-btn {
          height: auto;
          padding: 5px 7px;
          font-size: 0.8rem;
          border-radius: $smallInputRadius;

          @media only screen and (max-width: $tabletScreen) {
            height: auto;
          }
        }

        .custom-empty-link {
          width: 48%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $txt_clr9;

          & svg {
            margin-right: 3px;
            width: 8px;
            height: 8px;
          }

          &:hover {
            color: $txt_clr0;
          }
        }
      }

      .custom-transfer-link {
        background-color: transparent;
        height: auto;
        margin: 0 0 16px;
        padding: 0;
        width: 100%;
        text-align: right;
        color: $txt_clr9;
        text-decoration: underline;
        font-family: Roboto-Regular, sans-serif;
        font-size: 0.8125rem;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1rem;
        cursor: pointer;
      }

      .custom-green-link {
        margin-bottom: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 38px;
        background-color: transparent;
        color: $green0;
        border-color: $green0;

        &:hover {
          background-color: $green0;
          color: $txt_clr0;
          border-color: $green0;
        }

        @media only screen and (max-width: $tabletScreen) {
          font-size: 1.05rem;
        }
      }

      .price-block {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;

        .person-price {
          font-family: Roboto-Light, sans-serif;
          margin-bottom: 16px;
          color: $txt_clr6;
          font-size: 1.125rem;
          line-height: 1.555em;
        }
        .total-price-block {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          border-top: 1px dashed $brdr_clr21;
          padding-top: 8px;
          padding-bottom: 12px;

          .final-sum {
            border-top: initial;
            width: initial;
          }
          .title-block {
            @include font(Roboto-Regular, 0.875rem, normal, normal, normal, 1.125rem, normal);
          }
        }
      }

      .tax-bill {
        @extend .extra-sm-txt;
        color: $txt_clr22;
        text-align: right;
        margin-bottom: 12px;
        @include font(Roboto-Regular, 0.8125rem, normal, normal, normal, 1rem, normal);
        & > :last-child {
          text-decoration: underline;
        }

        & > :first-child {
          text-decoration: none;
        }
      }

      button {
        height: 49px;
      }

      .footer-btn-wrapper {
        //margin-top: auto;
        width: 100%;

        .custom-empty-btn,
        .custom-btn {
          width: 100%;
          background-color: $bg_clr7;
          color: $txt_clr0;
          transition: background-color 300ms;
          -webkit-transition: background-color 300ms;
          &:hover {
            background-color: $bg_clr16;
            transition: background-color 300ms;
            -webkit-transition: background-color 300ms;
          }
        }
      }

      .trip-date {
        margin-top: 6px;
        @include font(Roboto-Light, 0.875rem, normal, normal, normal, 1.142em, normal);
      }

      .room-pros {
        .payment-list {
          width: 100%;
          display: flex;
          justify-content: center;
          padding-top: 10px;
        }

        .payment-item {
          position: relative;
          padding-left: 18px;
          @include font(Roboto-Regular, 0.8rem, normal, normal, normal, 1.27em, normal);
          color: $txt_clr13;

          &-bold {
            font-weight: bold;
          }

          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            background-image: url('../../../images/icons/green-arrow.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            @include fixed-size(15px);
          }
        }
      }
    }

    .right.items-right {
      align-items: flex-end;
    }

    .footer-btn-wrapper {
      .custom-empty-btn,
      .custom-btn {
        height: 38px;
      }
    }
  }
}

@media only screen and (max-width: $tabletScreen) {
  .hotel-room {
    min-height: 386px;
    .left {
      position: relative;
      padding-left: 18px;
      padding-top: 18px;
      padding-bottom: 18px;

      .room-img-wrapper {
        width: 280px;
        min-width: 280px;
        max-width: 280px;
        min-height: 304px;
        max-height: 350px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .float-wrapper {
      .banner-profit.room-note {
        max-width: 290px;
        font-size: 1rem;
      }

      .right {
        min-width: 320px;
        max-width: 320px;
        min-height: 386px;

        .custom-empty-btn {
          width: 100%;
        }
      }
      .room-accordion-options {
        max-height: 151px;
      }
    }
  }
}

@media only screen and (max-width: $mobileScreen) {
  .wrapper-card-room .banner-attention-container {
    width: 100%;
  }
  .hotel-room {
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    height: initial;
    .float-wrapper .opened-description {
      .room-notice {
        padding-left: 12px;
        padding-right: 12px;
      }
      .expander.active {
        padding-left: 12px;
      }
    }

    .mobile-view {
      display: flex !important;

      &.room-slides-wrapper {
        width: 100%;
        flex-direction: column;
        padding: 12px 12px 0 12px;

        .mobile-gallery.hotels {
          padding-left: 0;
        }

        .room-pic {
          width: 180px !important;
          height: 122px;
          margin-right: 8px;
          object-fit: cover;
        }

        .hotel-name,
        .hotel-catering {
          margin-bottom: 6px;
        }

        .hotel-name {
          font-family: Roboto-Bold, sans-serif;
          font-size: 1.125rem;
          line-height: 1.25rem;
        }

        .hotel-catering {
          font-family: Roboto-Regular, sans-serif;
          font-size: 0.8125rem;
          line-height: 1.0625rem;
        }
      }

      &.footer-btn-wrapper {
        padding: 18px 12px 12px 12px;
      }
    }

    .expander.active {
      padding-bottom: 0 !important;
    }

    .actions-popup-wrapper {
      font-family: Roboto-Regular, sans-serif;
      padding: 8px 0 0 0;
    }

    .left {
      display: none;

      .room-img-wrapper {
        width: 100%;
        min-width: initial;
        margin: 0;
      }

      .guests-count-block {
        display: flex;
        font-size: 0.875rem;
      }
    }

    .float-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      .expander:not(.active) {
        margin-top: 16px;
      }
      .options-wrapper {
        padding: 12px 14px 0 14px;
        .banner-profit.spa-package.room-note {
          margin: 0;
        }
        .room-accordion-options {
          padding: 0;
          min-height: initial;
        }
        .room-info {
          .mobile-view.room-slides-wrapper {
            padding: 12px 0px 0px 0px;
            .hotel-name,
            .hotel-catering,
            .actions-popup-wrapper {
              display: none;
            }
          }
        }
      }

      .room-accordion-options {
        height: initial;
      }

      > div {
        flex-direction: column;
      }

      .right {
        width: 100%;
        padding: 0;
        max-width: initial;
        min-height: initial;
      }

      .room-img-wrapper {
        padding: 12px;
      }

      .room-accordion-options {
        padding: 0 12px;

        ul {
          li {
            padding-right: 0;
          }
        }

        &.open {
          padding-bottom: 0;
        }

        .expander {
          font-size: 0.8rem;

          &:not(.active) {
            height: 24px;
            padding: 0 30px 0 12px;
          }

          &.active {
            padding-bottom: 0;
          }
        }
      }

      .banner-profit.room-note {
        margin: 12px 12px 0 12px;
        max-width: calc(100% - 24px);
      }

      .right {
        width: 100%;
        min-width: initial;
        display: flex;
        padding: 12px;
        margin-top: 16px;

        .custom-empty-btn {
          width: 100%;
        }
        .minimal-days {
          margin-bottom: 8px;
        }
      }
    }
  }
}

@media only screen and (max-width: 325px) {
  .wrapper-card-room .banner-attention-container {
    .banner-attention {
      flex-direction: column;
    }
  }
  .hotel-room .float-wrapper .options-wrapper .room-accordion-options {
    max-height: 178px;
    &.open {
      max-height: initial;
    }
  }
}
