@import '../../../variables';

.swimming-container {
  position: relative;
  max-height: 1px;
  height: 1px;
  z-index: 3;
  width: 100%;
  display: none;
  @media only screen and (max-width: $tabletScreen) {
    display: block;
  }
}

.swimming-btn-wrapper {
  position: absolute;
  top: calc(100vh - 120px); //48 the height of nav bar
  width: 100%;
  left: 0;

  .position-wrapper {
    width: 100%;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    transition: background 0.3s;

    &.fixed {
      position: fixed;
      z-index: 4;
      .custom-btn {
        @media only screen and (max-width: $mobileScreen) {
          margin: 0;
        }
      }
      box-shadow: 0px -6px 10px -4px rgba(123, 145, 157, 0.2);
      padding: 24px 16px;
      background-color: $bg_clr0;
    }
  }

  .custom-btn {
    width: 100%;
    height: 48px;
    font-family: Roboto-Regular, sans-serif;

    &:hover {
      background-color: $brdr_clr8;
    }
  }

  @media only screen and (max-width: $tabletScreen) {
    .custom-btn {
      max-width: 975px;
    }
  }
  @media only screen and (max-width: $mobileScreen) {
    .custom-btn {
      max-width: 100%;
      margin: 0 16px;
    }
  }
}
