.elevator-modal {
  width: 651px !important;
  @media only screen and (max-width: $mobileScreen) {
    width: inherit !important;
  }

  h3.modal-header {
    color: $txt_clr16;
    line-height: 1rem;
    font-size: 0.875rem;
    margin-bottom: 16px;
  }

  h2.modal-header {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-bottom: 24px;
  }

  .modal-body {
    width: 500px;
    @media only screen and (max-width: $mobileScreen) {
      width: initial;
    }

    p {
      @include font(Roboto-Regular, 1rem, 400, normal, normal, 1.5rem, normal);
      margin-bottom: 8px;
      color: $txt_clr4;
      @media only screen and (max-width: $mobileScreen) {
        padding-right: 10%;
      }
    }

    + a {
      @include font(Roboto-Bold, 1rem, normal, normal, normal, 1.5rem, normal);
      color: $txt_clr4;
    }
  }
}
