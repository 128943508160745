@import '../../../variables.scss';

.hotel-location-menu {
  @include centerItems();
  height: 24px;

  ul {
    display: flex;
    flex-direction: row;
    width: $pcBlock;
    white-space: pre-wrap;
    @include font(Roboto-Light, 0.75rem, normal, normal, normal, 1.333em, normal);

    :last-child {
      font-family: Roboto-Medium;
    }
  }

  .location-separator {
    margin: 0 5px 0 10px;
  }
}

@media only screen and (max-width: $tabletScreen) {
  .hotel-location-menu {
    ul {
      width: $tabletBlock;
    }
  }
}
