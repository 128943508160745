@import '../../../variables.scss';

.hotels-gallery {
  @include centerItems();
  flex-direction: column;
  padding: 40px 0 0px 0;

  h2 {
    margin-bottom: 40px;
  }

  .item {
    transition: box-shadow 200ms;
    -webkit-transition: box-shadow 200ms;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0.33));
      transition: background 1s;
      -webkit-transition: background 1s;
    }
    &:hover {
      &::after {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
        transition: background 1s;
        -webkit-transition: background 1s;
      }
    }

    @media only screen and (min-width: $tabletScreen+1) {
      &:hover {
        box-shadow: 0px 15px 25px -15px rgba(5, 11, 51, 0.16);
        transition: box-shadow 200ms;
        -webkit-transition: box-shadow 200ms;
      }
      &:hover img {
        transition: 1s;
        -webkit-transition: 1s;
        transform: scale(1.07);
      }
    }
  }
}

.hotels-flex-gallery {
  width: $pcBlock;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 48px;
  border-bottom: 1px solid #cfcfdd;
  .custom-empty-btn {
    display: flex;
    align-items: center;
    @include font(Roboto-Regular, 1.125rem, normal, normal, normal, 1.25rem, normal);
    background: $bg_clr0;
    // opacity: 0.8;
    border-radius: 6px;
    color: $txt_clr4;
    border: initial;
    padding: 14px 24px;
    height: 48px;
  }

  .item {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: block;
    height: 263px;
    width: 384px;
    margin-top: 24px;
    border-radius: $inputRadius;

    &-header {
      @include font(Roboto-Regular, 1.25rem, normal, normal, normal, 1.2em, normal);

      h4 {
        @include preventOverSize();
        @include font(Roboto-Medium, 2rem, normal, normal, normal, 2.5rem, normal);
        color: $txt_clr0;
        margin-bottom: 24px;
        white-space: normal;
      }
      @media only screen and (max-width: $tabletScreen) {
        h4 {
          font-size: 1.5rem;
          line-height: 1.75rem;
        }
      }

      p {
        @include preventOverSize();
        text-decoration: underline;
        color: rgba(255, 255, 255, 0.5);

        span {
          color: $txt_clr0;
        }
      }
    }

    > div {
      position: relative;
      z-index: $blockAboveImgInfo;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      width: 100%;
      height: 100%;
      z-index: 3;
      @media only screen and (min-width: $tabletScreen+1) {
        &:hover {
          .custom-empty-btn {
            visibility: visible;
            opacity: 0.8;
            transition: opacity 250ms;
            -webkit-transition: opacity 250ms;
          }
        }
      }

      .custom-empty-btn {
        @media only screen and (min-width: $tabletScreen+1) {
          visibility: hidden;
          opacity: 0;
          transition: opacity 250ms;
          -webkit-transition: opacity 250ms;
        }
      }
    }

    .empty-img {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > img,
    .empty-img {
      object-fit: cover;
      position: absolute;
      z-index: $blockImgBg;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition: 1s;
      -webkit-transition: 1s;
    }

    &-footer {
      .bold-txt {
        margin-left: 6px;
        font-family: Roboto-Medium, sans-serif;
      }

      .hotels-count {
        color: $txt_clr0;
        margin-bottom: 0;

        > :first-child {
          font-family: Roboto-Regular, sans-serif;
        }
      }

      .hotels-count,
      .price {
        line-height: 1.75em;
      }
    }
  }

  .item.lg {
    width: 590px;
  }
}

@media only screen and (max-width: $tabletScreen) {
  .hotels-flex-gallery {
    width: $tabletBlock;

    .item {
      width: 312px;
      height: 240px;
      &::after {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.28), rgba(0, 0, 0, 0.28));
        transition: background 1s;
        -webkit-transition: background 1s;
      }

      & > div {
        padding: 13px;
      }
    }

    .item.lg {
      width: 478px;
      height: 240px;
    }
  }
}

@media only screen and (max-width: $mobileScreen) {
  .hotel-card.bordered.bot-pad {
    padding-bottom: 24px;
  }

  .hotels-gallery {
    background: transparent;
    padding: 44px 0 20px 0;

    h2 {
      padding: 0 16px;
      width: 100%;
      margin-bottom: 14px;
    }
  }
  .hotels-flex-gallery {
    width: 100%;
    .item {
      margin: 10px 8px;
      width: 283px;
      // width: 47%;
      height: 240px;

      &-header {
        font-size: 1rem;
        line-height: 1.187em;
      }

      & > div {
        padding: 13px;

        h4 {
          font-size: 1.25rem;
          line-height: 1.2em;
          margin-bottom: 8px;
        }
      }

      &-footer {
        font-size: 1rem;
        line-height: 1.5em;
        color: $txt_clr0;
      }
    }

    .item.lg {
      width: 283px;
    }
  }
}
@media only screen and (max-width: 790px) {
  .hotels-gallery .hotels-flex-gallery {
    justify-content: center;
    .item,
    .item.lg {
      width: 47%;
      &:last-of-type {
        width: 96%;
      }
    }
  }
}
@media only screen and (max-width: 590px) {
  .hotels-gallery .hotels-flex-gallery {
    justify-content: initial;
    .item,
    .item.lg {
      width: 283px;
      &:last-of-type {
        width: 283px;
      }
    }
  }
}
