@import '../../../variables';

%centered-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.promo-block {
  //remove it if you want to use it global and fixed
  width: 100%;
  height: 50px;
  background-color: $bg_clr11;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: $txt_clr0;

  &.fixed-promo-block {
    display: none;
    color: $txt_clr0;
    @include font(Roboto-Regular, 1rem, 400, normal, normal, 1.187em, normal);
    @media only screen and (min-width: $tabletScreen+1) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 10;
      height: 50px;
      background-color: $bg_clr11;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      &.hide-promo {
        position: absolute;
      }
      + header {
        margin-top: 50px;
      }
    }
  }

  > div {
    @extend %centered-content;
    height: 100%;
  }

  .md-font {
    font-family: Roboto-Medium, sans-serif;
    font-weight: 500;
  }

  .promo-arrow {
    margin: 0 9px;

    &.rotate {
      transform: rotateY(180deg);
    }
  }

  .copy-field-container {
    width: 189px;
    height: 36px;
    margin: 0 9px;
    opacity: 1;
    border-radius: 3px;
    @extend %centered-content;

    > div:not(.cb-txt) {
      background: rgba(255, 255, 255, 0.83);
    }

    .cb-txt {
      border-radius: 3px;
      background: #1c33c8c2;
    }

    .copy-value {
      cursor: initial;
      flex-grow: 1;
      padding: 0;
      @extend %centered-content;
      @include font(Roboto-Regular, 1rem, 400, normal, normal, 1.187em, normal);
      color: $txt_clr4;
      text-decoration: none;

      span {
        width: 100%;
        text-align: center;
        border-right: 1px solid #709409;
      }

      + div {
        width: 76px;
        text-align: center;
        color: $txt_clr4;
        text-decoration: none;

        span {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
