@import '../../../variables';

.home-page {
  .suggestions-page {
    .hotel-search {
      background-color: initial;
    }

    .search-form,
    .grid-suggestions {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .suggestions-page {
    .hotel-search {
      z-index: 1;
    }
  }

  &.txt-center {
    text-align: center;

    > span {
      display: inline-block;
      padding-top: 20px;
    }
  }

  .top-form-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $bg_clr1;

    .top-form-bg {
      position: absolute;
      background-image: url('../../../images/home-top-bg.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top;
      top: 0;
      z-index: $topFormBgZ;
      width: 100%;
      min-height: 560px;
      background-color: #f7fcfe;

      &.region-height {
        min-height: 350px;
        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(90deg, #ffffff 38.98%, rgba(255, 255, 255, 0.67) 54.12%, rgba(255, 255, 255, 0) 81.01%);
        }
      }
    }

    .top-form-content {
      position: relative;
      z-index: $topFormZ;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .main-inputs-form,
      .form-description-wrapper,
      .form-slogan {
        width: $pcBlock;
      }
      .main-inputs-form {
        .default-select-list.lg.type-check {
          &.hide-tags {
            .ant-select-selection-item {
              &:nth-child(3) {
                background-color: #ffffff;
              }
              @media only screen and (max-width: $tabletScreen) {
                &:nth-child(3) {
                  background-color: initial;
                }
                &:nth-child(2) {
                  background-color: #ffffff;
                }
              }
            }
          }
          .ant-select-selector .ant-select-selection-item {
            @media only screen and (max-width: $mobileScreen) {
              max-width: 200px;
            }

            .ant-select-selection-item-content {
              max-width: 180px;
              padding-top: 8px;
            }
          }
        }
      }

      .suggestions-page {
        .main-inputs-form {
          width: initial;
        }
      }

      .form-slogan {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //align-items: center;
        min-height: 350px;
        width: $pcBlock;

        + .fixed-filters-tab {
          .suggestions-header-wrapper {
            @media only screen and (min-width: $mobileScreen +1px) {
              border-top-color: $brdr_clr0;
            }
          }
        }

        .slogan-txt {
          display: flex;
          flex-direction: column;
          font-size: 2rem;
          line-height: 1.3em;
          margin-right: auto;
          word-break: break-word;

          &.lg {
            margin: 0;
            width: 50%;
            //margin-bottom: 40px;
            padding-top: 86px;

            @media only screen and (max-width: $mobileScreen) {
              padding-top: 0;
            }
          }

          .sub-headline {
            font-family: Roboto-Regular, sans-serif;
            margin-top: 20px;
            font-size: 1rem;
            line-height: 1.17em;
          }

          .hint-box {
            .hint-container {
              position: relative;
            }

            .custom-hint {
              top: -7px;
              left: -8px;
              font-size: 0.87rem;
              color: $txt_clr0;
            }
          }
        }

        .circle-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          background-color: white;
          padding: 17px 5px 0 5px;
          color: $txt_clr7;
          overflow: hidden;
          align-self: flex-end;
          margin-bottom: 26px;
          // margin-left: 40%;

          .icon-container {
            width: 100%;
            display: flex;
            justify-content: center;
          }

          .circle-price {
            font-size: 1.8rem;
            line-height: 1.13em;

            span {
              font-family: Roboto-bold, sans-serif;
            }
          }

          .circle-description {
            font-family: Roboto-Light, sans-serif;
            font-size: 0.91rem;
            line-height: 1.17em;
          }
        }
      }

      .form-description-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .form-description {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 58px;
          width: 100%;
        }

        .description-item {
          display: flex;
          align-items: flex-start;
          width: 384px;

          .custom-icon-ok {
            margin-right: 12px;
          }
        }
      }

      .circle-icons-container {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 680px;
        bottom: -30px;

        .circle-block.sm {
          @include centerItems();
          background-color: $bg_clr0;
        }
      }
    }
  }
}

@media only screen and (max-width: $tabletScreen) {
  .home-page {
    .top-form-wrapper {
      .top-form-bg {
        min-height: 414px;
        //background-image: url("../../../images/home-top-bg.jpg");
      }

      .top-form-content {
        .main-inputs-form,
        .form-description-wrapper,
        .form-slogan {
          width: $tabletBlock;
        }

        .form-slogan {
          // padding-top: 80px;
          align-items: flex-start;

          .slogan-txt {
            font-size: 1.5rem;

            margin-top: 10px;
          }

          .circle-block {
            background-color: $bg_clr0;
          }
        }

        .form-description-wrapper {
          .description-item {
            width: 309px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mobileScreen) {
  .home-page {
    .hotels-cards-block .hotel-card .cards-gallery .gallery-block.lg {
      width: 283px;
    }
    .slider-wrapper.mobile-gallery.hotels {
      padding-left: 0;
    }
    .hotels-flex-gallery {
      .slick-list {
        padding: 0 37px;
      }
    }
    .apply-btn.custom-empty-btn-mdx {
      margin-bottom: 0;
    }

    //.slider-wrapper.mobile-gallery.hotels .ant-carousel .slick-slide.slick-active {
    //  .gallery-block {
    //    width: 283px;
    //  }
    //}
    .grid-suggestions {
      padding: 0 16px;
    }

    .top-form-wrapper {
      padding: 0;

      .top-form-bg {
        display: none;
      }

      .top-form-content {
        width: 100%;

        .main-inputs-form,
        .form-description-wrapper,
        .form-slogan,
        .suggestions-page {
          width: 100%;
        }

        .form-slogan {
          padding: 30px 16px;
          min-height: initial;
          align-items: center;

          .slogan-txt {
            margin-top: 0;
            font-size: 1.125rem;
            line-height: 1.333em;

            &.lg {
              position: relative;
              margin-right: 10px;
              max-width: 350px;
              width: 100%;

              .custom-hint {
                //todo
              }
            }

            .hint-box .custom-hint {
              display: inline-block;
              position: static;
              margin-left: 5px;
            }
          }

          .circle-block {
            padding: 0;
            min-width: 60px;
            min-height: 60px;
            width: 60px;
            height: 60px;
            background-color: $bg_clr0;
            justify-content: center;
            align-self: auto;
            margin-bottom: 0;

            .circle-price {
              font-size: 0.875rem;
              line-height: 1.142em;
            }

            .circle-description {
              font-size: 0.562rem;
              line-height: 1em;
              padding: 2px 0;
            }

            .lg.custom-hint {
              width: 12px;
              height: 12px;
            }
          }
        }

        .form-description-wrapper {
          padding-left: 16px;
          padding-right: 16px;

          .form-description {
            flex-direction: column;
            align-items: center;
            margin-top: 34px;

            > :last-child {
              margin-bottom: 0;
            }
          }

          .description-item {
            display: flex;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 24px;

            .custom-icon-ok {
              margin-right: 12px;
            }
          }
        }

        .circle-icons-container {
          flex-wrap: wrap;
          justify-content: center;
          width: 100%;
          bottom: -45px;

          .circle-block {
            margin-right: 15px;
            margin-bottom: 15px;
          }

          > :first-child {
            margin-right: 15px;
          }
        }
      }
    }
  }
}
