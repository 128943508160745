@import '../../../variables';

.employee-card {
  border-radius: $inputRadius;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.14);

  .card-header {
    position: relative;
    z-index: $blockAboveImgInfo;
    min-height: 109px;
    border-radius: $inputRadius;
    padding: 23px 23px 16px 140px;
    background-color: $bg_clr0;

    > img {
      position: absolute;
      z-index: $blockImgBg;
      width: 126px;
      left: 0;
      bottom: 0;
    }

    h5 {
      font-family: Roboto-Light, sans-serif;
      font-size: 1rem;
      line-height: 1.25em;
      margin-bottom: 14px;
      white-space: pre-line;
      word-break: break-word;
    }

    .employee-name {
      font-family: Roboto-Medium, sans-serif;
      line-height: 1.062em;
      color: $txt_clr4;
      margin-bottom: 4px;
    }

    .employee-post {
      font-size: 0.75rem;
      line-height: 1.142em;
    }
  }

  .card-header.right-side {
    padding: 24px 200px 16px 24px;

    > img {
      transform: scale(-1, 1);
      left: initial;
      bottom: 0;
      right: 0;
    }
  }

  .card-footer {
    background: $bg_clr1;
    padding: 24px;
    border-radius: $inputRadius;
    min-height: 169px;

    h6 {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 2px;
    }

    .phone-number {
      display: flex;
      align-items: flex-start;
      @include font(Roboto-Bold, 1.5rem, 700, normal, normal, 1.75rem, 0.04px);
      justify-content: flex-start;
      color: $txt_clr10;
      margin-bottom: 11px;

      .custom-icon-phone {
        margin-top: 2px;
        margin-right: 14px;
      }
    }

    .content-txt {
      color: $txt_clr10;
      font-family: Roboto-Medium, sans-serif;
      font-size: 1.125rem;
      line-height: 1.1em;
      margin-bottom: 20px;
    }

    .working-time {
      margin-bottom: 4px;
      @include font(Roboto-Regular, 0.8125rem, normal, normal, normal, 1rem, normal);
      color: $txt_clr4;
    }

    .email-contact {
      h6 + p {
        @include font(Roboto-Regular, 1rem, normal, normal, normal, 1.5em, normal);
        color: $txt_clr10;
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: $mobileScreen) {
  .employee-card {
    margin-bottom: 44px;
  }
}
