@import '../../../variables';

.default-select-list.lg {
  width: 353px;
}

.default-select-list.sm {
  width: 100px;
}

.default-select-list.sm-mdx {
  width: 180px;
}

.default-select-list.sm-md {
  width: 84px;
}

.default-select-list.md {
  width: 392px;
}

.default-select-list {
  display: flex;
  flex-direction: column;
  @include font(Roboto-Regular, 0.87rem, 500, normal, normal, 1.14em, normal);
  //margin-right: 8px;

  &.tag-hide-mode {
    .ant-select-selection-item {
      padding: 0 10px;

      .ant-select-selection-item-remove {
        display: none;
      }
    }
  }

  &.error {
    border-color: $brdr_clr11 !important;
  }

  &.disabled {
    opacity: 0.5;
  }

  .custom-select-header {
    align-self: flex-start;
    font-family: Roboto-Medium, sans-serif;
    color: $txt_clr4;
    padding: 0;
    margin: 0 0 8px 15px;
  }

  .custom-select.ant-select-open {
    .ant-select-selector {
      box-shadow: none;
    }

    .ant-select-arrow {
      transform: rotate(180deg);
    }
  }

  .custom-select {
    .ant-select-arrow {
      top: 50%;
      right: 20px;
      width: 14px;
      height: 14px;
      transform-origin: 50% 50%;
      transition: transform 0.3s;
      img {
        width: 100%;
      }
    }

    .ant-select-selection-item {
      font-family: Roboto-Regular, sans-serif;
      font-size: 1rem;
      color: $txt_clr4;
    }

    .ant-select-selector {
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.14);
      border-radius: 4px;
      min-height: $input;
      border: none;
      display: flex;
      align-items: center;

      .ant-select-selection-placeholder {
        font-family: Roboto-Regular, sans-serif;
        font-size: 1rem;
        color: #777777;
      }

      &__clear {
        top: 50%;
      }

      &__rendered {
        margin: 0 15px;
      }
    }
  }
}

.ant-select-multiple {
  .ant-select-selection-item {
    background-color: $bg_clr1;
    border-color: transparent;
    max-width: 240px;
    height: 30px;
    max-height: 35px;

    .ant-select-selection-item-remove {
      margin-top: 1px;
      width: 13px;
      min-width: 13px;
      height: 13px;
      min-height: 13px;
      opacity: 0.7;
      background-image: url('../../../images/icons/cancel.svg');
      background-repeat: no-repeat;

      .anticon-close {
        svg {
          display: none;
        }
      }
    }

    &-content {
      font-family: Roboto-Light, sans-serif;
      padding-top: 2px;
      font-size: 0.75rem;
      line-height: 1.1666em;
      color: $txt_clr6;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      white-space: pre-line;
      height: 100%;
    }
  }
}

.ant-select-item-option:hover:not(.ant-select-item-option-disabled),
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: $bg_clr1;
}

.ant-select-dropdown-menu {
  .select-checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
}

.ant-select-item-group,
.ant-select-item-option {
  font-family: Roboto-Light, sans-serif;
  font-size: 0.9375rem;
  color: $txt_clr6;
  word-break: break-word;
  white-space: pre-line;
}

.ant-select-item-option-grouped {
  padding-left: 12px;
}

.ant-select-item-option {
  &-selected {
    font-weight: normal;
  }

  .anticon-check {
    display: none !important;
  }

  .check-box-wrapper {
    .ant-checkbox-inner {
      width: 12px;
      height: 12px;
      box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.54);

      &:after {
        top: 50%;
        width: 4px;
        height: 8px;
      }
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        box-shadow: none;
      }
    }
  }

  .ant-checkbox-wrapper {
    color: $txt_clr6;
  }
}

.ant-select-dropdown-menu-item-group-list {
  & > .ant-select-dropdown-menu-item {
    padding-left: 12px;
  }
}

@media only screen and (max-width: $tabletScreen) {
  .default-select-list.sm {
    width: 100px;
  }
  .default-select-list.sm-md {
    //width: 139px;
  }
  .default-select-list.md {
    width: 280px;
  }
}

@media only screen and (max-width: $mobileScreen) {
  .default-select-list {
    margin-right: 0;

    &.md {
      margin-top: 10px;
      width: 100%;
    }

    .custom-select-header {
      font-size: 0.75rem;
      line-height: 1.333em;
      margin: 0 0 4px 12px;
    }
  }
}
