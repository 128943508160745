@import '../../../variables.scss';

.pagination-block {
  @include centerItems();
  height: 48px;
  min-width: 432px;
  user-select: none;

  .figure-block {
    display: flex;
    flex-direction: row;
    height: 100%;
    margin: 0 auto;

    li {
      @include centerItems();
      cursor: pointer;
      margin: 0 16px;
      min-width: 16px;
      height: 100%;
      border-bottom: 4px solid transparent;
    }

    li.active {
      color: $txt_clr9;
      border-color: $brdr_clr8;
    }
  }

  .anticon.hide {
    visibility: hidden;
  }
}

@media only screen and (max-width: $tabletScreen) {
  .pagination-block {
    min-width: initial;
    padding: 0 12px;
    margin-bottom: 40px;
    max-width: 725px;

    .figure-block {
      padding: 0 12px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}
