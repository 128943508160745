@import '~antd/dist/antd.css';
@import './variables.scss';
@import './fonts/fonts.scss';

html body {
  color: $txt_clr4;
}

ul {
  margin-bottom: 0;

  li {
    list-style-type: none;
  }
}

.grid-container .left .cancellation-terms {
  font-family: Roboto-Bold, sans-serif;
}

.cancelation-fee {
  margin-bottom: 0;

  .days-container {
    font-family: Roboto-Bold, sans-serif;
    font-weight: bold;
    display: inline-block;
    min-width: 18px;
    text-align: center;
    margin-right: 4px;
    margin-left: 4px;

    &:nth-child(2) {
      margin-left: 5px;
    }
  }

  .row-with-percent span {
    margin-left: 4px;
    margin-right: 7px;
    font-family: Roboto-Bold, sans-serif;
  }
}

button:disabled,
button[disabled] {
  cursor: not-allowed;
}

.fixed-search-start-btn {
  width: 100%;
}

input,
button {
  border: none;

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }
}

a {
  text-decoration: none;
  color: $txt_clr4;

  &:hover {
    color: $txt_clr4;
  }

  &:visited {
    color: $txt_clr4;
  }

  &:active {
    color: $txt_clr4;
  }
}

.banner-attention-period {
  background-color: $bg_clr18;
}

.room-info-list {
  li {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .custom-icon {
    vertical-align: top;
    margin-right: 11px;
    background-repeat: no-repeat;
  }
}

.custom-icon {
  min-width: 24px;
  min-height: 24px;
  display: inline-block;

  &.sm {
    min-width: 16px;
    min-height: 16px;
  }

  &.sm-md {
    min-width: 18px;
    max-width: 18px;
    min-height: 18px;
    max-height: 18px;
  }

  &.md {
    background-size: cover;
    min-width: 25px;
    min-height: 25px;
  }

  &.check {
    background-size: contain;
    background-image: url('./images/service/check.png');

    &.green {
      background-image: url('./images/service/check-ic.svg');
    }
  }

  &.prevent {
    background-size: contain;
    background-image: url('./images/service/cancel.png');
  }

  &.handicapped {
    background-image: url('./images/service/handicapped.png');
  }

  &.lock {
    min-width: 11px;
    min-height: 16px;
    background-image: url('./images/icons/lock.svg');
  }

  &.express {
    background-image: url('./images/icons/americanExpress.svg');
    background-position: center;
    background-repeat: no-repeat;
    width: 55px;
    height: 22px;
  }

  &.visa {
    width: 56px;
    height: 26px;
    background-image: url('./images/icons/visa-logo.svg');
    background-position-y: 5px;
    background-repeat: no-repeat;
  }

  &.master-card {
    width: 35px;
    height: 26px;
    background-image: url('./images/icons/Mastercard-logo.svg');
    background-size: cover;
    background-position: center;
  }

  &.gallery {
    width: 32px;
    height: 32px;
    background-image: url('./images/icons/gallery.svg');
  }

  &-phone {
    min-width: 24px;
    min-height: 24px;
    display: inline-block;
    background-image: url('./images/icons/icon_phone.svg');
    background-position: center;
    background-repeat: no-repeat;
  }

  &-phone.bl {
    background-image: url('./images/icons/icon_phone_blue.svg');
  }

  &-close {
    min-width: 12px;
    min-height: 12px;
    background-image: url('./images/icons/cancel.svg');
  }

  &-slider-arrow {
    @extend .custom-icon;
    background-image: url('./images/icons/slider-arrow.svg');

    &.accent {
      background-color: black;
      border: 1px solid black;
      border-radius: 50%;
    }
  }

  &-star {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 1px;
    background-image: url('./images/icons/star.svg');
  }

  &-finger-up {
    display: inline-block;
    min-width: 18px;
    min-height: 18px;
    margin: 0 1px;
    background-image: url('./images/icons/like.svg');
    background-repeat: no-repeat;
  }

  &-ok {
    @extend .custom-icon;
    background-image: url('./images/icons/verified.svg');
  }

  &.arrow-back {
    min-width: 14px;
    min-height: 14px;
    display: inline-block;
    transform: rotate(90deg);
    background: url('./images/icons/down-arrow.svg');
  }
}

.meal-type {
  font-family: Roboto-Medium, sans-serif;
}

.meal-txt {
  padding-left: 4px;
}

.stars-block {
  margin-left: 5px;

  &.centered {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    height: 34px;
    max-width: 70px;
    @media only screen and (max-width: $mobileScreen) {
      height: 18px;
    }
  }
}

.modal-map-cursor {
  .stars-block {
    @include centerItems();
    justify-content: flex-start;
    margin: 0;
    padding: 8px 0;

    .custom-icon-star {
      top: initial;
    }
  }

  > div {
    z-index: 2;
    @include font(Roboto-Regular, 0.7rem, 500, normal, normal, 1.6em, normal);
    min-width: 200px;
    max-width: 200px;
    color: $txt_clr4;
    padding: 5px;
    text-align: left;
    left: 50%;
    background-color: $bg_clr0;
    margin-bottom: 15px;

    > :first-child {
      font-family: Roboto-Medium, sans-serif;
    }
  }
}

.circle-block {
  border-radius: 50%;
  box-shadow: 0 2px 12px 0 $bx_shdw1;

  &.fb {
    //temporary hide
    display: none;
    background-image: url('./images/fb.png');
    background-position: center;
  }

  &.instagram {
    //temporary hide
    display: none;
    background-image: url('./images/instagram.png');
    background-position: center;
  }

  &.ssl {
    background-image: url('./images/badgeSSL.png');
    background-position: center;
  }

  &.booksana {
    background-image: url('./images/badgeBooksana.png');
    cursor: pointer;
    background-position: center;
  }

  &.master-card {
    background-image: url('./images/badgeMasterCard.png');
    background-position: center;
  }

  &.visa {
    background-image: url('./images/Visa.png');
    background-position: center;
  }

  &.express {
    background-image: url('images/badgeAmericanExpress.png');
    background-position: center;
  }

  &.mega-sm {
    display: inline-block;
    border-radius: 100px;
    background-color: #d8d8d8;
    width: 20px;
    height: 20px;
  }

  &.extra-sm {
    @extend .circle-block;
    width: 32px;
    height: 32px;
  }

  &.sm {
    @extend .circle-block;
    width: 79px;
    height: 79px;
  }

  &.sm.norton {
    @extend .circle-block;
    width: 78px;
    height: 78px;
  }

  &.lg {
    @extend .circle-block;
    width: 119px;
    height: 119px;
  }

  &.mini {
    @extend .circle-block;
    width: 32px;
    height: 32px;
  }
}

.custom-hint {
  @include centerItems();
  border-radius: 100px;
  background-repeat: no-repeat;
  background-position: center;

  &.sm {
    @extend .custom-hint;
    width: 16px;
    height: 16px;
  }

  &.lg {
    @extend .custom-hint;
    height: 24px;
    width: 24px;
  }

  &.black {
    background-image: url('./images/icons/iconInfo-black.svg');
  }

  &.white {
    display: inline-block;
    margin: 0 10px 0 15px;
    box-shadow: -0px 0px 3px #888;
    border-radius: 50%;
    background-image: url('./images/icons/iconInfo-white.svg');
  }

  &.blue {
    background-image: url('./images/icons/iconInfo-blue.svg');
  }
}

.active-link {
  font-family: Roboto-bold, sans-serif;
}

.hint-box {
  position: relative;

  .custom-hint {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .empty-search {
    padding: 20px;
    color: $txt_clr14;
    text-align: center;
  }
}

a:hover {
  color: $txt_clr8;
}

.custom-btn {
  height: $input;
  background-color: $bg_clr7;
  @include font(Roboto-Medium, 1.12rem, normal, normal, normal, 1.6em, normal);
  border-radius: $inputRadius;
  color: $txt_clr0;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: $bg_clr6;
  }

  &.show-more-rooms {
    width: 100%;
    height: 48px;
    background-color: initial;
    border: 1px solid $brdr_clr8;
    color: $txt_clr9;
    @include font(Roboto-Medium, 1.125rem, 500, normal, normal, 1.25em, normal);
    margin-top: 24px;

    &:hover {
      background-color: $bg_clr6;
      border-color: $brdr_clr7;
      color: $txt_clr0;
    }

    @media only screen and (max-width: $mobileScreen) {
      font-size: 1rem;
    }
  }

  &-md {
    @extend .custom-btn;
    width: 354px;
  }

  &.sm {
    width: 220px;
    height: 48px;
  }
}

.btn-spinner {
  color: $txt_clr11;

  svg {
    width: 30px;
    height: 30px;
  }
}

.discount-type {
  color: rgba(255, 255, 255, 0.8);
}

a.underlined-link {
  font-size: 1rem;
  color: $txt_clr9;
  font-family: Roboto-Light, sans-serif;

  span {
    text-decoration: underline;
  }

  &.md {
    font-family: Roboto-Medium, sans-serif;
  }

  :hover {
    color: $txt_clr8;
  }
}

.bold-inscription {
  font-family: Roboto-bold, sans-serif;
}

.btns-block-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.custom-empty-btn {
  height: $input;
  background-color: transparent;
  border: 1px solid $brdr_clr8;
  padding: 0 20px;
  @include font(Roboto-Regular, 1.12rem, 500, normal, normal, 1.6em, normal);
  color: $txt_clr9;
  border-radius: $inputRadius;
  cursor: pointer;
  transition: color 0.2s, background-color 0.2s;

  &:hover {
    background-color: $bg_clr6;
    border-color: $brdr_clr7;
    color: $txt_clr0;
  }

  &.sm {
    margin-bottom: 8px;
    padding: 0 10px;
    width: max-content;
    height: 48px;
    font-size: 1rem;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &.link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $txt_clr9;

    & svg {
      margin-right: 5px;
      width: 11px;
      height: 11px;
    }

    &:hover {
      color: $txt_clr0;
    }
  }

  &-md {
    @extend .custom-empty-btn;
    min-width: 270px;
  }

  &-mdx {
    @extend .custom-empty-btn;
    min-width: 309px;
  }
}

.with-checkbox {
  .ant-select-item-option-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .custom-option-txt {
    padding: 0 8px;
  }
}

.empty-results {
  padding: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: $bg_clr0;
  border-radius: $inputRadius;

  > img {
    width: 64px;
    height: 64px;
    margin-bottom: 20px;
  }

  h4 {
    @include font(Roboto-Medium, 1.5rem, normal, normal, normal, normal, normal);
    margin-bottom: 15px;
  }

  p {
    @include font(Roboto-Regular, 1.2rem, normal, normal, normal, 1.6rem, normal);
    white-space: pre-wrap;
  }
}

.fake-box {
  display: inline-block;
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  border-radius: 2px;
  box-shadow: none;
  border: solid 1px $brdr_clr14;
  position: relative;

  .box-checked {
    display: none;
    width: 14px;
    height: 14px;
    position: absolute;
    top: -1px;
    left: -1px;
    border-radius: 2px;
    background-color: $bg_clr11;

    &.checked {
      display: block;
    }

    &:after {
      position: absolute;
      display: table;
      top: 50%;
      right: 6px;
      width: 4px;
      height: 8px;
      border: 2px solid #fff;
      border-top: 0;
      border-left: 0;
      -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
      -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: ' ';
    }

    //8px pad
  }
}

.popup-action {
  cursor: pointer;

  &.decorated {
    text-decoration: underline;
  }
}

.with-discount-price-color,
.current-price span {
  font-size: 1.5rem !important;
  line-height: 1.75rem !important;
}

.old-price {
  color: $txt_clr14;
  @include font(Roboto-Regular, 1rem, normal, normal, normal, 1.5rem, 0.03px);
  margin-right: 10px;

  > span {
    position: relative;

    &::after {
      position: absolute;
      border-top: 1px solid $brdr_clr13;
      transform: rotate(-5.32deg);
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      left: 0;
      top: 50%;
    }
  }
}

.date-from-to {
  font-family: Roboto-Bold, sans-serif;
  font-size: 0.8125rem;
  line-height: 1.0625rem;

  > :first-child {
    font-family: Roboto-Regular, sans-serif;
  }

  + .nights-count {
    font-family: Roboto-Bold, sans-serif;
    font-size: 0.8125rem;
    line-height: 1.0625rem;
  }
}

.catering {
  @extend .date-from-to;
}

.price-direction {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;

  .current-price-txt,
  .current-price {
    font-size: 1.125rem !important;
    line-height: 1.25rem !important;
  }

  .old-price {
    margin: 0;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  + .tax-bill {
    padding: 0 !important;
  }
}

.current-price {
  color: $txt_clr4;
  @include font(Roboto-bold, 1.43rem, normal, normal, normal, normal, 0.04px);
}

.current-price-txt {
  color: $txt_clr9;
  @include font(Roboto-Regular, 1.43rem, normal, normal, normal, normal, 0.04px);
}

.banner {
  position: absolute;
  border-radius: 5.4px 5.4px 0 5.4px;
  top: 12px;
  left: 12px;

  &-attention-container {
    position: absolute;
    top: 0;
    text-align: center;
    width: 100%;
    display: flex;
    @include font(Roboto-Medium, 0.813rem, 500, normal, normal, 1.063rem, normal);
    background: $bg_clr18;
    color: $txt_clr0;
  }

  &-attention {
    padding: 4px;
    margin: 0 auto;
    @media only screen and (max-width: $tabletScreen) {
      display: flex;
      flex-direction: column;
    }

    .discount-type {
      text-transform: uppercase;
      color: $txt_clr0;
    }

    .percents-discount {
      padding-right: 4px;

      &.sm {
        text-transform: initial;
        margin: 0;
      }
    }
  }

  &-attention-period {
    // font-family: Roboto-Regular, sans-serif;
    // font-size: 0.8rem;
    // padding: 4px;
  }

  &-profit {
    @extend .banner;
    @include centerItems();
    @include font(Roboto-Regular, 0.75rem, 400, normal, normal, 1.16em, normal);
    padding: 6px 6px 6px 10px;
    color: $txt_clr0;
    background: $bg_clr10;

    &.spa-package {
      font-size: 0.875rem;
      line-height: 1rem;
      padding: 2px 6px;
      border-radius: 6px;
    }
  }
}

.mark-block {
  display: flex;
  flex-direction: row;
  align-items: center;

  .mark.md {
    font-size: 1.5rem;
    width: 48px;
    height: 48px;
  }

  .mark.sm {
    width: 34px;
    height: 34px;
  }

  .block-txt {
    font-size: 0.87rem;
    line-height: 1.14em;
    color: $txt_clr13;
    margin-right: 6px;
  }

  .mark {
    border-radius: 6px 6px 0 6px;
    margin-right: 6px;

    & + span {
      font-size: 0.8125rem;
      line-height: 1.14em;
    }

    &-good {
      @extend .mark;
      @include centerItems();
      background-color: $bg_clr11;
      color: $txt_clr0;

      & + span {
        color: $txt_clr13;
      }
    }
  }
}

.extra-sm-txt {
  @include font(Roboto-Light, 0.75rem, 300, normal, normal, 1.33em, normal);
}

.section-header {
  @include font(Roboto-Medium, 1.5rem, 500, normal, normal, 1.75rem, normal);
  text-align: center;
}

.working-time {
  @include font(Roboto-Light, 0.75rem, normal, normal, normal, 1.333em, normal);
  color: $txt_clr10;
}

.hotels-search-block {
  width: $pcBlock;

  h3 {
    @include font(Roboto-Medium, 1.5rem, normal, normal, normal, 1.166em, normal);
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    // > :last-child {
    //   font-family: Roboto-Regular, sans-serif;
    //   font-size: 1.25rem;
    // }
  }

  .search-results {
    position: relative;
    width: 100%;
  }
}

//stripe inputs
.StripeElement {
  height: 48px;
  background-color: white;
  border: 1px solid $brdr_clr3;
  border-radius: 2px;
  padding: 4px 11px;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.CalendarDay__default,
.CalendarDay__default:hover {
  border: initial;
}

$calendarDAteActive: #ffc733;
.CalendarDay__default.CalendarDay__selected_span {
  background-color: $calendarDAteActive;
  border: initial;
  transition: background-color 350ms, color 300ms;
  -webkit-transition: background-color 350ms, color 300ms;
}

.CalendarDay__default.CalendarDay__selected_span:hover,
.CalendarDay__default.CalendarDay__defaultCursor.CalendarDay__selected:hover,
.CalendarDay__default.CalendarDay__selected_end:hover {
  background-color: $bg_clr17;
  color: $black0;
  transition: background-color 350ms, color 300ms;
  -webkit-transition: background-color 350ms, color 300ms;
}

.CalendarDay:hover {
  background: $white1;
}

.CalendarDay__selected {
  background: $bg_clr9;
  // color: $txt_clr16;
  color: $txt_clr0;
  border-radius: 30px 0px 0px 30px;
  border: initial;
  transition: background-color 350ms;
  -webkit-transition: background-color 350ms;
}

.CalendarDay__default.CalendarDay__selected_end {
  border-radius: 0px 30px 30px 0px;
  transition: background-color 350ms;
  -webkit-transition: background-color 350ms;
}

.CalendarDay__selected_span:active,
.CalendarDay__hovered_span {
  background: $calendarDAteActive;
  border: initial;
  color: $txt_clr0;
}

.CalendarDay__hovered_span:hover {
  background: $bg_clr9;
  border-radius: 0px 30px 30px 0px;
  border: initial;
  color: $txt_clr0;
}

.slide-counter {
  position: absolute;
  bottom: 12px;
  z-index: 3;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 46px;
  background: $bg_clr4;
  color: $txt_clr0;
  padding: 8px 12px;
  @include font(Roboto, 0.625rem, normal, normal, normal, 0.875em, normal);
}

.DateRangePicker_picker {
  z-index: 10;
}

.DateInput {
  width: 140px;
  border-radius: 4px;

  &:after {
    content: '';
    position: absolute;
    background-image: url('./images/icons/calendar_icon.svg');
    display: block;
    top: 50%;
    right: 15px;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    z-index: 1;
    @media only screen and(max-width: $mobileScreen) {
      right: 8px;
    }
  }
}

.DateRangePickerInput {
  background-color: transparent;
}

.DateInput_input {
  position: relative;
  z-index: 2;
  line-height: 52px;
  text-overflow: ellipsis;
  padding: 0 40px 0 15px;
  border-top: 2px solid transparent;
  border-radius: 4px;
  font-family: Roboto-Regular, sans-serif;
  font-size: 1rem;
  background-color: transparent;

  &::-webkit-input-placeholder,
  &::-ms-input-placeholder {
    color: #777777;
    font-size: 1rem;
  }
}

.order-not-found {
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  text-align: center;
  white-space: pre-line;
  border: 2px solid $brdr_clr11;
  color: #af0808;
  background: rgba(255, 0, 0, 0.1);
}

.DateInput_input__focused {
  border-bottom: 2px solid $brdr_clr8;
}

.DateRangePickerInput_arrow {
  //width: 29px;
  width: 8px;
}

// ant-popover
.ant-popover {
  z-index: 999;
}

// antD modal styles
.vertical-center-modal {
  text-align: center;
  white-space: nowrap;

  &.full-size {
    @media (max-width: 767px) {
      .ant-modal {
        max-width: 100%;
        margin: 0;
        height: 100%;

        .ant-modal-content {
          height: 100%;
        }
      }
    }
  }
}

.vertical-center-modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.vertical-center-modal .ant-modal {
  display: inline-block;
  vertical-align: middle;
  top: 0;
  text-align: left;
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.6);
}

.custom-modal {
  width: $tabletBlock !important;
  border-radius: 4px;

  &.txt-box-modal:not(.room-modal),
  &.tax-bill-modal {
    max-width: 651px;
    width: 100%;
  }

  .modal-header {
    @include font(Roboto-Medium, 1.25rem, 500, normal, normal, 1.75, normal);
    color: $txt_clr4;
    margin-bottom: 30px;
  }

  .modal-header.sm {
    font-size: 0.875rem;
    line-height: 1rem;
  }

  .modal-header.md {
    font-size: 1.125rem;
    line-height: 1.25rem;
  }

  .modal-body {
    @include font(Roboto-Regular, 1rem, 400, normal, normal, 1.5rem, normal);
    white-space: initial;
    color: $txt_clr4;

    & ul {
      margin-left: 18px;
      padding-bottom: 20px;
    }

    & li {
      list-style-type: disc;
      list-style-position: outside;
      padding-bottom: 5px;
    }

    .apartment-options {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      div {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding-right: 15px;
        margin-top: 15px;
        min-width: 30%;
        font-size: 0.875em;

        .custom-icon {
          margin-right: 15px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
      }
    }

    .check-box-wrapper {
      .ant-checkbox-wrapper {
        justify-content: flex-start;
        color: $txt_clr6;
      }
    }

    > p:not(.cancelation-fee) {
      margin-bottom: 12px;
    }

    .params-list {
      li {
        margin: 0 0 14px 0;
      }
    }
  }

  .ant-modal-close {
    top: 24px;
    right: 24px;

    &-x {
      width: 24px;
      height: 24px;
      line-height: 24px;
    }
  }
}

.params-list {
  font-size: 0.875rem;
  line-height: 1em;

  li {
    @include centerItems();
    flex-direction: row;
    justify-content: flex-start;
    margin: 8px 0;

    .checkbox-option {
      flex-grow: 1;
    }
  }

  .checkbox-txt {
    //@include preventOverSize();
    color: $txt_clr4;
    display: block;
    width: 151px;
  }

  .positions-cont {
    @include preventOverSize();
    color: $txt_clr3;
    text-align: right;
    width: 42px;
  }

  .list-header {
    text-align: left;
    font-family: Roboto-Medium, sans-serif;
    justify-content: flex-start;
    margin: 28px 0 14px 0;
  }
}

//antd override
.ant-calendar-picker {
  &:hover {
    .ant-calendar-picker-input:not(.ant-input-disabled) {
      border: 1px solid $brdr_clr3;
    }
  }
}

.ant-input {
  &:focus {
    border-color: $brdr_clr3;
    box-shadow: none;
  }
}

.custom-popover {
  margin-right: 10px;

  .ant-popover-inner {
    > div {
      padding: 16px;
    }
  }

  .ant-popover-title {
    font-family: Roboto-Regular, sans-serif;
    padding: 0 0 8px 0;
    border-bottom: none;
    font-size: 1rem;
    color: $txt_clr5;
    line-height: 1.187em;
  }

  .ant-popover-inner-content {
    font-family: Roboto-Light, sans-serif;
    border-bottom: none;
    padding: 0;
    color: $txt_clr5;
    font-size: 1rem;
    line-height: 1.5em;
  }

  .transfer-txt {
    @include font(Roboto-Regular, 0.81rem, 400, normal, normal, 1.06rem, normal);

    button {
      color: $blue2;
      text-decoration: underline;
      background: none;

      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  &.home-header {
    .ant-popover-arrow {
      //left: 4px;
    }
  }

  &.home-price {
    .ant-popover-arrow {
      right: 51px;
    }
  }

  &.header-phone {
    .ant-popover-arrow {
      right: 9px;
    }
  }
}

.custom-popover.lg {
  max-width: 600px;
}

.custom-popover.md {
  max-width: 384px;
}

.custom-collapse {
  border: none;
  background: transparent;

  .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    left: initial;
    right: 0;
  }

  .ant-collapse-content {
    background: transparent;
    border: none;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 14px 0 0 0;
  }

  > .ant-collapse-item {
    border: none;
  }

  .ant-collapse-item > .ant-collapse-header {
    padding: 0 22px 0 0;
    margin-top: 10px;
  }

  &.options-collapse {
    .ant-collapse-header {
      @include font(Roboto-Medium, 0.875rem, normal, normal, normal, 1.142em, normal);
    }

    .params-list {
      .list-header {
        color: $txt_clr4;
        margin: 0 0 14px 0;
      }
    }
  }
}

.ant-calendar {
  border: none;
  width: 270px;

  ::placeholder {
    color: $txt_clr4;
    opacity: 1;
  }
}

.ant-calendar-input-wrap {
  display: flex;
  align-items: center;
  height: $input;
  @include font(Roboto-Regular, 1rem, normal, normal, normal, normal, normal);
  color: $txt_clr4;
}

.custom-radio.ant-radio-wrapper.sm-md {
  @include font(Roboto-Light, 1rem, normal, normal, normal, normal, normal);
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  span.ant-radio + * {
    padding-right: 11px;
    padding-left: 11px;
  }

  .ant-radio-checked {
    .ant-radio-inner {
      border-color: $brdr_clr6 !important;
      box-shadow: inset 0 0 0 1px black;

      &:after {
        background-color: $bg_clr5;
      }
    }
  }

  .ant-radio-inner {
    border-width: 1px;
    width: 20px;
    height: 20px;

    &:after {
      width: 10px;
      height: 10px;
      top: 4px;
      left: 4px;
    }
  }
}

.custom-radio.ant-radio-wrapper.sm {
  @include font(Roboto-Light, 1rem, normal, normal, normal, normal, normal);
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  span.ant-radio + * {
    padding-right: 11px;
    padding-left: 11px;
  }

  .ant-radio-inner {
    border-width: 1px;
    width: 16px;
    height: 16px;
  }
}

.custom-radio.ant-radio-wrapper.gray {
  $grayTheme: #979797;
  color: $txt_clr6;

  .ant-radio-inner {
    border-color: $grayTheme;

    &:after {
      background-color: $grayTheme;
    }
  }

  .ant-radio-checked {
    .ant-radio-inner {
      border-color: $grayTheme;
    }
  }

  .ant-radio:hover {
    .ant-radio-inner {
      border-color: $grayTheme;
    }
  }
}

.custom-radio.ant-radio-wrapper.gray {
  $lightTheme: #979797;

  .ant-radio-inner {
    border-color: $lightTheme;

    &:after {
      background-color: $lightTheme;
    }
  }

  .ant-radio-checked {
    .ant-radio-inner {
      border-color: $lightTheme;
    }
  }

  .ant-radio:hover {
    .ant-radio-inner {
      border-color: $lightTheme;
    }
  }
}

.ant-radio-wrapper {
  @include font(Roboto-Regular, 1rem, normal, normal, normal, normal, normal);
  color: $txt_clr4;

  .ant-radio-inner {
    border: 2px solid $brdr_clr5;
    width: 18px;
    height: 18px;
    background: transparent;

    &:after {
      background-color: $bg_clr4;
    }
  }

  .ant-radio-input:focus {
    & + .ant-radio-inner {
      box-shadow: none;
    }
  }

  .ant-radio-checked {
    .ant-radio-inner {
      border-color: $brdr_clr5;
    }
  }

  .ant-radio:hover {
    .ant-radio-inner {
      border-color: $brdr_clr5;
    }
  }
}

.tabs-slider-block,
.payment-page {
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: $txt_clr9;
  }

  .ant-tabs {
    color: $txt_clr4;
  }

  .ant-tabs-ink-bar {
    background-color: $bg_clr7;
  }

  .ant-tabs-nav .ant-tabs-tab-active a {
    color: $txt_clr9;
  }
}

.ant-tabs-nav {
  margin: 0 !important;
  background-color: $bg_clr0;
}

.room-notice {
  margin-top: 25px;
  padding-bottom: 50px;
  border-bottom: 1px solid $brdr_clr21;
  @include font(Roboto-Light, 0.9375rem, normal, normal, normal, 1.25em, normal);

  > :first-child {
    font-family: Roboto-Medium, sans-serif;
  }

  &.no-border {
    border-bottom: none;
  }
}

.ant-radio-input:focus + .ant-radio-inner {
  border-color: $brdr_clr5;
}

@media only screen and (max-width: $tabletScreen) {
  .DateInput {
    width: 142px;
  }
  .banner {
    &-attention {
      font-size: 0.7rem;
    }

    &-attention-period {
      font-size: 0.7rem;
    }
  }
  .custom-btn-md {
    width: 294px;
  }
}

@media only screen and (max-width: $mobileScreen) {
  .main-inputs-form .form {
    .DateRangePickerInput {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .DateInput {
        flex-grow: 1;
        max-width: calc(50% - 6px);
      }

      .DateRangePickerInput_arrow {
        display: none;
      }
    }
  }

  .hide-for-mobile {
    display: none;
  }

  .custom-popover.payments {
    max-width: 70vw;
  }

  .custom-popover {
    &.home-header {
      max-width: initial;
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
    }

    &.header-phone {
      max-width: 200px;
    }

    &.home-price {
      max-width: 200px;

      .ant-popover-arrow {
        right: 25px;
      }
    }
  }
  .ant-carousel {
    .slick-track {
      display: flex;
      flex-direction: row;
    }
  }
  .DateRangePicker {
    width: 100%;
  }
  .DateRangePickerInput {
    width: 100%;
  }
  .DateRangePickerInput_arrow {
    width: 2%;
  }
  .DateInput {
    width: 49%;
  }
  .vertical-center-modal {
    overflow: unset;
  }
  .custom-modal {
    width: 100% !important;
    height: 100%;
    margin: initial;
    padding-bottom: 0;
    // overflow: auto;

    .ant-modal {
      max-width: initial;
    }

    .ant-modal-close {
      top: 24px;
      right: 16px;

      &-x {
        width: 20px;
        height: 20px;
        line-height: 20px;
      }
    }
  }
  .ant-modal-content {
    height: 100%;
    overflow: auto;

    .ant-modal-body {
      padding: 24px 16px;
      height: 100%;
    }
  }
}

.ant-select-dropdown {
  padding: 0;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  opacity: initial;
}

.ant-select-dropdown.resize-dropdown {
  min-width: 343px !important;
}

.with-discount-price-color {
  color: $txt_clr15 !important;
}

.discount-price-color {
  color: $txt_clr9 !important;
}

.present-row {
  display: flex;
  align-items: center;
  padding-bottom: 3px;

  .tooltip-title {
    @include font(Roboto-Regular, 0.813rem, 400, normal, normal, 1rem, normal);
    margin-left: 6px;
    cursor: pointer;

    &.hoverable {
      &:hover {
        font-family: Roboto-Medium, sans-serif;
        font-weight: 500;
      }
    }
  }
}

@media only screen and (max-width: $mobileScreen) {
  .transfer-options.in-datepicker {
    display: flex;
    flex-direction: column;
    padding: 18px 18px 0 18px;
    background-color: #ffffffff;
    position: relative;
    z-index: 1;

    .calendar-transfer-txt,
    .calendar-elevator-input {
      //margin-top: 12px;
      //margin-bottom: 16px;
      @include font(Roboto-Regular, 0.81rem, 400, normal, normal, 1.06rem, normal);
    }

    .calendar-elevator-input {
      display: flex;
      padding-left: 0;

      .custom-input {
        height: 48px;
        width: 78px;
        border-radius: 2px;
        //margin-left: 26px;
        margin-right: 6px;
        flex-shrink: 0;

        input {
          height: 100%;
        }

        &.focus-transfer {
          border: 3px solid $txt_clr23;
          border-radius: 6px;
        }
      }
    }

    .calendar-elevator-icon {
      padding-top: 8px;
      margin-right: 6px;
      width: 40px;
      height: 40px;
      color: $txt_clr4;
    }

    .calendar-elevator-icon-red {
      color: $txt_clr23;
    }

    .calendar-elevator-red {
      font-weight: 600;
      font-size: 0.9rem;
      color: $txt_clr23;
    }

    .calendar-elevator-descr {
      margin-bottom: 6px;

      &.mb {
        margin-bottom: 10px;
      }
    }

    .calendar-elevator-link {
      text-decoration: underline;
      font-weight: 700;
    }

    .calendar-transfer-txt {
      padding-left: 26px;

      button {
        color: $blue2;
        text-decoration: underline;
        background: none;

        &:hover {
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
  .transfer-options.in-datepicker > :first-child {
    display: flex;
    flex-direction: column;

    > :first-child {
      margin-bottom: 12px;
    }
  }
}

.widget-hidden {
  .pe-pro-seal {
    display: none;
  }
}

.index-option {
  @include font(Roboto-Regular, 1rem, normal, normal, normal, 1.187em, normal);
  color: $txt_clr20;
}

#provenexpert_circle_widget_bafch {
  height: 79px;
  width: 110px;
  display: inline-block;

  img {
    height: 79px;
    width: 110px;
  }
}

@import 'components/mainPages/home/index.scss';
@import 'components/mainPages/hotelSuggestion/index.scss';
@import 'components/mainPages/hotelRooms/index.scss';
@import 'components/mainPages/cancelReservation/index.scss';
@import 'components/mainPages/payment/index.scss';
@import 'components/mainPages/promoCodeBlock/index.scss';
@import 'components/mainPages/infoPage/index.scss';
@import 'components/mainPages/logger/index.scss';
@import 'components/modals/mobileMainMenu/index.scss';
@import 'components/modals/sorting/index.scss';
@import 'components/modals/tableFilter/index.scss';
@import 'components/modals/elevator/index.scss';
@import 'components/modals/roomOptions/index.scss';
@import 'components/modals/detailInsurance/index.scss';
@import 'components/modals/taxBill/index.scss';
@import 'components/helpers/header/index.scss';
@import 'components/helpers/paymentHeader/index.scss';
@import 'components/helpers/room/index.scss';
@import 'components/helpers/priceView/index.scss';
//@import "components/helpers/preloader/index.scss";
@import 'components/helpers/footer/index.scss';
@import 'components/helpers/copyField/index.scss';
@import 'components/helpers/paginationBlock/index.scss';
@import 'components/helpers/inputTxt/index.scss';
@import 'components/helpers/footerTxtBlock/index.scss';
@import 'components/helpers/selectList/index.scss';
@import 'components/helpers/homeGallery/index.scss';
@import 'components/helpers/searchForm/index.scss';
@import 'components/helpers/checkBox/index.scss';
@import 'components/helpers/slider/index.scss';
@import 'components/helpers/hotelsGallery/index.scss';
@import 'components/helpers/breadCrumbs/index.scss';
@import 'components/helpers/employeeLgBoard/index.scss';
@import 'components/helpers/employeeCard/index.scss';
@import 'components/helpers/gridSuggestions/index.scss';
@import 'components/helpers/hotelName/index.scss';
@import 'components/helpers/swimmingBtn/index.scss';
@import 'components/helpers/index.scss';
@import 'components/helpers/offerRoom/index.scss';
