@import '../../../variables.scss';

.table-filter-modal {
  .modal-body {
    .custom-btn {
      width: 100%;
      margin-top: 28px;
      font-size: 0.9375rem;
    }

    .radio-container {
      display: flex;
      flex-direction: column;

      > :first-child {
        margin-top: 16px;
      }

      .radio-option {
        margin-bottom: 16px;
      }

      > :last-child {
        margin-bottom: 0;
      }

      > label {
        @include font(Roboto-Light, 0.8125rem, 400, normal, normal, 1em, normal);

        > :first-child {
          > span {
            border-color: $brdr_clr10;

            &:after {
              background-color: $bg_clr11;
            }
          }
        }

        > :last-child {
          padding: 0 12px;
        }
      }

      .ant-radio-wrapper-checked {
        @include font(Roboto-Medium, 0.8125rem, 500, normal, normal, 1.0625em, normal);
      }
    }
  }
}
