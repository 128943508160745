@import '../../../variables';

.footer-img-container {
  @include centerItems();
  border-bottom: 1px solid $brdr_clr12;

  & > div {
    @include centerItems();
    width: $pcBlock;
    height: 260px;

    .img-bord {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      & > img {
        margin-right: 44px;
        height: 258px;
        width: 263px;
      }

      .txt-block {
        @include centerItems();
        flex-direction: column;
        align-items: flex-start;

        h3 {
          line-height: 28px;
          @include font(Roboto-Medium, 1.25rem, normal, normal, normal, 1.75rem, normal);
          margin-bottom: 12px;
        }

        .main-txt {
          @include font(Roboto-Regular, 1.1rem, normal, normal, normal, 1.25rem, normal);
          color: $txt_clr16;
          text-align: left;
          width: 452px;
          margin: 0;
        }

        .phone-number {
          @include font(Roboto-Bold, 1.5rem, normal, normal, normal, 1.75rem, normal);
          color: $txt_clr10;
          margin: 19px 0 8px 0;
        }

        .working-time {
          @include font(Roboto-Light, 1rem, normal, normal, normal, 1.5rem, normal);
          color: $txt_clr4;
        }
      }
    }
  }
}

@media only screen and (max-width: $tabletScreen) {
  .footer-img-container {
    & > div {
      width: $tabletBlock;
    }
  }
}

@media only screen and (max-width: $mobileScreen) {
  .footer-img-container {
    display: none;
  }
  .employee-card-wrapper {
    width: 100%;
    padding: 60px 16px 0 16px;
    background: $bg_clr0;

    .employee-card {
      margin-bottom: 0;
    }
  }
}
