@keyframes showToast {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.logger-body {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  justify-content: flex-start;
  position: fixed;
  height: 100vh;
  right: 0;
  top: 0;
  width: 10px;
  z-index: 100;

  .logger-item {
    padding: 19px 14px;
    min-height: 65px;
    width: 336px;
    margin-top: 10px;
    background: $bg_clr0;
    box-shadow: 0px 4px 6px rgba(5, 11, 51, 0.107982);
    border-radius: 0px 0px 4px 4px;
    transform: translateX(100%);
    animation: showToast 1s forwards;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    border-top: 4px solid transparent;
    @media only screen and(max-width: $mobileScreen) {
      width: 100vw;
    }

    &.info {
      border-top-color: $brdr_clr8;
      color: $txt_clr9;
    }

    &.ok {
      border-top-color: $brdr_clr10;
      color: $txt_clr13;
    }

    &.warn {
      border-top-color: $brdr_clr9;
      color: $txt_clr12;
    }

    &.err {
      border-top-color: $brdr_clr13;
      color: $txt_clr15;
    }

    span {
      word-break: break-word;
      margin-right: auto;
    }

    svg {
      min-height: 20px;
      min-width: 20px;
      margin-right: 14px;
    }

    img {
      margin-left: 14px;
      width: 13px;
      height: 13px;
      cursor: pointer;
    }
  }
}
