@import '../../../variables.scss';

.payment-header {
  min-height: 72px;
  display: flex;
  align-items: center;
  border: 1px solid #cfcfdd;

  .pe-richsnippets {
    padding: 0 8px;
  }

  .inner-container {
    width: 100%;
    max-width: $pcBlock;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .left {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .circle-block-wrapper {
        display: flex;
        justify-content: center;
        margin-left: 48px;

        .circle-block {
          background-size: contain;
          box-shadow: 0 1px 4px 0 rgba(0, 20, 72, 0%);
          width: 48px;
          height: 48px;

          &:nth-child(2) {
            margin-left: 8px;
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      .employee-img {
        min-width: 48px;
        min-height: 48px;
        border-radius: 50%;
        background-color: #e3eff7;
        margin-right: 18px;
        position: relative;
        overflow: hidden;

        img {
          min-width: 48px;
          max-width: 48px;
          min-height: 48px;
          max-height: 48px;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .contact-data {
        .phone-number {
          display: flex;
          align-items: center;
          font-size: 1.12rem;
          color: $txt_clr10;
        }

        .working-time {
          font-size: 0.81rem;
          color: $txt_clr4;
          margin: 0;
        }
      }
    }
  }

  @media only screen and (max-width: $tabletScreen) {
    padding: 0 30px;
  }

  @media only screen and (max-width: $mobileScreen) {
    .inner-container {
      .left {
        .circle-block-wrapper {
          margin-left: 22px;
        }
      }
    }
  }
}
