@import '../../../variables.scss';

.payment-page {
  .mask {
    position: relative;
    z-index: 1;

    &:after {
      position: absolute;
      z-index: 2;
      background: rgba(255, 255, 255, 0.7);
      cursor: not-allowed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      content: '';
    }
  }

  .StripeElement {
    transition: border 0.3s;
  }

  .ant-input,
  .ant-select,
  .StripeElement {
    &:focus,
    &:hover:not(.index-search-box) {
      border: 1px solid $brdr_clr8 !important;
    }
  }

  .error-border {
    .ant-input {
      &:focus,
      &:hover:not(.index-search-box) {
        border: 1px solid $brdr_clr11 !important;
      }
    }
  }

  .selected-apartments {
    padding: 24px 16px;
    background-color: $white1;

    .center-container {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 8px;
    }

    .name-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 16px;
    }

    .hotel-name {
      &-rating {
        font-size: 1.12rem;
      }

      .address-wrapper {
        font-size: 0.81rem;
      }
    }

    .hotel-image {
      width: 80px;
      height: 50px;
      margin-left: 16px;
      flex-shrink: 0;

      img {
        width: 100%;
        height: auto;
      }
    }

    .catering,
    .guestCount {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.87rem;

      .label {
        display: flex;
        align-items: center;

        svg {
          margin-right: 12px;
        }
      }
    }

    .catering {
      margin-bottom: 8px;
    }
  }

  .navigate-wrapper {
    width: 100%;
    padding-top: 14px;
    background-color: $bg_clr0;
    box-shadow: inset 0px -2px 0px rgba(207, 207, 221, 0.2);

    .ant-tabs-nav-wrap {
      width: 100%;
    }

    .ant-tabs-nav-list {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;

      .ant-tabs-tab {
        justify-content: center;
        border-bottom: solid 2px $brdr_clr18;

        .ant-tabs-tab-btn {
          width: 100%;

          a {
            display: block;
            padding: 8px 30px;
            width: 100%;
            text-align: center;
          }
        }

        &-active {
          border-bottom: solid 2px $brdr_clr8;

          .ant-tabs-tab-btn {
            a {
              color: $txt_clr9;
            }
          }
        }
      }
    }

    .ant-tabs-nav {
      background-color: transparent;

      &:before {
        border-bottom: none;
      }
    }

    .fixedNav {
      .ant-affix {
        z-index: 11;
      }
    }

    .ant-tabs-tab {
      @include font(Roboto-Regular, 1rem, normal, normal, normal, 1.5em, normal);
      padding: 0;
      margin: 0;
      flex-grow: 1;
    }
  }

  .discount-price-color {
    > span {
      color: $txt_clr19 !important;
    }
  }

  .header-block {
    width: $pcBlock;

    h1 {
      margin: 20px 0 0 0;
    }

    > span {
      font-family: Roboto-Light, sans-serif;
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
    }
  }

  .wrapper-card-room .banner-attention-container {
    display: none;
  }

  .hotel-room {
    width: $pcBlock;
    background-color: $bg_clr0;
    min-height: 370px;
    border: initial;

    .actions-popup-wrapper .popup-action {
      margin-right: auto;
      margin-left: initial;
    }

    .left {
      padding: 0;
      padding-right: 24px;

      .room-img-wrapper {
        min-height: 370px;
        max-height: 370px;
      }
    }

    @media only screen and (max-width: $tabletScreen) {
      min-height: 384px;
      .left .room-img-wrapper {
        min-height: 384px;
        max-height: 384px;
      }
    }

    &.with-discount {
      border: initial;
      border-radius: 8px;
    }

    .popup-action {
      margin-left: auto;
    }

    .order-options-info {
      .date-from-to {
        font-family: Roboto-Regular, sans-serif;
      }

      .header-txt {
        font-size: 1.125rem;
        line-height: 1.25rem;
        font-family: Roboto-Bold, sans-serif;
      }

      .nights-count {
        font-family: Roboto-Medium, sans-serif;
      }

      .catering {
        font-family: Roboto-Bold, sans-serif;

        > :first-child {
          font-family: Roboto-Regular, sans-serif;
        }
      }
    }

    .float-wrapper {
      .banner-profit.spa-package.room-note {
        font-size: 0.875rem;
        line-height: 1rem;
      }

      .room-accordion-options.with-present.open {
        max-height: initial;
        height: initial;
      }

      .right {
        min-width: 378px;
        max-width: 378px;
        min-height: 370px;
        @media only screen and (max-width: $tabletScreen) {
          min-height: 384px;
          min-width: 338px;
          max-width: 338px;
        }

        .payment-types {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-top: auto;
          @media only screen and (max-width: $tabletScreen) {
            svg {
              width: 65px;
            }
            .custom-icon {
              &:not(:last-of-type) {
                margin-right: 2px !important;
              }

              &.express {
                max-width: 38px;
                max-height: 40px;
                background-size: contain;
              }
            }
          }

          .custom-icon {
            margin-right: 10px;
          }

          :last-child {
            margin-right: 0;
          }

          .extra-sm-txt {
            font-size: 0.812rem;
          }
        }

        .footer-btn-wrapper {
          display: none;
        }

        .price-block .tax-bill {
          padding: 0;
          color: $txt_clr22;
          @include font(Roboto-Regular, 0.813rem, normal, normal, normal, 1rem, normal);
        }
      }
    }
  }

  .empty-order {
    padding: 20px;
    text-align: center;
    color: $txt_clr14;
  }

  .iban-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-right: 40px;
    font-size: 1rem;
    line-height: 1.5em;
    color: $txt_clr5;
    font-family: Roboto-Light, sans-serif;

    .check-box-wrapper {
      margin-top: 5px;
    }
  }

  .banner-profit {
    position: static;
    display: inline-block;
  }

  .banner-profit-wrapper {
    display: inline-flex;
    align-items: center;
    margin-bottom: 16px;

    svg {
      width: 16px;
      height: 16px;
    }

    .banner-profit {
      @include font(Roboto-Medium, 0.87rem, 500, normal, normal, 1rem, normal);
      color: $txt_clr4;
      border-radius: 6px;
      padding: 6px 12px;
      margin-right: 12px;
    }
  }

  .hotel-details {
    margin-bottom: 22px;

    & .expander {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-right: 30px;
      @include font(Roboto-Regular, 0.8125rem, 500, normal, normal, 1rem, normal);
      color: $txt_clr22;

      > svg {
        margin-left: 8px;
      }

      &:not(.active) {
        margin-top: 16px;
      }

      span {
        text-decoration: underline;
        cursor: pointer;
      }

      &.active {
        align-items: center;
        padding: 20px 24px 0 0;
        @media only screen and (min-width: $tabletBlock +1px) {
          span {
            &:hover {
              color: $txt_clr22;
            }
          }
        }

        > svg {
          transform: rotate(180deg);
        }
      }
    }

    .opened-description {
      display: flex;
      flex-direction: column;

      .room-notice {
        padding-right: 24px;
        border: none;
        padding-bottom: 0;
        margin-top: 0;

        strong {
          color: $txt_clr4;
        }

        p {
          color: $txt_clr22;
          margin-bottom: 0;
          font-family: Roboto-Light, sans-serif;
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
      }
    }
  }

  .guests-count {
    margin-top: 8px;
    padding: 0 24px;
    @media only screen and (max-width: $tabletScreen) {
      padding: 0 16px;
    }

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-family: Roboto-Regular, sans-serif;
      font-size: 0.8125rem;
      line-height: 1.0625rem;
      color: $txt_clr6;
    }

    > :last-child {
      padding-bottom: 6px;
    }
  }

  .mobile-view {
    display: none;
  }

  .extra-sm-txt {
    padding: 0 24px;
    @media only screen and (max-width: $tabletScreen) {
      padding: 0 16px;
    }
  }

  .final-sum-wrapper {
    margin-top: 13px;

    .final-sum {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: none;

      .sum-direction {
        display: flex;
        align-items: center;
        text-align: right;
        margin-left: auto;

        .old-price {
          color: $txt_clr19;

          .discount-price-color {
            color: $txt_clr19 !important;

            &::after {
              border-top: 1px solid $txt_clr19;
              transform: initial;
            }
          }
        }

        .old-price,
        .old-price .discount-price-color,
        .with-discount-price-color,
        .with-discount-price-color span,
        .final-price {
          font-size: 1.125rem !important;
          line-height: 1.5rem !important;
          font-family: Roboto-Bold, sans-serif;
          font-weight: 700;
        }

        .old-price,
        .old-price .discount-price-color {
          font-family: Roboto-Regular, sans-serif;
          font-weight: 400;
        }
      }
    }
  }

  .extra-sm-txt {
    @extend .extra-sm-txt;
    color: $txt_clr6;
    font-size: 0.875rem;
    line-height: 1.142em;
  }

  // .selected-apartments {
  //   &-wrapper {
  //     background-color: $bg_clr2;
  //     @include centerItems();
  //     flex-direction: column;
  //   }

  //   &-card {
  //     display: flex;
  //     flex-direction: row;
  //     width: $pcBlock;
  //     min-height: 309px;
  //     margin: 60px 0;
  //     overflow: hidden;
  //     border-radius: $inputRadius;
  //     box-shadow: 0 2px 16px 0 rgba(21, 31, 99, 0.22);

  //     .left,
  //     .right {
  //       padding: 24px;
  //       min-height: 309px;
  //     }

  //     .left {
  //       width: 792px;
  //       background-color: $bg_clr1;
  //       display: flex;
  //       flex-direction: column;

  //       .hotel-address {
  //         white-space: pre-wrap;
  //       }

  //       h2 {
  //         display: flex;
  //         flex-direction: column;
  //         flex-wrap: wrap;
  //         margin-bottom: 0;
  //         color: $txt_clr9;
  //         @include font(Roboto-Medium, 1.5rem, normal, normal, normal, normal, normal);

  //         .name-wrapper {
  //           display: flex;
  //           flex-direction: row;
  //           flex-wrap: wrap;
  //         }

  //         > span {
  //           display: flex;
  //           align-items: flex-end;
  //         }

  //         .stars-block {
  //           display: inline-block;
  //           margin-left: 5px;

  //           .custom-icon-star {
  //             position: relative;
  //             top: -16px;
  //           }
  //         }

  //         .extra-sm-txt {
  //           color: $txt_clr6;
  //           margin-bottom: 2px;
  //         }
  //       }

  //       article {
  //         display: flex;
  //         flex-direction: row;
  //         margin-top: 15px;

  //         > h3 {
  //           display: none;
  //         }

  //         .room-info-wrapper {
  //           display: flex;
  //           flex-direction: column;
  //           //justify-content: space-between;
  //         }

  //         .room-img {
  //           object-fit: cover;
  //           width: 360px;
  //           height: 209px;
  //           margin-right: 24px;
  //         }

  //         .room-info-list {
  //           display: flex;
  //           flex-direction: column;
  //           max-width: 360px;

  //           > :first-child {
  //             font-family: Roboto-Regular, sans-serif;
  //           }

  //           li {
  //             @include font(Roboto-Light, 1rem, normal, normal, normal, 1.187em, normal);
  //             color: $txt_clr6;
  //             white-space: pre-wrap;
  //             word-break: break-word;
  //             margin-bottom: 14px;

  //             .decor {
  //               text-decoration: underline;
  //               color: rgba(0, 0, 0, 0.5);
  //               white-space: pre-line;

  //               span {
  //                 color: $txt_clr5;
  //               }
  //             }

  //             &:last-of-type {
  //               margin-bottom: 0;
  //               flex-wrap: wrap;

  //               > :last-child {
  //                 text-decoration: underline;
  //                 display: flex;
  //                 width: 100%;
  //                 flex-direction: column;
  //                 flex-wrap: wrap;
  //                 justify-content: space-between;

  //                 :first-child {
  //                   margin: 14px 0;
  //                 }
  //               }
  //             }

  //             &.medical-options,
  //             &.treatment-option {
  //               //margin-bottom: 0;
  //               & + .catering-option {
  //                 margin-top: 0;
  //               }
  //             }

  //             &.catering-option {
  //               margin-top: 14px;
  //             }
  //           }
  //         }
  //       }

  //       .popup-links {
  //         padding-top: 14px;

  //         span {
  //           text-decoration: underline;
  //           font-family: Roboto-Light, sans-serif;
  //         }

  //         :first-child {
  //           margin-right: 20px;
  //         }

  //         :last-child {
  //           margin-right: 0;
  //         }
  //       }

  //       .popover-description {
  //         //margin-top: 14px;
  //         display: flex;
  //         flex-direction: row;
  //         align-items: center;
  //         justify-content: flex-start;

  //         .custom-hint {
  //           margin-left: 0;
  //         }
  //       }
  //     }

  //     .right {
  //       flex-grow: 1;
  //       max-width: 408px;
  //       background-color: $bg_clr0;
  //       display: flex;
  //       flex-direction: column;

  //       h3 {
  //         @include font(Roboto-bold, 1.25rem, normal, normal, normal, 1.4em, normal);
  //         color: $txt_clr6;
  //       }

  //       .date-from-to {
  //         color: $txt_clr6;
  //         line-height: 1.25em;
  //         margin-top: 4px;
  //         margin-bottom: 16px;
  //         display: flex;
  //         flex-wrap: wrap;

  //         .nights-count {
  //           @include font(Roboto-bold, 1rem, normal, normal, normal, normal, normal);
  //           margin-left: auto;
  //         }
  //       }

  //       .guests-count {
  //         padding: 12px 0;
  //         border-top: 1px solid #d6d6d6;
  //         border-bottom: 1px solid #d6d6d6;

  //         > :last-child {
  //           padding: 0;
  //           border: none;
  //         }
  //       }

  //       .tax-bill {
  //         margin-top: 5px;
  //         text-align: right;
  //         margin-bottom: 10px;

  //         & > :last-child {
  //           text-decoration: underline;
  //           cursor: pointer;
  //         }

  //         & > :first-child {
  //           text-decoration: none;
  //         }
  //       }
  //     }
  //   }
  // }

  .header-txt {
    &.sm {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
      color: $txt_clr4;

      > :first-child {
        margin-bottom: 3px;
      }

      .dates-block,
      .nights-count {
        @include font(Roboto-Regular, 0.87rem, 400, normal, normal, 1.12rem, normal);
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        svg {
          @include fixed-size(16px);
          margin-right: 12px;
        }
      }

      .nights-count {
        .count-num {
          margin-right: 4px;
        }
      }
    }
  }

  .payment-block-wrapper {
    @include centerItems();
    background-color: $bg_clr0;
    padding: 32px 0 16px 0;

    .ant-radio {
      .ant-radio-inner {
        border-color: $bg_clr16;
      }

      &.ant-radio-checked {
        .ant-radio-inner {
          border-color: $bg_clr16 !important;
          box-shadow: inset 0 0 0 1px $bg_clr16;

          &:after {
            background-color: $bg_clr16;
          }
        }
      }
    }

    .payment-block {
      display: flex;
      flex-direction: row;
      max-width: $pcBlock;
      width: 100%;

      .border-form {
        border: 1px solid $brdr_clr3;
        background-color: $bg_clr0;

        .payment-summary {
          @include font(Roboto-Regular, 1.375rem, normal, normal, normal, 1.27em, normal);
          color: $txt_clr9;
          margin-bottom: 17px;
        }
      }

      .conclusion-form {
        padding: 24px;
        border-radius: $inputRadius;
        background-color: $bg_clr0;

        .conclusion-pros {
          padding-top: 16px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          @media only screen and (max-width: $tabletScreen) {
            justify-content: flex-start;
          }

          @media only screen and (max-width: $mobileScreen) {
            justify-content: center;
          }

          .payment-list {
            width: 314px;
            margin-bottom: 17px;

            @media only screen and (max-width: $mobileScreen) {
              width: auto;
            }
          }

          .payment-item:not(:last-child) {
            margin-bottom: 5px;
          }

          .payment-item {
            position: relative;
            padding-left: 22px;
            margin-right: 0;
            @include font(Roboto-Regular, 0.8rem, normal, normal, normal, 1.27em, normal);
            color: $txt_clr13;

            &-bold {
              font-weight: bold;
            }

            &:before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              background-image: url('../../../images/icons/green-arrow.png');
              background-position: center;
              background-repeat: no-repeat;
              display: block;
              @include fixed-size(18px);
            }

            &:last-child {
              &:before {
                background-image: url('../../../images/icons/flag.png');
                background-size: cover;
              }
            }
          }
        }

        .coupon-field {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-bottom: 12px;
          padding-top: 12px;
          padding-right: 39px;
          border-bottom: 1px dashed $brdr_clr18;

          .bold-txt {
            font-family: Roboto-Bold, sans-serif;
          }

          > span {
            font-family: Roboto-Regular, sans-serif;
          }
        }

        .extra-sm-txt {
          text-align: right;

          span {
            position: relative;
            top: 18px;
          }

          > :last-child {
            text-decoration: underline;
          }

          > :first-child {
            text-decoration: none;
          }
        }

        .custom-collapse {
          .ant-collapse-item > .ant-collapse-header {
            display: inline-block;
            @include font(Roboto-Regular, 0.81rem, 400, normal, normal, 1rem, normal);
            text-decoration: underline;
            color: $txt_clr22;
          }

          .content-txt {
            padding-bottom: 0;
            margin-bottom: 0;

            .present-row {
              .tooltip-title {
                @include font(Roboto-Light, 1rem, 300, normal, normal, 1.5rem, normal);
                color: $txt_clr4;
                transition: font-weight 300ms, font-family 300ms;
                -webkit-transition: font-weight 300ms, font-family 300ms;

                &:hover {
                  @include font(Roboto-Medium, 1rem, 500, normal, normal, 1.5rem, normal);
                  transition: font-weight 300ms, font-family 300ms;
                  -webkit-transition: font-weight 300ms, font-family 300ms;
                }
              }
            }
          }

          + .guests-count {
            li {
              @include font(Roboto-Light, 1rem, 300, normal, normal, 1.5rem, normal);
              margin-bottom: 4px;
              padding-bottom: 0;

              .transfer-trigger {
                display: flex;
                align-items: flex-start;
              }
            }
          }
        }

        .content-txt {
          @include font(Roboto-Light, 1rem, 300, normal, normal, 1.5rem, normal);
          color: $txt_clr5;

          @media only screen and (max-width: $mobileBlock) {
            font-family: Roboto-Bold, sans-serif;
          }
        }

        .check-box-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin: 13px 0;

          > div {
            @include font(Roboto-Light, 1rem, 300, normal, normal, 1.5rem, normal);
            display: flex;
            flex-direction: row;
            margin-bottom: 11px;
            color: $txt_clr5;
            white-space: pre-wrap;
            word-break: break-word;

            @media only screen and (max-width: $mobileScreen) {
              font-size: 0.8rem;
            }

            &:last-of-type {
              margin-bottom: 0;
            }

            a {
              @include font(Roboto-Light, 1rem, 300, normal, normal, 1.5rem, normal);
              color: $txt_clr9;
              text-decoration: underline;

              @media only screen and (max-width: $mobileScreen) {
                font-size: 0.8rem;
              }

              &:hover {
                color: $txt_clr8;
              }
            }
          }
        }

        .check-data-msg {
          padding-top: 13px;
          margin-bottom: 26px;
          line-height: 1.25rem;
          font-size: 1rem;
          color: $txt_clr6;
        }

        .footer-controls {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .btn-back {
            @include centerItems();

            &:hover span {
              color: $txt_clr8;
            }

            .custom-icon {
              margin-right: 6px;
            }

            span {
              text-decoration: underline;
              font-size: 1rem;
              color: $txt_clr5;
            }
          }

          .custom-btn {
            width: 314px;
            height: 49px;
          }

          @media only screen and (max-width: $mobileScreen) {
            flex-direction: column;

            .btn-back {
              order: 2;
            }

            .custom-btn {
              order: 1;
              width: 100%;
              margin-bottom: 26px;
            }
          }
        }
      }

      .left {
        min-width: 384px;
        position: relative;

        .border-form {
          padding: 16px;
          border-radius: 6px;
          position: sticky;
          top: 87px;
        }

        h1 {
          margin-bottom: 4px;
          @include font(Roboto-Regular, 1.2rem, 400, normal, normal, 1.75rem, normal);

          .stars-block.centered {
            align-items: flex-start;
            height: initial;
          }
        }

        .address-wrapper {
          @include font(Roboto-Regular, 0.81rem, 400, normal, normal, 1rem, normal);
          margin-bottom: 16px;
          display: block;
        }

        .pic-wrapper {
          margin-bottom: 16px;
          height: 105px;
          width: 50%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            @media only screen and (max-width: $mobileScreen) {
              width: 50%;
            }
          }
        }

        .dates-block,
        .nights-count,
        .catering {
          display: flex;
          align-items: center;
          @include font(Roboto-Regular, 0.875rem, 400, normal, normal, 1.125rem, normal);
          margin-bottom: 8px;

          svg {
            min-width: 16px;
            max-width: 16px;
            min-height: 16px;
            max-height: 16px;
            margin-right: 12px;
          }
        }

        .catering {
          margin-bottom: 16px;

          > span {
            margin-right: 12px;
          }
        }

        .ant-collapse.custom-collapse {
          margin-bottom: 16px;
        }

        .booking-item-options {
          margin-bottom: 15px;

          .wrapper-with-checkbox {
            display: flex;
            flex-direction: column;

            .item-with-checkbox {
              display: flex;
              border-bottom: 1px dashed $brdr_clr18;

              .item-icon {
                position: relative;
                top: 12px;
              }

              &.inactive-row {
                .ant-collapse-item .ant-collapse-header {
                  color: $txt_clr19;
                }

                svg path {
                  fill: $txt_clr19;
                }
              }

              &.partly-inactive {
                .check-box-wrapper.padding .ant-checkbox-checked .ant-checkbox-inner {
                  background-color: initial;
                  border: 1px solid $brdr_clr10;
                  box-shadow: 0 0 1px 0 $brdr_clr10;

                  &::after {
                    transform: initial;
                    border: initial;
                    border-top: 1px solid $brdr_clr10;
                    box-shadow: 0px -0.5px 0px 0px $brdr_clr10;
                    transition: initial;
                    width: 8px;
                  }
                }
              }
            }
          }

          .check-box-wrapper.sm.hide_txt.padding {
            position: relative;
            top: 8px;
          }

          .check-box-wrapper {
            .ant-checkbox-inner {
              border-radius: 1px;
            }

            .ant-checkbox {
              border: 1px solid $brdr_clr19;
              box-shadow: 0 0 1px 0 $brdr_clr19;

              &.ant-checkbox-checked {
                border: initial;
                box-shadow: initial;
              }
            }
          }

          .ant-collapse {
            width: 100%;
            background-color: transparent;

            .ant-collapse-item {
              border: initial;

              .anticon.ant-collapse-arrow {
                display: none;
              }

              .ant-collapse-header {
                background-color: $bg_clr0;
                display: flex;
                align-items: center;
                width: 100%;
                padding-left: 0;
                padding-right: 0;

                .final-sum {
                  border-top: initial;
                }

                .option-info-header {
                  display: flex;
                  align-items: center;
                  width: 100%;

                  .price-option {
                    margin-left: auto;
                  }
                }
              }

              .collapse-header-form {
                display: flex;
                justify-content: space-between;
                width: 100%;

                p {
                  display: flex;
                  align-items: center;
                  margin-bottom: 0;
                  width: 100%;
                  max-width: 400px;

                  svg {
                    min-width: 16px;
                    max-width: 16px;
                    min-height: 16px;
                    max-height: 16px;
                    margin-right: 8px;
                  }
                }

                .final-sum {
                  margin-top: 0;
                  padding-top: 0;

                  .price.price-direction {
                    .old-price,
                    .current-price .with-discount-price-color,
                    .current-price .with-discount-price-color span,
                    .current-price span {
                      font-family: Roboto-Medium, sans-serif;
                      font-weight: 500;
                    }
                  }
                }
              }

              .final-sum {
                margin-top: 0;
                padding-top: 0;

                .price.price-direction {
                  .old-price,
                  .current-price .with-discount-price-color,
                  .current-price .with-discount-price-color span,
                  .current-price span {
                    font-size: 0.875rem !important;
                    font-family: Roboto-Regular, sans-serif;
                    font-weight: 400;
                  }
                }
              }

              .ant-collapse-content-box {
                padding-top: 7px;
                padding-left: 0;
                padding-right: 0;

                .person-item-row {
                  display: flex;
                  align-items: center;
                  @include font(Roboto-Regular, 0.813rem, 400, normal, normal, 1rem, normal);
                  color: $txt_clr22;
                  // padding-right: 25px;
                  transition: background-color 0.2s;
                  -webkit-transition: background-color 0.2s;

                  &.inactive {
                    color: $txt_clr19;
                  }

                  &:not(:last-child) {
                    margin-bottom: 6px;
                  }

                  &:hover {
                    background-color: $bg_clr13;
                    transition: background-color 0.2s;
                    -webkit-transition: background-color 0.2s;
                    cursor: pointer;
                  }

                  .option-index {
                    padding-left: 3px;
                  }

                  span:first-child {
                    white-space: nowrap;
                  }

                  .final-sum {
                    margin-top: 0;
                    padding-top: 0;
                    border-top: initial;

                    .current-price {
                      color: $txt_clr22;
                    }
                  }
                }

                .price-for-person {
                  margin-left: auto;
                  font-size: 0.875rem;
                }
              }
            }

            &:first-child {
              // padding-left: 25px;
              border-bottom: 1px dashed $brdr_clr21;

              .ant-collapse-content-box {
                padding-left: 25px;
              }
            }
          }

          .coupon-field {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .title-row {
            @include font(Roboto-Regular, 0.875rem, 400, normal, normal, 1.125rem, normal);
          }

          .ant-collapse,
          .ant-collapse-content {
            border: none;
          }

          .transfer-row {
            display: flex;
            align-items: baseline;
            border-bottom: 1px dashed $brdr_clr18;
            margin-bottom: 13px;

            .item-icon {
              top: 8px;
              position: relative;
            }

            .check-box-wrapper.sm.hide_txt.padding {
              position: relative;
              top: 6px;
            }

            &.inactive-row {
              .ant-collapse-item .ant-collapse-header {
                color: $txt_clr19;
              }

              svg path {
                fill: $txt_clr19;
              }
            }

            &.partly-inactive {
              .check-box-wrapper.padding .ant-checkbox-checked .ant-checkbox-inner {
                background-color: initial;
                border: 1px solid $brdr_clr10;
                box-shadow: 0 0 1px 0 $brdr_clr10;

                &::after {
                  transform: initial;
                  border: initial;
                  border-top: 1px solid $brdr_clr10;
                  box-shadow: 0px -0.5px 0px 0px $brdr_clr10;
                  transition: initial;
                  width: 8px;
                }
              }
            }
          }

          .insurance-row {
            display: flex;
            align-items: center;
            width: 100%;
            padding-top: 15px;

            .insurance-price {
              margin-left: auto;
              padding-right: 39px;
            }
          }

          .item-icon {
            min-width: 16px;
            max-width: 16px;
            min-height: 16px;
            max-height: 16px;
            margin-right: 8px;
          }

          .insurance-price,
          .price-option,
          .bold-txt {
            @include font(Roboto-Medium, 0.875rem, 500, normal, normal, 1.063rem, normal);
          }
        }

        .hotel-taxes {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 13px;
          line-height: 16px;
          color: $txt_clr22;
          margin-bottom: 6px;

          .room-fees-popover {
            display: flex;
            align-items: center;
            margin-right: auto;
            color: $black0;

            > svg {
              margin-left: 6px;

              @include fixed-size(16px);
            }
          }

          .popup-action {
            text-decoration: underline;
          }
        }
      }

      .right {
        padding-left: 24px;
        width: calc(100% - 384px);

        .payment-info {
          margin-bottom: 17px;
        }

        .payment-title {
          margin: 0 0 17px;
          @include font(Roboto-Regular, 2rem, bold, normal, normal, 1.27em, normal);
          color: $txt_clr9;

          @media only screen and (max-width: $mobileScreen) {
            font-size: 1.3rem;
          }
        }

        .payment-list {
          margin-bottom: 17px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
        }

        .payment-item {
          position: relative;
          margin-right: 30px;
          padding-left: 20px;
          @include font(Roboto-Regular, 1rem, normal, normal, normal, 1.27em, normal);
          color: $txt_clr13;

          &-bold {
            font-weight: bold;
          }

          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            background-image: url('../../../images/icons/green-arrow.png');
            background-position: center;
            background-repeat: no-repeat;
            display: block;
            @include fixed-size(18px);
          }
        }

        //.payment-details {
        //  margin: 0;
        //  @include font(Roboto-Regular, 1rem, normal, normal, normal, 1.27em, normal);
        //  color: $txt_clr6;
        //}

        .border-form {
          padding: 24px;

          &:nth-child(2) {
            border-radius: 6px 6px 0 0;
          }
        }

        .custom-input {
          input {
            @include font(Roboto-Regular, 1rem, normal, normal, normal, 1.5rem, normal);
            color: $txt_clr4;
          }
        }

        .payment-calendar {
          width: 518px;
        }

        .ant-calendar-picker {
          .ant-input {
            height: 48px;
            font-size: 1rem;
            color: $txt_clr20;
          }
        }

        .custom-radio {
          font-family: Roboto-Regular, sans-serif;
        }

        .input-container {
          &.relative {
            position: relative;

            .txt-area-state {
              position: absolute;
              left: 16px;
              top: 8px;
              @include font(Roboto-Regular, 0.8125rem, 400, normal, normal, 1rem, normal);
              color: $txt_clr19;
              z-index: 2;
            }
          }

          .index-search-box {
            min-width: 165px;
            max-width: 165px;

            @media only screen and (max-width: $tabletScreen) {
              min-width: 213px;
              max-width: 213px;
            }
            @media only screen and (max-width: $mobileScreen) {
              min-width: 100px;
              max-width: 100px;
              margin-right: 8px;
            }

            &:hover {
              .ant-select-selector {
                border-color: rgb(217, 217, 217);
              }
            }

            &.error-state {
              .ant-select-selector {
                border-color: $brdr_clr13;
                box-shadow: 0 0 0 1px $brdr_clr13;
              }
            }

            .ant-select-selector {
              height: 48px;
              @include font(Roboto-Regular, 1rem, normal, normal, normal, 1.187em, normal);
              color: $txt_clr20;

              .ant-select-selection-search-input {
                height: 48px;
              }

              .ant-select-selection-item {
                line-height: 48px;
              }
            }
          }
        }

        textarea.ant-input {
          @include font(Roboto-Regular, 1rem, 400, normal, normal, 1.5rem, normal);
          padding: 30px 16px 8px 16px;
          resize: none;

          &::placeholder {
            color: $txt_clr22;
            font-size: 1rem;
          }
        }

        .receiving-address {
          .form-header {
            margin-bottom: 24px;
          }

          .address-checkboxes {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            @media only screen and(max-width: $mobileScreen) {
              flex-direction: column;
              align-items: initial;
              .radio-box:not(:last-child) {
                margin-bottom: 16px;
              }
            }
          }

          @keyframes slidemenu {
            0% {
              height: 0;
              opacity: 0;
              transition: opacity 300ms;
              -webkit-transition: opacity 300ms;
            }
            100% {
              height: 223px;
              opacity: 1;
              transition: opacity 300ms;
              -webkit-transition: opacity 300ms;
            }
          }

          .grid-form {
            opacity: 1;
            transition: opacity 300ms;
            -webkit-transition: opacity 300ms;

            &.address-fields {
              animation: slidemenu 0.6s;
            }
          }
        }

        @media only screen and(max-width: $mobileScreen) {
          .city-inputs {
            .input-container.sp-btw {
              align-items: center;
              flex-wrap: initial;

              .custom-input {
                margin-bottom: 0;

                &.inp-sm {
                  max-width: 100px;
                  margin-right: 8px;
                }
              }
            }
          }
        }

        &.with-ergo-new {
          padding: 0;

          .ant-radio {
            top: 2px;
          }

          span.ant-radio + * {
            padding-left: 14px;
          }

          .top-content {
            padding: 24px;

            h3 {
              display: flex;
              justify-content: space-between;
            }

            .input-container.insurance-checkboxes {
              display: flex;
              flex-direction: column;

              .radio-box {
                label {
                  max-width: 511px;
                  @include font(Roboto-Regular, 1rem, normal, normal, normal, 1.5rem, normal);
                  width: 100%;
                }

                .label-checkbox {
                  white-space: pre-wrap;
                }

                &:nth-child(1) {
                  padding-bottom: 12px;
                }
              }
            }
          }

          .insurance-block {
            padding: 24px;
            background-color: $bg_clr13;

            .notice {
              padding-left: 36px;

              span {
                @include font(Roboto-Regular, 1rem, 400, normal, normal, 1.5rem, normal);
              }
            }

            .input-container.type-insurance {
              margin-top: 16px;

              .radio-box {
                margin-bottom: 16px;

                .title-checkbox {
                  @include font(Roboto-Medium, 1rem, 700, normal, normal, 1.5rem, normal);
                  padding-bottom: 8px;
                }

                .subtitle-checkbox {
                  @include font(Roboto-Regular, 1rem, 400, normal, normal, 1.5rem, normal);
                }
              }
            }

            .toggle-block {
              display: flex;
              align-items: center;
              margin-bottom: 24px;
              padding-left: 36px;

              .title-toggle {
                @include font(Roboto-Medium, 1rem, bold, normal, normal, 1.5rem, normal);
                margin-right: 24px;
              }

              button {
                height: 20px;
                max-height: 20px;
                width: 36px;
                max-width: 36px;
                min-width: initial;
                margin-right: 8px;
                background-color: $bg_clr17;
                border-radius: 12px;

                &.ant-switch-checked {
                  background-color: $bg_clr16;

                  .ant-switch-handle {
                    left: calc(100% - 12px - 4px);
                  }
                }

                .ant-switch-handle {
                  top: 4px;
                  left: 4px;
                  width: 12px;
                  height: 12px;
                }
              }
            }

            .cards-container {
              display: flex;
              align-items: center;
              width: 100%;

              .card-wrapper {
                &:not(:last-child) {
                  margin-right: 12px;
                }

                .card-insurance {
                  width: 100%;
                  max-width: 240px;
                  max-height: 449px;
                  border-radius: 8px;
                  margin-top: 42px;
                  transition: background-color 300ms;
                  -webkit-transition: background-color 300ms;

                  &:hover,
                  &.active-card {
                    cursor: pointer;
                    background-color: $bg_clr0;
                    transition: background-color 300ms;
                    -webkit-transition: background-color 300ms;
                  }

                  .content-card {
                    padding: 28px 12px 21px 12px;
                    width: 100%;
                    height: 100%;

                    .header-card {
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      border-bottom: 1px solid $brdr_clr10;
                      position: relative;

                      .tooltip-txt {
                        visibility: hidden;
                        opacity: 0;
                        @include font(Roboto-Regular, 0.813rem, 400, normal, normal, 1rem, normal);
                        display: flex;
                        position: absolute;
                        top: -40px;
                        left: 0;
                        background: $bg_clr0;
                        z-index: 10;
                        color: $txt_clr4;
                        border: 1px solid $brdr_clr18;
                        box-shadow: 0px 10px 10px -10px rgba(123, 145, 157, 0.15);
                        border-radius: 4px;
                        padding: 15px;
                        min-width: 200px;
                        transition: opacity 300ms;
                        -webkit-transition: opacity 300ms;
                      }

                      .title-card {
                        @include font(Roboto-Medium, 1.125rem, 500, normal, normal, 1.25rem, normal);
                        padding-bottom: 8px;
                        text-align: center;

                        &:hover .tooltip-txt {
                          visibility: visible;
                          opacity: 1;
                          transition: opacity 300ms;
                          -webkit-transition: opacity 300ms;
                        }
                      }

                      .details-block {
                        @include font(Roboto-Regular, 0.813rem, 400, normal, normal, 1rem, normal);
                        color: $txt_clr9;
                      }
                    }

                    .option-card {
                      display: flex;
                      flex-direction: column;
                      padding-top: 16px;
                      padding-bottom: 16px;
                      margin-bottom: 16px;
                      border-bottom: 1px solid $brdr_clr10;

                      .option-row {
                        display: flex;
                        @include font(Roboto-Regular, 0.813rem, 400, normal, normal, 1rem, normal);

                        svg {
                          min-width: 16px;
                          width: 16px;
                          height: 16px;
                          min-height: 16px;
                          margin-right: 8px;
                        }

                        &:not(:last-child) {
                          margin-bottom: 16px;
                        }

                        &.inactive {
                          color: $txt_clr19;
                        }
                      }
                    }

                    .footer-card {
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;

                      .modal-title {
                        @include font(Roboto-Regular, 0.813rem, 400, normal, normal, 1rem, normal);
                        padding-bottom: 9px;
                        color: $txt_clr9;
                      }

                      .footer-description {
                        @include font(Roboto-Medium, 0.813rem, 500, normal, normal, 1.063rem, normal);
                        padding-bottom: 9px;
                      }

                      .price-card {
                        @include font(Roboto-Medium, 1.5rem, bold, normal, normal, 1.75rem, normal);
                        color: $txt_clr13;
                        padding-bottom: 9px;
                      }

                      .ant-radio {
                        top: 0;

                        .ant-radio-inner {
                          border-color: $brdr_clr10;
                        }

                        &.ant-radio-checked {
                          .ant-radio-inner {
                            border-color: $brdr_clr10 !important;
                            box-shadow: inset 0 0 0 1px $brdr_clr10;

                            &:after {
                              background-color: $brdr_clr10;
                            }
                          }
                        }
                      }
                    }
                  }

                  &.promotion-card {
                    max-height: 491px;
                    border: 1px solid $brdr_clr10;
                    margin-top: 0;

                    .content-card {
                      padding-top: 16px;

                      .tooltip-txt {
                        top: -60px;
                      }
                    }

                    .promotion-header {
                      height: 74px;
                      width: 100%;
                      background-image: url('../../../images/bg_prom.svg');
                      background-position: center;
                      background-repeat: no-repeat;
                      padding: 12px 12px 14px 12px;

                      .promotion-title {
                        color: $txt_clr0;
                        @include font(Roboto-Regular, 1rem, 400, normal, normal, 1.5rem, normal);
                        margin-bottom: 0;
                        width: 100%;
                        text-align: center;
                        max-width: 156px;
                        margin: auto;

                        span:nth-child(1) {
                          font-family: Roboto-Medium, sans-serif;
                          font-weight: 700;
                          padding-right: 4px;
                        }
                      }
                    }
                  }
                }
              }
            }

            .insurance-description {
              display: flex;
              flex-direction: column;
              @include font(Roboto-Regular, 1rem, 400, normal, normal, 1.5rem, normal);
              margin-top: 24px;
              padding: 8px 15px 8px 24px;
              border-left: 8px solid $brdr_clr10;

              .row-with-price {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-bottom: 0;

                .price-card {
                  font-weight: 700;
                  font-family: Roboto-Medium, sans-serif;
                  padding-left: 5px;
                  padding-right: 5px;
                }
              }
            }

            @media only screen and (max-width: $tabletScreen) {
              .cards-container .card-wrapper .card-insurance {
                max-width: 300px;

                &.promotion-card {
                  .promotion-header {
                    background-size: cover;
                    background-position: bottom center;
                  }
                }
              }
            }
            @media only screen and (max-width: $mobileScreen) {
              .input-container.type-insurance .radio-box .subtitle-checkbox {
                white-space: pre-wrap;
              }
              .cards-container {
                flex-direction: column;

                .card-wrapper {
                  &:not(:last-child) {
                    margin-right: 0;
                    margin-bottom: 12px;
                  }

                  .card-insurance {
                    max-width: initial;
                    margin-top: initial;

                    &:not(.promotion-card) {
                      border: 1px solid $brdr_clr19;
                    }
                  }

                  .insurance-description {
                    margin-top: 12px;
                  }
                }
              }
            }
          }
        }

        .with-ergo {
          &-head {
            display: flex;
            justify-content: space-between;
            margin-bottom: 6px;
          }

          .radio-box {
            margin-bottom: 12px;

            .lbl {
              max-width: 466px;
              display: block;
              white-space: initial;
              line-height: 24px;
            }
          }
        }

        .option-info {
          @media only screen and (max-width: $mobileScreen) {
            .form-header {
              margin-bottom: initial;
            }
          }

          .option-block {
            display: flex;
            flex-direction: column;

            .option-wrapper {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-top: 16px;

              @media only screen and (max-width: $mobileScreen) {
                flex-direction: column;
                justify-content: initial;
                align-items: initial;
                .counter-price-block {
                  margin-top: 17px;

                  .total-price {
                    margin-left: auto;
                  }
                }
              }

              &.empty-option {
                color: $txt_clr19;
              }

              &:not(:last-child) {
                border-bottom: 1px dashed $brdr_clr18;
                padding-bottom: 25px;
              }

              .name-option {
                display: flex;
                flex-direction: row;
                align-items: center;
                @media only screen and (max-width: $mobileScreen) {
                  justify-content: space-between;
                }

                .name-card {
                  @include font(Roboto-Regular, 1rem, 400, normal, normal, 1.5rem, normal);
                  max-width: 280px;
                  word-break: break-word;
                }

                span:nth-child(2):not(.anticon-question-circle) {
                  @include font(Roboto-Regular, 1rem, 400, normal, normal, 1.5rem, normal);
                  padding-bottom: 4px;
                }

                span:last-child:not(.anticon-question-circle) {
                  @include font(Roboto-Regular, 0.813rem, 400, normal, normal, 1rem, normal);
                }

                .anticon.anticon-question-circle {
                  margin-left: 10px;

                  svg path {
                    fill: $txt_clr22;
                    transition: fill 300ms;
                    -webkit-transition: fill 300ms;
                  }

                  &:hover {
                    svg path {
                      fill: $bg_clr7;
                      transition: fill 300ms;
                      -webkit-transition: fill 300ms;
                    }
                  }
                }
              }

              .counter-price-block {
                display: flex;
                align-items: center;
                @media only screen and (max-width: $mobileScreen) {
                  flex-direction: column;
                  align-items: initial;
                  .counter-wrapper {
                    justify-content: space-between;
                    width: 100%;
                  }
                }

                .counter-wrapper {
                  display: flex;
                  align-items: center;

                  .counter-title {
                    @include font(Roboto-Regular, 0.813rem, 400, normal, normal, 1.063rem, normal);
                    margin-right: 12px;
                  }

                  .btn-counter {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border: 1px solid $brdr_clr18;
                    border-radius: 2px;
                    height: 32px;

                    p {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      height: 100%;
                      margin-bottom: initial;
                      min-width: 26px;
                    }

                    .count-icon {
                      height: 100%;
                      background-color: $bg_clr0;
                      width: 32px;
                      cursor: pointer;
                      transition: box-shadow 200ms;
                      -webkit-transition: box-shadow 200ms;

                      &:hover {
                        box-shadow: 0px 0px 0px 1px $bx_shdw0;
                        transition: box-shadow 200ms;
                        -webkit-transition: box-shadow 200ms;
                      }

                      &.minus {
                        border-radius: 2px 0px 0px 2px;
                      }

                      &.plus {
                        border-radius: 0 2px 2px 0;
                      }
                    }
                  }
                }

                .price-regulation {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  min-width: 180px;
                  margin-left: 20px;

                  @media only screen and (max-width: $mobileScreen) {
                    margin-left: 0;
                    width: 100%;
                    justify-content: space-between;
                    margin-top: 8px;
                  }

                  &.without-regulation {
                    min-width: initial;
                  }

                  .regulation-name {
                    color: $txt_clr22;
                    @include font(Roboto-Regular, 0.813rem, 400, normal, normal, 1rem, normal);
                  }

                  .total-price {
                    @include font(Roboto-Regular, 1rem, 400, normal, normal, 1.5rem, normal);
                    min-width: 60px;
                    text-align: right;
                  }
                }
              }
            }
          }
        }

        &.radius-border {
          border-radius: 0 0 6px 6px;
          border-bottom: 1px solid $brdr_clr3;
        }

        .form-header {
          @include font(Roboto-Regular, 1.375rem, normal, normal, normal, 1.27em, normal);
          color: $txt_clr9;
          margin-bottom: 17px;

          &.sm {
            font-size: 1.1rem;
          }

          &.top-pad {
            padding-top: 17px;

            + div {
              font-size: 0.937rem;
            }
          }
        }

        .grid-form {
          display: grid;
          align-items: center;
          grid-template-columns: 185px 556px;
          grid-auto-rows: auto;
          grid-row-gap: 22px;

          .inp {
            &-ex-sm {
              width: 126px;
            }

            &-sm {
              width: 165px;
            }

            &-md {
              width: 253px;
            }

            &-ex-md {
              width: 342px;
            }

            &-lg {
              width: 518px;
            }
          }

          .title-wrapper {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @media only screen and (max-width: $tabletScreen) {
              flex-direction: row;

              & > .input-title:first-child {
                margin-right: 5px;
              }
            }
          }

          .input-title {
            color: $txt_clr5;
            font-size: 0.9375rem;
            line-height: 1.333em;
            padding-right: 28px;

            &.with-popover {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .hint-icon {
                color: $txt_clr1;
              }

              .hint-icon:hover {
                color: $txt_clr9;
              }
            }
          }

          .input-container {
            display: flex;
            align-items: center;
            flex-direction: row;
            padding-left: 40px;
            position: relative;

            textarea {
              height: 100px;
              resize: none;

              &:hover {
                border-color: $grey2;
              }
            }

            .ant-radio-group {
              display: flex;
              flex-direction: row;

              &.new-page {
                width: 100%;
                justify-content: space-between;
              }

              &.error {
                .radio-box {
                  border-color: $brdr_clr11;
                }
              }

              & + .default-select-list {
                margin-left: auto;

                .ant-select-selection-placeholder {
                  color: $txt_clr20;
                  opacity: 0.6;
                }
              }
            }

            .custom-icon {
              margin-right: 36px;
            }

            .master-card {
              width: 44px;
              height: 33px;
            }

            .visa {
              width: 54px;
              height: 17px;
            }

            .express {
              width: 53px;
              height: 48px;
            }

            & + .extra-sm-txt {
              font-size: 0.75rem;
              line-height: 1.666em;
              margin-top: 4px;
              margin-left: 12px;
            }

            &.sp-btw {
              justify-content: space-between;
            }

            .hint-icon {
              top: 50%;
              color: $txt_clr1;
              margin-top: -8px;
              position: absolute;
              left: 0;

              &:hover {
                color: $txt_clr9;
              }
            }

            .radio-box {
              display: flex;
              height: 48px;
              align-items: center;
              flex-direction: row;
              padding-left: 15px;
              border: 1px solid $brdr_clr3;
              background-color: $bg_clr0;
              border-radius: 2px;

              .ant-radio-wrapper {
                margin-right: 0;
              }

              //&.no-b {
              //  border-left: none;
              //}

              & ~ .form-select {
                margin-left: auto;
              }
            }

            .form-select {
              margin-right: 0;
              box-shadow: none;

              .ant-select {
                border: 1px solid $brdr_clr3;

                &.ant-select-open {
                  border: 1px solid $brdr_clr8;
                }
              }

              .ant-select-selector {
                min-height: 46px;
                box-shadow: none;
              }
            }
          }
        }

        .inputs-row {
          $inputCellMargin: 7px;
          display: flex;
          flex-direction: row;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          .default-select-list {
            margin-right: 0;
          }

          .members-gender {
            width: 178px;
            border: 1px solid $brdr_clr3;
            background-color: $bg_clr0;
            height: 48px;
            align-items: center;
            margin-right: $inputCellMargin;

            .ant-radio-group {
              display: flex;
              flex-direction: row;
              width: 100%;

              > :first-child {
                border-right: 1px solid $brdr_clr3;
              }

              &.error {
                outline: 1px solid $txt_clr14;

                > :first-child {
                  border-color: $brdr_clr11;
                }
              }
            }

            .radio-box {
              @include centerItems();
              padding: 0 7px;
              width: 100%;
              height: 48px;

              .custom-radio {
                margin-right: 0;

                span.ant-radio + * {
                  padding: 0 0 0 6px;
                }
              }
            }
          }

          .members-name {
            //width: 365px;
            width: 100%;

            &.m-r {
              margin-right: $inputCellMargin;
            }

            .custom-input {
              flex-grow: 1;
              //width: 162px;
            }

            > :first-child {
              margin-right: $inputCellMargin;
            }
          }

          .inputs-wrapper {
            display: flex;
            flex-direction: row;
          }

          .members-birth {
            width: 184px;
            align-items: flex-end;

            .custom-input {
              width: 60px;
              margin-right: 2px;

              input {
                padding: 4px 8px;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }

          .form-select-wrapper {
            max-width: 113px;
          }

          .form-select {
            margin-right: 0;
            box-shadow: none;
            border: 1px solid $brdr_clr3;
            height: 48px;
            max-width: 113px;
            overflow: hidden;
            background-color: $bg_clr0;

            .ant-select-selection__rendered {
              @include preventOverSize();
              width: 113px;
              margin-right: 0;
              margin-left: 12px;
              padding-right: 35px;
            }

            .ant-select-selector {
              min-height: 46px;
              width: 113px;
              box-shadow: none;
            }
          }

          &.hide-title {
            .custom-input,
            .form-select-wrapper,
            .members-birth {
              .custom-input-title {
                display: none;
              }
            }
          }
        }

        .members-count.error-state {
          border: 1px solid $brdr_clr11;
        }

        .card-info {
          .pad {
            padding-top: 25px;
          }

          .payment-description {
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            .custom-icon {
              margin: 10px 10px 0 0;
            }

            span {
              color: $txt_clr5;
              font-size: 0.9375rem;
              line-height: 1.333em;
            }
          }

          .radio-box {
            width: 50%;
            padding-right: 15px;
          }
        }

        .coupon-form {
          .input-container {
            padding-right: 0;
          }

          &.border-form {
            border-radius: 0 0 6px 6px;
          }

          .inp-ex-md {
            width: 315px;

            &.md-font {
              position: relative;

              .custom-input-icon {
                position: absolute;
                top: 50%;
                margin-top: -12px;
                right: 12px;
                width: 24px;
                min-width: 24px;
                height: 24px;
                min-height: 24px;
              }
            }
          }

          .coupon-btn {
            width: 230px;
            height: 48px;
            margin-left: 12px;
          }

          @media only screen and (max-width: $tabletScreen) {
            .coupon-btn {
              width: 216px;
              flex-shrink: 0;
              margin-left: 8px;
            }
          }

          @media only screen and (max-width: $mobileScreen) {
            .input-container {
              flex-direction: column;
              align-items: flex-start;
            }

            .inp-ex-md {
              margin-bottom: 24px;
            }

            .coupon-btn {
              width: 100%;
              margin-left: 0;
            }
          }
        }

        .warning-msg {
          @include centerItems();
          align-items: flex-start;
          justify-content: flex-start;
          padding: 10px 0;
          font-family: Roboto-Light, sans-serif;
          font-size: 1rem;
          color: $txt_clr14;

          .warn-icon {
            margin-right: 10px;
            margin-bottom: 2px;
          }

          &.bordered {
            margin-top: 16px;
            padding: 22px 55px;
            border: 2px solid #d70909;
            background-color: #fcebeb;
            white-space: pre-wrap;
            word-break: break-word;

            strong {
              font-family: Roboto-bold;
            }
          }
        }
      }
    }
  }

  .server-transaction-msg {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 37px;
    @extend .extra-sm-txt;
    font-size: 0.75rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0;

    .custom-icon {
      margin-left: 7px;
      flex-shrink: 0;
    }
  }
}

.payment-result-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $bg_clr2;
  flex-grow: 1;

  .result-txt {
    &.msg-error {
      color: $txt_clr14;
    }

    &.msg-success {
      color: $txt_clr13;
    }

    text-align: center;
    width: $pcBlock;
  }
}

.hotel-options {
  p {
    &:first-child {
      @include font(Roboto-Regular, 1rem, 400, normal, normal, 1.5rem, normal);
    }

    &:last-child {
      @include font(Roboto-Light, 0.87rem, 300, normal, normal, 1.2rem, normal);
      color: $txt_clr22;
    }
  }

  ul {
    margin-bottom: 16px;

    li {
      @include font(Roboto-Regular, 0.81rem, 400, normal, normal, 1rem, normal);
      position: relative;
      padding-left: 26px;
      padding-right: 24px;
      word-break: break-word;
      color: $txt_clr4;
      margin-bottom: 6px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background-image: url('../../../images/icons/check_new.svg');
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        @include fixed-size(18px);
      }
    }
  }
}

@media only screen and (max-width: $tabletScreen) {
  .payment-page {
    .navigate-wrapper {
      .ant-tabs-nav-list {
        width: auto;
        padding: 0 20px;

        .ant-tabs-tab {
          .ant-tabs-tab-btn {
            width: auto;
          }
        }
      }
    }

    .payment-block-wrapper {
      padding: 32px 20px 16px 20px;

      .payment-block {
        .left {
          min-width: 314px;
        }

        .right {
          width: calc(100% - 314px);

          .border-form {
            border: none;
            padding-left: 0;
            padding-right: 0;

            &:first-child {
              padding-top: 0;
            }
          }

          .members-count {
            .inputs-row {
              display: grid;
              grid-template-areas:
                'gender name'
                'birth birth';
              grid-row-gap: 16px;
              grid-column-gap: 32px;
              grid-template-columns: 130px 1fr;

              > div:first-child {
                grid-area: gender;
              }

              .members-name {
                width: 100%;
                margin-right: 0;
                grid-area: name;
              }

              .members-gender {
                border: none;
                width: auto;

                .ant-radio-group {
                  .radio-box {
                    width: auto;

                    &:first-child {
                      border: none;
                      padding-left: 0;
                    }
                  }
                }
              }

              .members-birth {
                width: 100%;
                grid-area: birth;

                .custom-input {
                  width: 50%;

                  &:first-child {
                    width: 124px;
                  }

                  &:not(:last-child) {
                    margin-right: 8px;
                  }

                  .custom-input-title {
                    display: block;
                  }
                }
              }
            }
          }

          .conclusion-form {
            box-shadow: 0px 4px 6px rgba(5, 11, 51, 0.107982);
            padding: 24px;
          }

          .grid-form {
            grid-template-columns: 100%;

            .inp-lg,
            .inp-md,
            .inp-sm,
            .inp-ex-md,
            .payment-calendar {
              width: 100%;
            }

            //.inp-ex-sm {
            //  width: 50%;
            //}

            .extra-sm-txt {
              padding: 0;
            }

            .input-container {
              padding-left: 0;

              .hint-icon {
                left: initial;
                right: 0;
                top: -31px;
              }

              .input-popover-wrapper {
                width: 100%;
                position: relative;
                margin-bottom: 22px;

                .mobile-view {
                  display: inline-block;
                }
              }

              > :last-child {
                margin-bottom: 0;
              }

              .ant-radio-group {
                margin-right: 32px;

                .radio-box {
                  border: none;
                  padding-left: 0;
                }
              }

              &.birthday {
                .inp-sm {
                  margin-bottom: 0;
                  width: 32%;
                }

                .mobile-view {
                  display: inline-block;
                }
              }
            }

            .input-title {
              padding-right: 0;
            }
          }
        }
      }
    }

    .server-transaction-msg {
      padding: 0 30px;
    }
  }
}

@media only screen and (max-width: $mobileBlock) {
  .payment-page {
    .payment-block-wrapper {
      .payment-block {
        flex-direction: column;

        .left {
          order: 2;
          width: 100%;

          h1 {
            line-height: 1.35rem;
          }

          .address-wrapper {
            line-height: 100%;
          }

          .pic-wrapper {
            height: auto;
          }

          .border-form {
            border: none;
            padding: 0;
            border-radius: 0;
            position: static;
            margin-bottom: 32px;
          }

          //.payment-types {
          //  display: flex;
          //  justify-content: space-between;
          //  align-items: center;
          //  margin-bottom: 32px;
          //}

          .conclusion-form {
            margin-bottom: 0;

            .footer-controls {
              .custom-btn {
                margin-bottom: 0;
              }
            }
          }
        }

        .right {
          order: 1;
          width: 100%;
          padding-left: 0;

          .coupon-form.border-form {
            border-radius: 0;
          }

          .border-form {
            border-bottom: 1px solid #cfcfdd;
          }

          .grid-form {
            grid-row-gap: 11px;

            .input-title {
              padding-top: 11px;
            }

            .input-container {
              .hint-icon {
                top: -20px;
              }
            }

            .flex-col {
              flex-direction: column;
              align-items: flex-start;

              .radio-box {
                width: 100%;
              }
            }
          }

          .members-count {
            .inputs-row {
              grid-template-areas:
                'gender gender'
                'name name'
                'birth birth';
              margin-bottom: 30px;

              &:last-child {
                margin-bottom: 0;
              }

              .custom-input-title {
                display: none;
              }

              .members-gender {
                border: none;
                // margin-bottom: 15px;
                height: auto;

                .ant-radio-group {
                  > :first-child {
                    border-right: none;
                  }

                  .radio-box {
                    width: auto;
                    height: 100%;
                    // padding: 0;
                  }
                }
              }

              .members-name {
                flex-direction: column;
                width: 100%;

                .custom-input {
                  margin: 0;

                  &:first-child {
                    margin-bottom: 12px;
                  }
                }
              }

              .members-birth {
                .custom-input {
                  width: auto;
                  flex-grow: 1;
                }
              }
            }
          }
        }
      }
    }

    .server-transaction-msg {
      justify-content: center;
    }

    .selected-apartments {
      .hotel-name-rating {
        font-size: 1rem;
        line-height: 1.35rem;
      }

      .hotel-name {
        line-height: 0;

        .address-wrapper {
          font-size: 0.7rem;
          line-height: 0.75rem;
        }
      }
    }
  }
}

// @media only screen and (max-width: $mobileScreen) {
//   .payment-result-page {
//     .result-txt {
//       width: 100%;
//     }
//   }

//   .payment-page {
//     .header-block {
//       width: 100%;
//       padding: 16px;
//       padding-bottom: 8px;
//       border-bottom: 1px solid $brdr_clr0;

//       .hotel-name-rating {
//         font-family: Roboto-Medium, sans-serif;
//         font-size: 1.125rem;
//         line-height: 1.25rem;
//         align-items: baseline;
//         margin-bottom: 6px;
//       }

//       .address-wrapper {
//         @include font(Roboto-Regular, 0.813rem, normal, normal, normal, 1rem, normal);
//       }
//     }

//     .wrapper-card-room {
//       width: 100%;
//       margin-top: 0;
//     }

//     .hotel-room {
//       width: 100%;
//       background-color: transparent;
//       border: none;
//       box-shadow: none;
//       margin-top: 16px;

//       .left {
//         display: initial;
//         width: 100%;
//         padding-left: 16px;
//         padding-right: 16px;
//         padding-bottom: 16px;
//         background-color: transparent;

//         .room-img-wrapper {
//           min-width: initial;
//           max-width: initial;
//           min-height: 176px;
//           max-height: 176px;
//         }
//       }

//       .float-wrapper {
//         .opened-description {
//           padding-left: 16px;
//           padding-right: 16px;
//         }

//         .present-row {
//           display: none;

//           &.show-present {
//             display: initial;
//           }
//         }

//         .expander,
//         .opened-description {
//           display: none;
//         }

//         .room-accordion-options {
//           display: none;

//           &.open {
//             display: initial;

//             .room-notice {
//               margin-top: 13px;
//               padding-bottom: 16px;
//               border-bottom: initial;

//               p {
//                 color: $txt_clr22;
//                 @include font(Roboto-Light, 0.875rem, 300, normal, normal, 1.25rem, normal);
//                 margin-bottom: 0;
//               }
//             }
//           }
//         }

//         .mobile-toggle-btn {
//           display: flex;
//           align-items: center;
//           margin-top: 12px;
//           margin-bottom: 16px;

//           span {
//             @include font(Roboto-Regular, 0.813rem, normal, normal, normal, 1rem, normal);
//             text-decoration-line: underline;
//             margin-right: 5px;
//             color: $txt_clr22;
//           }

//           .look-down {
//             &.look-up {
//               transform: rotate(180deg);
//             }
//           }
//         }

//         .options-wrapper {
//           display: flex;
//           flex-direction: column;

//           .room-info {
//             order: 3;
//             margin-bottom: 0;
//             margin-top: 0;

//             .actions-popup-wrapper {
//               padding: 0;
//               margin-top: 5px;
//             }
//           }
//         }

//         .banner-profit.room-note {
//           margin-left: 0;
//           display: initial !important;
//           margin-bottom: 12px;
//           order: 0;
//           align-self: flex-start;
//         }

//         .opened-description {
//           .room-notice,
//           .expander.active {
//             padding-left: 0;
//             padding-right: 0;
//             padding-bottom: 0;
//           }
//         }

//         .options-wrapper {
//           padding-top: 0;

//           .expander {
//             padding-left: 0;
//             padding-right: 0;
//           }

//           .room-accordion-options {
//             padding-left: 0;
//             padding-right: 0;
//             padding-bottom: 0;
//           }

//           .banner-profit {
//             display: none;
//           }
//         }

//         .right {
//           width: 100%;
//           min-width: initial;
//           max-width: initial;
//           background-color: transparent;
//           margin-top: 0;
//           padding: 16px;
//           min-height: initial;

//           .order-options-info {
//             padding-left: 0;
//             padding-right: 0;
//             margin-top: 0;
//           }

//           > .banner-profit {
//             order: 0;
//           }
//         }
//       }

//       .slider-wrapper.mobile-gallery.hotels {
//         padding-left: 0px;
//       }

//       .guests-count,
//       .final-sum-wrapper,
//       .float-wrapper .right .tax-bill {
//         padding: 0 0px;
//       }

//       .footer-btn-wrapper.mobile-view {
//         display: none !important;
//       }

//       .mobile-view {
//         .slider-wrapper.mobile-gallery.hotels {
//           .room-pic {
//             width: 216px !important;
//             height: 162px;
//             margin-right: 8px;
//           }
//         }
//       }

//       .left {
//         .room-img-wrapper {
//           .actions-popup-wrapper {
//             flex-direction: column;

//             :first-child {
//               margin-bottom: 12px;
//             }
//           }
//         }
//       }

//       .float-wrapper .right {
//         .payment-types {
//           padding: 0;
//           margin-bottom: 12px;
//         }

//         .popup-action {
//           font-size: 0.813;
//           line-height: 1rem;
//           margin-left: initial;
//           margin-top: 8px;
//           margin-bottom: 4px;
//           text-decoration-line: underline;
//         }

//         .tax-bill {
//           margin-bottom: 25px;
//         }
//       }
//     }

//     .iban-wrapper {
//       padding-right: 25px;
//     }

//     .banner-profit {
//       margin-top: 13px;
//       margin-bottom: 5px;
//     }

//     .selected-apartments-wrapper {
//       background-color: #eff8fc;
//       padding-bottom: 0;
//     }

//     .payment-block-wrapper {
//       padding: 0 16px;

//       .payment-block {
//         width: 100%;

//         .left {
//           .conclusion-form .booking-item-options {
//             .insurance-row {
//               align-items: initial;

//               .check-box-wrapper {
//                 position: relative;
//                 top: -5px;
//               }
//             }

//             .check-box-wrapper.sm.hide_txt.padding {
//               top: 6px;
//             }

//             .title-row {
//               max-width: 150px;
//             }

//             .ant-collapse {
//               .ant-collapse-item .ant-collapse-header {
//                 .option-info-header {
//                   align-items: initial;
//                 }

//                 .anticon.ant-collapse-arrow {
//                   top: 20px;
//                 }
//               }

//               .check-box-wrapper.sm.hide_txt.padding &:first-child {
//                 .ant-collapse-item .ant-collapse-header {
//                   p {
//                     align-items: initial;
//                   }

//                   .anticon.ant-collapse-arrow {
//                     top: initial;
//                   }
//                 }
//               }
//             }
//           }

//           .border-form {
//             padding: 20px 0;
//             border: none;
//             border-bottom: 1px solid $brdr_clr3;

//             .form-header {
//               font-size: 1.125rem;
//               line-height: 1.55em;

//               &.sm {
//                 margin-top: 17px;
//                 font-size: 1rem;
//                 line-height: 1.75em;
//               }
//             }

//             .mobile-view {
//               display: block;
//             }
//           }

//           .grid-form {
//             grid-template-columns: 100%;

//             .inp-lg,
//             .inp-md,
//             .inp-sm,
//             .inp-ex-md,
//             .payment-calendar {
//               width: 100%;
//             }

//             .inp-ex-sm {
//               width: 50%;
//             }

//             .input-container {
//               padding-right: 25px;
//               padding-left: 0;
//               flex-wrap: wrap;

//               .hint-icon {
//                 left: initial;
//                 right: 0;
//               }

//               .ant-radio-group {
//                 width: 100%;
//               }

//               > .custom-input {
//                 margin-bottom: 22px;
//               }

//               .input-popover-wrapper {
//                 width: 100%;
//                 position: relative;
//                 margin-bottom: 22px;

//                 .mobile-view {
//                   display: inline-block;
//                 }

//                 .hint-icon {
//                   right: -25px;
//                 }
//               }

//               > :last-child {
//                 margin-bottom: 0;
//               }

//               .radio-box {
//                 margin-bottom: 22px;
//               }

//               &.birthday {
//                 .inp-sm {
//                   margin-bottom: 0;
//                   width: 32%;
//                 }

//                 .mobile-view {
//                   display: inline-block;
//                 }
//               }
//             }

//             .input-title,
//             .title-wrapper {
//               display: none;
//             }
//           }

//           .inputs-row {
//             padding-right: 13px;
//             flex-wrap: wrap;

//             .ant-calendar-picker {
//               width: 100%;
//             }

//             .custom-input-title {
//               font-family: Roboto-Regular, sans-serif;
//               font-size: 0.875rem;
//               line-height: 1.142em;
//               margin-bottom: 8px;
//             }

//             > :first-child {
//               width: 100%;
//             }

//             .members-name {
//               width: 100%;
//               margin: 0;
//               flex-wrap: wrap;

//               .custom-input-title {
//                 display: none;
//               }

//               .custom-input {
//                 width: 100%;
//                 margin: 0 0 22px 0;
//               }
//             }

//             .members-gender {
//               width: 100%;
//               margin-bottom: 22px;

//               .radio-box {
//                 padding: 0 20px;
//                 justify-content: flex-start;
//                 width: 50%;
//               }
//             }

//             .members-birth {
//               display: flex;
//               flex-direction: row;
//               justify-content: space-between;
//               width: 100%;
//               margin: 0;

//               .custom-input-title {
//                 display: none;
//               }

//               .mobile-view {
//                 display: block;
//               }

//               .custom-input {
//                 width: 32%;
//               }
//             }

//             .form-select-wrapper {
//               width: 100%;
//               max-width: initial;

//               .custom-input-title {
//                 display: none;
//               }

//               .form-select {
//                 max-width: 100%;
//               }
//             }
//           }

//           .members-count {
//             padding-bottom: 27px;
//           }

//           .card-info {
//             .grid-form .input-container .radio-box {
//               margin-bottom: 0;
//               overflow: hidden;
//               width: 50%;
//             }

//             .custom-radio.ant-radio-wrapper {
//               align-items: center;

//               &.sm-md span.ant-radio + * {
//                 display: flex;
//                 flex-direction: column;
//               }
//             }

//             .custom-input-title {
//               font-family: Roboto-Regular, sans-serif;
//               font-size: 0.875rem;
//               line-height: 1.142em;
//               margin-bottom: 8px;
//               color: $txt_clr6;
//             }
//           }

//           .coupon-form {
//             border-radius: 0 !important;

//             .coupon-btn {
//               width: 100%;
//               margin-left: auto;
//             }

//             .input-title,
//             .input-container {
//               padding: 0;
//             }
//           }

//           .conclusion-form {
//             color: $txt_clr6;
//             padding: 12px;

//             .room-preview {
//               flex-direction: column-reverse;

//               .description {
//                 padding: 0;
//                 margin-bottom: 16px;

//                 .header-block {
//                   padding: 0;
//                 }

//                 .header-block .header-txt.sm,
//                 .catering {
//                   display: none;
//                 }

//                 //.header-txt sm
//               }

//               h1 {
//                 margin: 12px 0 !important;
//               }

//               + .header-txt {
//                 margin: 0 0 12px 0 !important;
//               }

//               .pic-wrapper {
//                 width: 100%;
//                 min-height: 176px;
//                 height: 176px;
//               }
//             }

//             .content-txt {
//               font-size: 0.8125rem;
//               line-height: 17px;

//               ul {
//                 li {
//                   font-size: 0.8125rem;
//                   line-height: 17px;
//                 }
//               }
//             }

//             .collapse-header-form {
//               .banner-profit {
//                 display: none;
//               }
//             }

//             .banner-profit {
//               margin: 5px 0;

//               &.mobile-view {
//                 display: block;
//                 color: #222222;
//                 font-size: 1.25rem;
//                 border-radius: 3px;
//                 padding: 4px 12px;
//                 font-weight: 500;
//               }
//             }

//             .final-sum {
//               margin-top: 10px;
//             }

//             .guests-count {
//               margin-bottom: 10px;
//             }

//             .check-data-msg {
//               padding-top: 7px;
//               margin-bottom: 20px;
//             }

//             .check-data-msg {
//               padding-top: 7px;
//             }

//             .extra-sm-txt {
//               span {
//                 position: static;
//               }
//             }

//             .tax-bill {
//               text-align: right;
//               margin: 9px 0;
//             }

//             h3 {
//               font-size: 1.25rem;
//               line-height: 1.4em;
//             }

//             .header-txt {
//               font-size: 1rem;
//             }

//             .ant-collapse-header {
//               color: $txt_clr6;
//             }

//             .footer-controls {
//               .custom-btn {
//                 width: 100%;
//                 height: initial;
//                 min-height: 49px;
//                 padding: 0 15px;
//               }

//               .btn-back {
//                 display: none;
//               }
//             }
//           }

//           .server-transaction-msg {
//             margin-bottom: 38px;
//             justify-content: center;
//           }
//         }
//       }
//     }
//   }
// }

.changed-index-msg {
  border-left: 8px solid $txt_clr13;
  padding: 8px 0px 8px 24px;
  @include font(Roboto-Regular, 1rem, 400, normal, normal, 1.5rem, normal);
  display: flex;
  flex-direction: column;

  &.err {
    border-left-color: $brdr_clr13;
  }

  .price-block {
    font-weight: 700;
  }
}

.room-fees {
  &__header {
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__description {
    font-size: 14px;
    font-weight: 400;

    > p {
      &:not(:last-of-type) {
        margin-bottom: 6px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
