@import '../../../variables.scss';

.final-sum {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  border-top: 1px dashed $brdr_clr21;

  .price {
    flex-direction: row-reverse;
    align-items: baseline;
    &.price-direction {
      .old-price,
      .current-price .with-discount-price-color,
      .current-price .with-discount-price-color span {
        font-size: 0.813rem !important;
        line-height: 1rem !important;
        font-weight: 400;
      }
      .old-price {
        color: rgb(170, 170, 186);
        > span::after {
          border-top-color: rgb(170, 170, 186);
          transform: initial;
        }
        .discount-price-color {
          color: rgb(170, 170, 186) !important;
          margin-right: 4px;
        }
      }
      &.total-price {
        .current-price span {
          font-size: 1.125rem !important;
          line-height: 1.5rem !important;
          font-weight: 700;
        }
        .discount-price-color {
          font-size: 1.125rem !important;
          line-height: 1.5rem !important;
        }
      }
    }
  }
  .current-price {
    padding-left: 4px;
  }

  .current-price span {
    font-family: Roboto-Regular, sans-serif;
    font-size: 0.813rem !important;
    line-height: 1rem !important;
    font-weight: 400;
  }
}
