@import '../../../variables.scss';

.footer-block {
  @include centerItems();
  font-family: 'Roboto-Light';
  font-size: 1rem;
  color: $txt_clr5;
  flex-direction: row;
  align-items: flex-start;
  padding: 48px 0;

  .footer-menu {
    border-radius: $inputRadius;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
    display: none;
    padding: 20px 12px;

    ul {
      .active {
        font-family: Roboto-Regular, sans-serif;
      }

      li {
        cursor: pointer;
        padding: 8px 32px 8px 0;
        border-bottom: 1px solid $brdr_clr3;

        @media only screen and (min-width: $tabletScreen+1) {
          &:hover {
            color: $txt_clr8;
            background-color: $bg_clr13;
          }
        }
      }
    }
  }

  .left-block {
    width: 384px;
    margin-right: 24px;

    .footer-menu {
      display: block;
      margin-bottom: 124px;
    }
  }

  .right-block {
    width: 791px;

    * {
      line-height: initial;
    }

    ul {
      li {
        list-style-type: initial;
      }
    }

    p {
      font-family: 'Roboto-Light';
      font-size: 14pt;
      line-height: 22pt;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    b {
      font-family: 'Roboto-Regular';
      font-weight: 300;
    }

    h1 {
      font-family: 'Roboto-Medium';
      font-size: 26pt;
      font-weight: 500;
    }

    h2,
    h3 {
      font-family: 'Roboto-Medium';
      font-weight: 500;
      margin-top: 30px;
      margin-bottom: 15px;
    }

    h4,
    h5,
    h6 {
      font-family: 'Roboto-Regular';
      font-weight: 300;
      margin-top: 30px;
      margin-bottom: 15px;
    }

    h2 {
      font-size: 22pt;
    }

    h3 {
      font-size: 20pt;
    }

    h4 {
      font-size: 18pt;
    }

    h5 {
      font-size: 16pt;
    }

    h6 {
      font-size: 14pt;
    }

    ul {
      font-family: 'Roboto-Light';
      font-size: 14pt;
      line-height: 22pt;
      list-style-position: outside;
      padding-left: 22px;
      margin-top: 15px;
      margin-bottom: 15px;

      * {
        line-height: 22pt;
      }
    }

    li {
      margin-bottom: 10px;
    }

    ol {
      font-family: 'Roboto-Light';
      font-size: 14pt;
      line-height: 22pt;
      list-style-position: outside;
      padding-left: 29px;
      margin-top: 15px;
      margin-bottom: 15px;

      * {
        line-height: 22pt;
      }
    }

    a {
      color: $txt_clr9;
      text-decoration: none;
    }

    a:hover {
      color: #000;
    }

    li a {
      border-bottom: 1px dotted $brdr_clr8;
    }
  }
}

@media only screen and (max-width: $tabletScreen) {
  .footer-block {
    .left-block {
      width: 309px;
    }

    .right-block {
      width: 642px;
    }
  }
}

@media only screen and (max-width: $mobileScreen) {
  .footer-block {
    flex-direction: column;
    padding: 40px 16px;

    .footer-menu {
      display: block;
      width: 100%;
      box-shadow: none;
      padding-bottom: 44px;
      padding-top: 0;
      order: 2;
    }

    .left-block {
      order: 1;
      width: 100%;
      margin-right: 0;

      .employee-card {
        margin-bottom: 44px;
      }

      .footer-menu {
        display: none;
      }
    }

    .right-block {
      width: 100%;
      overflow: hidden;
      order: 3;

      .main-block-header {
        font-size: 1.125rem;
        line-height: 1.333em;
      }

      h2 {
        font-size: 1.8rem;
        line-height: 1.2em;
      }

      h3 {
        font-size: 1.6rem;
        line-height: 1.2em;
      }

      h4 {
        font-size: 1.4rem;
        line-height: 1.2em;
      }

      h5 {
        font-size: 1.2rem;
        line-height: 1.2em;
      }

      h6 {
        font-size: 1rem;
        line-height: 1.2em;
      }

      h3,
      h4,
      h5,
      h6 {
        padding-top: 20px;
      }
    }
  }
}
