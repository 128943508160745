@import '../../../variables';

.hotels-cards-block {
  @include centerItems();
  flex-direction: column;
  padding: 20px 0 0 0;

  .empty-hotel-results {
    padding: 20px;
  }

  .hotel-card.bordered {
    border-bottom: 1px solid $brdr_clr4;
  }

  .hotel-card {
    @include centerItems();
    flex-direction: column;
    width: $pcBlock;
    min-height: 30px;

    .btn-wrapper {
      .apply-btn {
        background-color: $bg_clr7;
        color: $txt_clr0;
        transition: background-color 300ms;
        -webkit-transition: background-color 300ms;

        &:hover {
          background-color: $bg_clr6;
          transition: background-color 300ms;
          -webkit-transition: background-color 300ms;
        }
      }
    }

    &.recommended {
      .cards-gallery .gallery-block.with-banner {
        height: 470px;
        @media only screen and (max-width: $mobileScreen) {
          height: 450px;
        }
      }
    }

    h2 {
      margin: 40px 0 40px 0;

      .header-description {
        font-family: Roboto-Regular, sans-serif;
        font-size: 1rem;
        line-height: 1.125em;
      }
    }

    .cards-gallery {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      padding-bottom: 40px;
      @media only screen and (max-width: $mobileScreen) {
        padding-bottom: 0;
      }

      &.centered-block {
        justify-content: center;
      }

      &.horizontal-card {
        flex-direction: column;

        .gallery-block > .price-horizontal-block .price-wrapper .price.row-type .current-price {
          color: $txt_clr9;
        }

        .gallery-block {
          margin-bottom: 16px;
          width: 100%;
          flex-direction: row;
          border: 1px solid $brdr_clr18;

          .txt-block {
            padding: 24px;

            .mark-block {
              display: flex;
              margin-bottom: 16px;
              margin-top: initial;
              flex-direction: row-reverse;
              justify-content: flex-end;

              .mark-good.sm {
                margin-left: 12px;
              }
            }

            .hotel-location {
              margin-bottom: 12px;
            }

            .mark-and-price {
              margin-top: initial;

              .description-txt {
                display: flex;
                flex-direction: column-reverse;

                p {
                  margin-bottom: 0;

                  .mark-block {
                    display: none;
                  }
                }
              }

              .price-block {
                display: none;
              }
            }

            .short-description {
              position: relative;

              .description-txt {
                display: block;

                .banner-profit {
                  left: 0;
                  top: 40px;
                }
              }
            }
          }

          &.with-banner {
            .price-horizontal-block .price-wrapper {
              padding-top: 15px;
            }
          }

          > .price-horizontal-block {
            display: block;
            padding: 24px 24px 24px 0;

            .price-wrapper {
              height: 100%;
              border-left: 1px solid $brdr_clr18;
              padding-left: 24px;

              .price {
                display: flex;
                justify-content: center;
                flex-direction: column-reverse;
                align-items: baseline;
                margin-top: 24px;

                &.row-type {
                  flex-direction: row;
                  margin: 21px 0 6px 0;
                  white-space: pre-wrap;
                  justify-content: initial;

                  .current-price-txt {
                    font-family: Roboto-Bold, sans-serif;
                    margin-right: initial;
                    font-size: 1rem;
                  }
                }

                .with-discount-price-color {
                  font-size: 1.5rem !important;
                  line-height: 1.75rem !important;
                }

                .old-price {
                  margin-bottom: 6px;
                }

                .old-price,
                .current-price-txt {
                  margin-left: 0;
                  margin-right: auto;
                }
              }
            }
          }
        }
      }

      .price-horizontal-block {
        display: none;
      }

      .txt-block {
        padding: 12px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        > .mark-block {
          display: none;
        }

        h3 {
          @include font(Roboto-Medium, 1.123rem, 500, normal, normal, 1.25rem, normal);
          margin-bottom: 0;
          color: $txt_clr9;

          .hotel-name-rating {
            margin-bottom: 8px;
          }

          a {
            color: $txt_clr4;
            display: flex;
            flex-direction: column;
          }

          .stars-block {
            height: 16px;
          }
        }

        .popularity {
          display: flex;
          flex-direction: row;
          height: 12px;
          margin: 4px 0;
        }

        .hotel-location {
          word-break: break-all;
          @include font(Roboto-Regular, 0.875rem, 400, normal, normal, 1.0625rem, normal);
          margin-bottom: 5px;
        }

        .short-description {
          @include font(Roboto-Light, 0.876rem, normal, normal, normal, 1em, normal);

          h4 {
            @include font(Roboto-Medium, 1rem, 500, normal, normal, 1.5rem, normal);
            margin-bottom: 12px;
          }

          .description-txt {
            display: none;
          }
        }

        .mark-block {
          margin-top: auto;

          .rating-txt {
            @include font(Roboto-Medium, 0.875rem, 500, normal, normal, 1rem, normal);
            color: $txt_clr13;
            margin-right: 6px;
            overflow: hidden;
            max-width: 84px;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .good-mark-icon {
            margin-left: 6px;
          }

          .mark-good.sm {
            min-width: 28px;
            min-height: 28px;
            width: 28px;
            height: 28px;
            margin-right: 0;
            @include font(Roboto-Medium, 0.875rem, 500, normal, normal, 1rem, normal);
          }

          @media only screen and (max-width: $mobileScreen) {
            .mark-good.sm {
              min-width: 22px;
              min-height: 22px;
              width: 22px;
              height: 22px;
              font-size: 0.625rem;
              line-height: 0.875rem;
            }
            svg {
              min-width: 22px;
              min-height: 22px;
              width: 22px;
              height: 22px;
            }
          }
        }

        .mark-and-price {
          display: flex;
          flex-direction: column;
          margin-top: auto;

          > div {
            width: 100%;
          }

          > :first-child {
            display: flex;
            flex-direction: column;
          }

          .description-txt {
            p {
              display: flex;
              align-items: center;
              margin-bottom: 12px;

              .mark-block {
                margin-left: auto;
              }
            }

            .banner-profit {
              position: static;
              display: inline-block;
              @include font(Roboto-Medium, 0.875rem, 500, normal, normal, 1rem, normal);
              color: $txt_clr4;
              white-space: nowrap;
              padding: 3px 6px;
              border-radius: 3px;
              max-height: 22px;
              max-width: 215px;
              overflow: hidden;
              padding-right: 4px;
              text-overflow: ellipsis;
            }
          }

          .price-block {
            flex-grow: 1;
            display: flex;
            text-align: right;
            border-top: 1px solid $brdr_clr18;
            padding-top: 12px;

            .period {
              font-size: 0.8125rem;
              line-height: 1.0625rem;
            }

            .price {
              display: flex;
              justify-content: center;
              flex-direction: column-reverse;
              align-items: flex-end;
              margin-left: auto;

              &.row-type {
                flex-direction: row;
                margin-top: auto;
                white-space: pre-wrap;

                .current-price-txt {
                  font-family: Roboto-Bold, sans-serif;
                  font-size: 1rem;
                  line-height: 1.5rem;
                  padding-right: 4px;
                }
              }

              .with-discount-price-color {
                font-size: 1.5rem !important;
                line-height: 1.75rem !important;
                font-weight: bold;
              }

              .old-price {
                margin-bottom: 6px;
                font-weight: 600;
              }

              .old-price,
              .current-price-txt {
                margin-right: 0;
                margin-left: auto;
              }
            }

            .price.row-type .current-price span.card-price {
              @include font(Roboto-Medium, 1.5rem, bold, normal, normal, 1.75rem, normal);
              color: $txt_clr9;
            }
          }
        }
      }

      .info-card-trip {
        .info-item {
          display: flex;
          align-items: center;
          @include font(Roboto-Regular, 0.813rem, 400, normal, normal, 1rem, normal);

          &:not(:last-child) {
            margin-bottom: 8px;
          }

          .item-icon,
          .count-nights {
            margin-right: 4px;
          }

          .period {
            display: flex;
            align-items: center;
          }
        }
      }

      .gallery-block {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 384px;
        height: 470px;
        border-radius: $inputRadius;
        overflow: hidden;
        border: 1px solid $brdr_clr18;
        transition: box-shadow 200ms;
        -webkit-transition: box-shadow 200ms;

        &.resize-card {
          width: 100%;
          height: 166px;

          .txt-block .mark-block .mark-good.sm {
            margin-left: 0;
          }

          .mark-block .good-mark-icon {
            margin-left: 0;
            margin-right: 6px;
          }

          .hotel-img-block {
            max-width: 300px;
          }

          .banner-attention-container {
            position: absolute;
            top: -27px;
            left: -2px;
            border-radius: 8px 8px 0px 0px;
            width: auto;
            @media only screen and (max-width: $tabletScreen) {
              .banner-attention {
                flex-direction: row;
              }
            }

            .banner-attention {
              padding: 4px 22px;
            }
          }

          .rating-txt {
            padding-left: 6px;
          }
        }

        &.with-banner {
          border: 2px solid $brdr_clr20;
          height: 528px;

          .mark-block {
            @media only screen and (max-width: $tabletScreen) {
              .rating-txt {
                display: none;
              }
            }
          }

          &.resize-card {
            width: 100%;
            height: 222px;
            margin-bottom: 50px;
            border-radius: 0px 8px 8px 8px;
            overflow: initial;

            .hotel-img-block {
              max-height: 218px;
              border-bottom-left-radius: 6px;
            }

            .img-container,
            .txt-block,
            .price-horizontal-block {
              overflow: hidden;
            }
          }
        }

        &:hover .hotel-img-block img {
          transform: scale(1.1);
          transition: 1s;
          -webkit-transition: 1s;
        }

        @media only screen and (max-width: $tabletScreen) {
          width: 312px;
          // height: 445px;
        }
        @media only screen and (max-width: $mobileScreen) {
          width: 283px;
          height: 450px;
          &:not(.with-banner) {
            .mark-and-price .price-block .info-card-trip .info-item {
              align-items: initial;

              .period {
                flex-direction: column;

                span:last-child {
                  margin-left: -10px;
                }
              }
            }
          }
        }

        @media only screen and (min-width: $tabletScreen) {
          &:hover {
            box-shadow: 0px 15px 25px -15px rgba(5, 11, 51, 0.16);
            transition: box-shadow 200ms;
            -webkit-transition: box-shadow 200ms;
          }
        }

        .banner-attention-container {
          position: static;
        }

        .hotel-img-block {
          display: block;
          height: 235px;
          position: relative;
          overflow: hidden;

          @media only screen and (max-width: $mobileScreen) {
            height: 185px;
          }

          .custom-icon-finger-up {
            margin-right: 8px;
          }

          img,
          .empty-img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.1);
            transition: 1s;
            -webkit-transition: 1s;
          }

          .empty-img {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .apply-btn {
    height: 49px;
    // margin: 40px 0 40px 0;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: $tabletScreen) {
  .hotels-cards-block {
    padding-bottom: 0;

    .hotel-card {
      width: $tabletBlock;
    }
  }
}

@media only screen and (max-width: $mobileScreen) {
  .hotels-cards-block {
    .btn-wrapper {
      padding: 0 16px;
    }

    .apply-btn {
      width: 100%;
      padding: 0 15px;
      min-width: initial;
      //margin-top: 30px;
      min-height: 49px;
      height: initial;
    }

    .apply-btn.custom-empty-btn-mdx {
      margin-bottom: 24;
    }

    .hotel-card {
      width: 100%;

      &.bot-pad {
        .slick-slide {
          padding-bottom: 20px;
          min-width: 300px;
          max-width: 300px;
        }
      }

      .section-header {
        padding: 0 16px;
        width: 100%;
        margin-top: 44px;
        margin-bottom: 10px;

        .header-description {
          font-size: 1rem;
          line-height: 1.125em;
          margin-top: 10px;
        }
      }

      .cards-gallery {
        flex-wrap: wrap;
        justify-content: space-around;

        &.mobile-view {
          display: block;
          width: 100%;
        }

        .gallery-block {
          margin: 10px 8px;
        }
      }
    }
  }
}

.hotels-cards-block .hotel-card {
  .slider-gallery {
    display: initial;
    width: 100%;

    .slick-track {
      display: flex;
      flex-direction: row;
    }

    .slick-slide {
      text-align: initial;

      .gallery-block {
        margin: 0 8px;
      }
    }
  }
}
