@import '../../../variables.scss';

.room-modal {
  min-width: 1100px;

  @media only screen and (max-width: $mobileScreen) {
    min-width: initial;
    max-width: 100%;
    height: 100%;
    min-height: 100%;
    padding: 0;
    margin: 0;
    .ant-modal-content {
      height: 100%;

      .ant-modal-close {
        top: 30px;
        right: 16px;
      }
    }
  }
  @media only screen and (max-width: $tabletScreen) {
    min-width: initial;
  }

  .ant-modal-close-x:hover {
    svg path {
      fill: #1c33c8;
      transition: fill 350ms;
      -webkit-transition: fill 350ms;
    }
  }

  .ant-modal-body {
    padding-bottom: 40px;
    @media only screen and (max-width: $mobileScreen) {
      padding: 24px 16px;
    }
  }

  .modal-header {
    @include font(Roboto-Medium, 0.875rem, 500, normal, normal, 1em, normal);
    color: $txt_clr16;
    color: #7d7e8e;
    margin-bottom: 16px;
    @media only screen and (max-width: $mobileScreen) {
      margin-bottom: 6px;
    }
  }

  .room-options {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    @media only screen and (max-width: $tabletScreen) {
      flex-wrap: initial;
    }

    .wrapper-txt {
      width: 100%;
      max-width: 588px;
      margin-right: 32px;
      @media only screen and (max-width: $tabletScreen) {
        max-width: 484px;
      }
      @media only screen and (max-width: $mobileScreen) {
        max-width: initial;
        margin-right: 0;
      }

      .room-services-block {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        width: 100%;

        .room-amenities-title {
          @include font(Roboto-Medium, 1.25rem, 500, normal, normal, 1.75em, normal);
          padding-bottom: 20px;
          @media only screen and (max-width: $mobileScreen) {
            padding-bottom: 14px;
            box-shadow: 0px 10px 10px -10px rgba(123, 145, 157, 0.15);
          }
        }

        .wrapper-service-room {
          display: flex;
          flex-wrap: wrap;
          @media only screen and (max-width: $mobileScreen) {
            padding-top: 16px;
          }

          .amenities-item {
            span {
              padding-top: 6px;
            }

            svg {
              margin-right: 8px;
              width: 24px;
              min-width: 24px;

              > path {
                fill: $bg_clr14;
              }
            }
          }
        }
      }
    }

    .amenities-item {
      display: flex;
      min-width: 33%;
      max-width: 33%;

      padding-right: 15px;

      &:not(:nth-child(-n + 3)) {
        margin-top: 10px;
      }

      @media only screen and (max-width: $tabletScreen) {
        &:not(:nth-child(-n + 2)) {
          margin-top: 10px;
        }
      }

      @media only screen and (max-width: $mobileScreen) {
        &:nth-child(2n + 2) {
          padding-right: 0;
        }
      }

      &:nth-child(3n + 3) {
        padding-right: 0;
        @media only screen and (max-width: $mobileScreen) {
          padding-right: 15px;
        }
      }

      @media only screen and (max-width: $tabletScreen) {
        min-width: 50%;
        max-width: 50%;
        font-size: 0.625rem;
        line-height: 0.875rem;
      }

      span {
        @include font(Roboto-Regular, 0.813rem, normal, normal, normal, 1rem, normal);
      }
    }

    .slider-block {
      width: 432px;
      height: 420px;
      position: relative;
      top: 31px;
      @media only screen and (max-width: $mobileScreen) {
        display: none;
      }

      .slide-counter {
        bottom: 103px;
      }

      .preview-image-block {
        display: flex;
        align-items: center;
        margin-top: 12px;

        .preview-slider-item {
          width: 136px;
          min-width: 136px;
          height: 83px;
          &:nth-child(n + 2) {
            opacity: 0.4;
          }

          &:not(:last-child) {
            margin-right: 12px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .ant-carousel {
        width: 100%;
        height: 100%;

        .slider-item {
          min-width: initial;
          width: 432px;
          height: 324px;
        }
      }
    }

    .wrapper-amenities {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      width: 100%;
      margin-top: 0;
      padding-right: 0;

      .block-txt-amenities {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        max-width: 588px;
        @media only screen and (max-width: $mobileScreen) {
          margin-right: 0;
          max-width: initial;
        }

        .service-amenities {
          border-top: 1px solid $brdr_clr12;
          padding-top: 12px;
          padding-bottom: 12px;
          width: 100%;

          &:last-of-type {
            padding-bottom: 0;
          }

          .wrapper-amenities {
            margin-top: 6px;
          }
        }

        .title-block-amenities {
          @include font(Roboto-Medium, 0.813rem, 500, normal, normal, 1.063em, normal);
        }

        .wrapper-amenities {
          display: flex;
          flex-wrap: wrap;
          width: 100%;

          .amenities-item {
            position: relative;

            &:not(:nth-child(-n + 3)) {
              margin-top: 4px;
            }

            @media only screen and (max-width: $tabletScreen) {
              &:not(:nth-child(-n + 2)) {
                margin-top: 4px;
              }
            }

            &:not(:nth-child(3n)) {
              padding-right: 25px;
              @media only screen and (max-width: $mobileScreen) {
                padding-right: 0;
              }
            }

            span {
              padding-top: 1px;
            }

            i {
              width: 16px;
              min-width: 16px;
              height: 16px;
              min-height: 16px;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}
