//color variables
$white0: #ffffff;
$white1: #edf4f8;
$white2: #d2e2eb;
$white3: #f8f8f8;
$white4: #e6e6eb;

$grey0: #686868;
$grey1: #eaf0ff;
$grey2: #d9d9d9;
$grey3: #dfdfdf;
$grey4: #999999;
$grey5: #7d7e8e;
$grey6: #848484;
$grey7: #9a9a9a;
$grey8: #484848;
$grey9: #cacccd;
$grey10: #cfcfdd;
$grey11: #aaaaba;
$grey12: #868686;
$grey13: #656d77;
$grey14: #c4c4c4;

$black0: #333333;
$black1: rgba(0, 0, 0, 0.87);
$black2: #000000;

$blue0: #00266e;
$blue1: #062153;
$blue2: #1c33c8;
$blue3: #1b2e77;
$blue4: rgba(0, 33, 128, 0.31);

$yellow0: #ffc733;
$yellow1: #ffb100;
$yellow2: #febc01;
$yellow3: #ffc733a6;

$green0: #8ab91d;

$red0: #f55935;
$red1: #e93208;
$red2: #ff7f50;
$red3: #ff0000;

//text color variables
$txt_clr0: $white0;
$txt_clr16: $white3;

$txt_clr1: $grey0;
$txt_clr2: $grey3;
$txt_clr3: $grey4;
$txt_clr16: $grey5;
$txt_clr17: $grey8;
$txt_clr18: $grey9;
$txt_clr19: $grey11;
$txt_clr20: $grey12;
$txt_clr21: $grey10;
$txt_clr22: $grey13;

$txt_clr4: $black0;
$txt_clr5: $black1;
$txt_clr6: $black2;

$txt_clr7: $blue0;
$txt_clr8: $blue1;
$txt_clr9: $blue2;
$txt_clr10: $blue3;

$txt_clr11: $yellow0;
$txt_clr12: $yellow1;

$txt_clr13: $green0;

$txt_clr14: $red0;
$txt_clr15: $red1;
$txt_clr23: $red3;

//background-color variables
$bg_clr0: $white0;
$bg_clr1: $white1;
$bg_clr2: $white2;
$bg_clr13: $white3;

$bg_clr3: $grey0;
$bg_clr14: $grey5;
$bg_clr17: $grey10;
$bg_clr19: $grey13;

$bg_clr4: $black0;
$bg_clr5: $black2;

$bg_clr6: $blue1;
$bg_clr7: $blue2;
$bg_clr16: $blue3;

$bg_clr8: $yellow0;
$bg_clr9: $yellow1;
$bg_clr10: $yellow2;
$bg_clr15: $yellow3;

$bg_clr11: $green0;

$bg_clr12: $red0;
$bg_clr18: $red2;

//border color variables

$brdr_clr0: $white0;
$brdr_clr12: $white4;
$brdr_clr16: $white1;

$brdr_clr1: $grey0;
$brdr_clr2: $grey1;
$brdr_clr3: $grey2;
$brdr_clr4: $grey3;
$brdr_clr14: $grey6;
$brdr_clr15: $grey7;
$brdr_clr18: $grey10;
$brdr_clr19: $grey11;
$brdr_clr21: $grey14;
$brdr_clr22: $grey13;

$brdr_clr5: $black0;
$brdr_clr6: $black2;

$brdr_clr7: $blue1;
$brdr_clr8: $blue2;

$brdr_clr9: $yellow1;

$brdr_clr10: $green0;

$brdr_clr11: $red0;
$brdr_clr13: $red1;
$brdr_clr20: $red2;

$brdr_clr17: $yellow0;

//box-shadow color variables
$bx_shdw0: $blue2;
$bx_shdw1: $blue4;

$bx_shdw2: $yellow0;

//outline color variables

//colors
//bg color

//border-colors
//font-colors

//z-index
$headerMenuZ: 3;
$topFormZ: 2;
$topFormBgZ: 1;
$blockImgBg: 1;
$blockAboveImgInfo: 2;

//sizes
$tabletScreen: 1235px;
$mobileScreen: 1000px;
$mobileBlock: 767px;
$tabletBlock: 975px;
$pcBlock: 1200px;
$input: 56px;
$inputRadius: 6px;
$smallInputRadius: 4px;

//resolutions
$fullHd: 1920px;
//functions
@mixin font($family, $size, $weight, $style, $stretch, $lineHeight, $spacing) {
  font-family: $family, sans-serif;
  font-size: $size;
  font-weight: $weight;
  font-style: $style;
  font-stretch: $stretch;
  line-height: $lineHeight;
}

@mixin centerItems() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin preventOverSize() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin fixed-size($width, $height: null) {
  min-width: $width;
  max-width: $width;

  @if ($height == null) {
    min-height: $width;
    max-height: $width;
  } @else {
    min-height: $height;
    max-height: $height;
  }
}

%guests-count-block {
  @include font(Roboto-Regular, 0.8125rem, normal, normal, normal, 1.0625rem, normal);
  @include preventOverSize();
  color: $txt_clr6;
  width: 100%;
  display: flex;
  flex-direction: row;

  > :first-child {
    text-align: left;
  }

  > :last-child {
    text-align: right;
  }

  > div {
    flex-grow: 1;
  }
}
