@import '../../../variables';

.slider-wrapper,
.filter-block {
  .slider-controllers {
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    z-index: 1;
    padding: 0 16px;

    .slider-controller {
      cursor: pointer;
    }

    .next {
      transform: rotate(-180deg);
    }

    &.outer {
      .next,
      .prev {
        position: absolute;
        top: 0;
      }

      .next {
        right: -24px;
      }

      .prev {
        left: -24px;
      }
    }
  }
}

.slider-wrapper {
  position: relative;

  .slider-controllers {
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    z-index: 1;
    padding: 0 16px;

    .slider-controller {
      cursor: pointer;
    }

    .next {
      transform: rotate(-180deg);
    }
  }

  .ant-carousel .slick-slide {
    text-align: center;
    //background: #364d79;
    overflow: hidden;

    > div {
      height: 100%;
    }
  }

  .slider-item {
    position: relative;

    .main-img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}

.slider-wrapper.sm {
  .slider-item {
    height: 271px;
    width: 350px;

    .banner-profit {
      top: 16px;
      left: 16px;

      .custom-icon-finger-up {
        margin-right: 10px;
      }
    }
  }

  .slider-controllers {
    margin-top: -12px;

    .slider-controller {
      width: 24px;
      height: 24px;
    }
  }

  .ant-carousel .slick-slide {
    height: 271px;
  }
}

.slider-wrapper.md {
  width: 100vw;

  .ant-carousel {
    .slick-slide {
      height: 396px;
      width: 670px;
    }
  }

  .slider-item {
    height: 100%;

    .banner-profit {
      width: 122px;
      height: 38px;
      top: 24px;
      left: 24px;

      .custom-icon-finger-up {
        margin-right: 10px;
      }
    }
  }

  .slider-controllers {
    margin-top: -18px;

    .slider-controller {
      min-width: 36px;
      min-height: 36px;
    }
  }
}

@media only screen and (max-width: $tabletScreen) {
  .slider-wrapper.sm {
    .slider-item {
      height: 202px;
    }

    .ant-carousel .slick-slide {
      height: 202px;
    }
  }
  //.slider-wrapper.md {
  //  .slider-item {
  //    height: 420px;
  //    width: 737px;
  //  }
  //
  //  .ant-carousel .slick-slide {
  //    height: 420px;
  //  }
  //}
}

@media only screen and (max-width: $mobileScreen) {
  .slider-wrapper.mobile-gallery {
    width: 100%;

    .slider-controllers {
      margin-top: -12px;
      padding: 0 34px;
    }

    &.hotels {
      padding-left: 8px;

      .slider-controllers {
        display: none;
      }

      .ant-carousel .slick-slide {
        color: $txt_clr4;
        text-align: initial;
        //width: 269px !important;
        background: transparent;
      }
    }

    &.room-view {
      //padding: 0 16px;

      .slider-item {
        height: 250px;
      }

      .ant-carousel .slick-slide {
        color: $txt_clr4;
        height: 250px;
      }
    }
  }
}
