.table-filter-modal {
  width: 100%;
  padding: 0;

  .ant-modal-close {
    top: 28px;
  }

  .ant-modal-body {
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;

    .modal-header {
      min-height: 72px;
      margin-bottom: 0;
      padding: 0 16px;
      display: flex;
      align-items: center;
      box-shadow: 0px 10px 10px -10px rgba(123, 145, 157, 0.15);
    }

    .modal-body {
      overflow-y: scroll;
      flex-grow: 1;
      padding: 0 16px;

      .custom-collapse .ant-collapse-item > .ant-collapse-header {
        margin-top: 16px;
      }

      .space-between {
        .check-box-wrapper {
          margin-top: 16px;

          .ant-checkbox-wrapper-checked {
            font-family: Roboto-Bold, sans-serif;
          }
        }
      }

      ul {
        margin-left: 0;
        padding-bottom: 0;

        li {
          padding-bottom: 0;

          .ant-checkbox-wrapper-checked,
          .positions-cont {
            font-family: Roboto-Bold, sans-serif;
            color: $txt_clr4;
          }
        }

        :last-child {
          margin-bottom: 0;
        }
      }
    }

    .buttons-container {
      height: 140px;
      display: flex;
      flex-direction: column-reverse;
      padding: 16px;
      box-shadow: 0px -10px 10px -10px rgba(123, 145, 157, 0.15);

      button {
        width: 100%;
        height: 48px;
        font-size: 0.9375rem;
        white-space: pre-line;
        padding: 0 20px;
        overflow: hidden;
      }

      > :last-child {
        margin-bottom: 12px;
      }
    }
  }
}
