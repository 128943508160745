@import '../../../variables';

@keyframes slideLeft {
  0% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
.main-menu-modal {
  animation: slideLeft 0.4s ease-in-out;
  .ant-modal-body {
    padding: initial;
    overflow: auto;
  }
  .modal-header {
    color: $txt_clr4;
  }
  .ant-modal-close-x {
    svg path {
      fill: $brdr_clr22;
    }
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    min-height: 600px;
    .modal-header {
      display: flex;
      align-items: center;
      box-shadow: 0px 10px 10px -10px rgba(123, 145, 157, 0.15);
      padding: 18px 16px;
      margin-bottom: 12px;
      animation: slideLeft 0.4s ease-in-out;
      &.no-animation {
        animation: initial;
      }

      span {
        @include font(Roboto-Medium, 1.125rem, 500, normal, normal, 111%, normal);
      }
      svg {
        margin-right: 18px;
      }
    }

    .mobile-nav {
      @include font(Roboto-Regular, 1.125rem, 400, normal, normal, 111%, normal);
      color: $txt_clr6;
      padding: 14px 0;
      border-top: 1px solid transparent;
      border-bottom: 1px solid $brdr_clr4;
      margin: 0 16px;
      cursor: pointer;

      &.with-arrow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg path {
          fill: $brdr_clr19;
        }
      }
      .regular {
        font-family: Roboto-Regular, sans-serif;
      }

      &.no-pad {
        padding-bottom: 0;
      }
    }
    .menu-list {
      display: flex;
      flex-direction: column;
      &.left-animation {
        animation: slideRight 0.4s ease-in-out;
      }
    }

    .links-list {
      display: flex;
      flex-direction: column;
      padding: 0 16px;
      animation: slideLeft 0.4s ease-in-out;
      &.left-animation {
        animation: slideRight 0.4s ease-in-out;
      }

      .mobile-nav {
        @include font(Roboto-Regular, 1rem, 400, normal, normal, 1.5rem, normal);
        margin: 0;
        border: initial;
        padding: 12px 0;
        .name-count-hotel {
          display: flex;
          flex-direction: column;
          min-width: 190px;
          .count-hotels {
            @include font(Roboto-Regular, 0.8125rem, 400, normal, normal, 1rem, normal);
            color: $txt_clr22;
          }
        }
        .arrow-wrapper {
          min-width: 50px;
          min-height: 35px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
    .regions-list {
      animation: slideLeft 0.4s ease-in-out;
      &.left-animation {
        animation: slideRight 0.4s ease-in-out;
      }
      li.region-item {
        list-style-type: none;
        padding-bottom: 0;
      }
      .mobile-nav {
        display: flex;
        flex-direction: column;
        border: initial;
        @include font(Roboto-Regular, 0.875rem, 400, normal, normal, 1.125rem, normal);
        padding: 10px 0;
        margin: 0;
        span:nth-child(1) {
          padding-bottom: 4px;
        }
        span:nth-child(2) {
          color: $txt_clr22;
        }
        .count-hotels {
          @include font(Roboto-Regular, 0.8125rem, 400, normal, normal, 1rem, normal);
          color: $txt_clr22;
        }
      }
    }
  }
}
