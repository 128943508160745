@import '../../../variables.scss';

.ant-modal.details-insurance {
  width: 100% !important;
  max-width: 602px;

  .ant-modal-content {
    box-shadow: 0px 10px 10px -10px rgba(123, 145, 157, 0.15);
    border-radius: 4px;
    border: 1px solid $brdr_clr18;
    background: $bg_clr0;

    .modal-content {
      display: flex;
      flex-direction: column;
      .header-modal {
        color: #7d7e8e;
        @include font(Roboto-Medium, 0.875rem, 500, normal, normal, 1rem, normal);
        padding-bottom: 16px;
      }
      .title-modal {
        @include font(Roboto-Medium, 1.25rem, 500, normal, normal, 1.75rem, normal);
        padding-bottom: 24px;
      }
      .modal-txt {
        display: flex;
        flex-direction: column;
        white-space: pre-wrap;
        @include font(Roboto-Regular, 1rem, 400, normal, normal, 1.5rem, normal);
      }
    }
  }
}
