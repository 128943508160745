@import '../../../variables.scss';

.transfer-modal {
  max-width: 680px;

  &.custom-modal {
    width: auto !important;
  }

  .modal-header {
    max-width: 460px;
    white-space: initial;

    &.pt {
      padding-top: 30px;
    }

    div:first-child {
      font-size: 0.85rem;
      color: $grey13;
      margin-bottom: 6px;
    }

    div:last-child {
      font-size: 1.125rem;
      line-height: 1.25rem;
    }
  }

  .modal-body {
    max-width: 529px;

    ol {
      padding-left: 18px;

      li {
        list-style-type: decimal;
      }
    }
  }
}
