@import '../../../variables.scss';

.suggestions-header-wrapper {
  width: 100%;
  box-shadow: 0px 10px 10px -10px rgba(123, 145, 157, 0.15);
  background-color: $bg_clr0;
  border-top: 1px solid $brdr_clr12;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1px;
  @media only screen and (max-width: $mobileScreen) {
    padding: 0 16px;
  }

  .suggestions-header {
    width: $pcBlock;
    @media only screen and (max-width: $mobileScreen) {
      padding-top: 16px;
    }

    @include centerItems();
    min-height: 64px;
    justify-content: space-between;

    .mobile-view {
      display: none;
    }

    .items-count {
      @include font(Roboto-Medium, 1rem, normal, normal, normal, 1.5rem, normal);

      .remove-btn {
        font-family: Roboto-Regular, sans-serif;
        margin-left: 12px;
        cursor: pointer;
      }
    }

    .items-sort {
      @include centerItems();
      flex-direction: row;

      .sort-title {
        @include font(Roboto-Medium, 1rem, normal, normal, normal, 1.5rem, normal);
        margin-right: 16px;
      }

      .sort-list {
        background: transparent;
        margin-right: 0;

        .custom-select {
          .ant-select-selection-item {
            font-family: Roboto-Light, sans-serif;
            color: $txt_clr4;
          }

          .ant-select-selector {
            background: transparent;
            box-shadow: none;
            width: 222px;
            min-height: 38px;
          }

          &:after {
            right: 0;
          }
        }
      }
    }
  }
}

.grid-suggestions {
  width: $pcBlock;
  display: grid;
  margin-top: 24px;
  grid-template-columns: 226px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'options results';
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  font-family: Roboto-Light, sans-serif;

  .suggestions-options {
    min-height: 562px;
    position: relative;
    grid-area: options;

    .options-mask {
      width: 100%;
      height: 100%;
      cursor: not-allowed;
      position: absolute;
      z-index: 2;
    }

    h4 {
      font-family: Roboto-Medium, sans-serif;
      font-size: 1.125rem;
    }

    .apply-filters {
      margin-top: 30px;
      width: 100%;
      height: 38px;
      font-size: 0.937rem;
    }
  }

  .suggestions-results {
    position: relative;
    grid-area: results;
    display: flex;
    align-items: center;
    flex-direction: column;

    .preloader-bg {
      position: absolute;
      background: #d9d9d991;
      cursor: not-allowed;
      border-radius: $inputRadius;
      margin: 0;
      height: 100%;
      width: 100%;
    }

    .results-card {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: 314px;
      margin-bottom: 20px;
      border-radius: $inputRadius;
      background: $bg_clr0;

      &.discount-info {
        margin-top: 20px;
        border: 2px solid $brdr_clr20;
        border-radius: 0 $inputRadius $inputRadius $inputRadius;
      }

      .direction-wrapper {
        display: flex;
        flex-direction: row;
        border-radius: 6px;
        //overflow: hidden;
        position: relative;

        .banner-attention-container {
          top: -25px;
          width: initial;
          box-sizing: content-box;
          left: -2px;
          border-radius: $inputRadius $inputRadius 0 0;

          .banner-attention {
            padding: 4px 11px;
          }
        }

        .left {
          display: flex;
          flex-direction: column;
          min-width: 595px;
          padding: 24px 24px 16px 24px;
          border-right: 1px dashed $brdr_clr19;

          .header-info {
            position: relative;
            margin-bottom: 16px;

            > h3 {
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-direction: row;
              width: 100%;

              .banner-profit {
                position: static;
                @include font(Roboto-Medium, 0.875rem, 500, normal, normal, 1rem, normal);
                color: $txt_clr4;
                padding: 6px 12px;
              }

              .rating-block {
                display: flex;
                flex-direction: row;

                .mark-block {
                  .block-txt {
                    @include font(Roboto-Medium, 0.875rem, 500, normal, normal, 1rem, normal);
                  }

                  .mark-good {
                    width: 28px;
                    height: 28px;
                    @include font(Roboto-Medium, 0.875rem, 500, normal, normal, 1rem, normal);
                  }
                }
              }
            }
          }

          .hotel-link {
            margin-bottom: 8px;
            @include font(Roboto-Medium, 1.125rem, 500, normal, normal, 1.25rem, normal);
            color: $txt_clr4;

            .link-stars-block {
              display: flex;
              flex-direction: row;

              .stars-block {
                margin-left: 8px;
                white-space: nowrap;
              }
            }
          }

          .location-wrapper {
            margin-bottom: 16px;
            @include font(Roboto-Medium, 0.875rem, 400, normal, normal, 1.125rem, normal);
            color: $txt_clr22;
          }

          .circle-container {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 16px;

            > div {
              margin-right: 14px;
              max-width: 24px;
              max-height: 24px;
              margin-bottom: 6px;

              > svg {
                max-width: 24px;
                max-height: 24px;

                > path {
                  fill: $bg_clr19;
                }
              }
            }

            > :last-child {
              margin: 0;
            }
          }

          .room-name {
            margin-bottom: 16px;
            @include font(Roboto-Medium, 1rem, 500, normal, normal, 1.5rem, normal);
            color: $txt_clr4;
          }

          .img-container {
            flex-grow: 1;

            .slick-slide {
              //height: 239px;
              opacity: 0.5;
              transition: opacity 0.5s;
              -webkit-transition: opacity 0.5s;

              &.slick-active {
                opacity: 1;
                transition: opacity 0.5s;
                -webkit-transition: opacity 0.5s;
              }
            }

            .slider-wrapper .slick-track {
              > div {
                padding-right: 4px;
              }
            }

            .empty-slide {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-grow: 1;
              height: 100%;
              min-height: 232px;
              max-height: 400px;
              background: #d9d9d991;
            }
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          padding: 24px;
          min-width: 355px;
          position: relative;

          svg {
            min-width: 16px;
            max-width: 16px;
            min-height: 16px;
            max-height: 16px;
          }

          .date-nights {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;

            .date {
              @include font(Roboto-Regular, 0.875rem, 400, normal, normal, 1.125rem, normal);
              color: $txt_clr4;
              display: flex;
              align-items: center;

              > span {
                margin-left: 5px;
              }
            }

            .nights {
              @include font(Roboto-Medium, 0.875rem, 500, normal, normal, 1.125rem, normal);
              color: $txt_clr4;
            }

            &.mt {
              margin-top: 12px;
            }
          }

          .supply {
            margin-bottom: 12px;
            @include font(Roboto-Regular, 0.875rem, 400, normal, normal, 1.125rem, normal);
            display: flex;
            align-items: center;
            color: $txt_clr4;

            > span {
              margin-left: 5px;
            }
          }

          .treatments {
            margin-bottom: 12px;
            @include font(Roboto-Regular, 0.875rem, 400, normal, normal, 1.125rem, normal);
            color: $txt_clr4;
            display: flex;
            align-items: center;
            white-space: pre-wrap;

            > :nth-child(2) {
              margin-left: 5px;
            }
          }

          .price-block {
            margin-bottom: 9px;

            .price-without-transfer {
              display: flex;
              justify-content: space-between;
              align-items: baseline;

              > :first-child {
                @include font(Roboto-Medium, 0.875rem, 400, normal, normal, 1.125rem, normal);
                color: $txt_clr4;
                display: flex;
                // @media only screen and (max-width: $tabletScreen) {
                //   max-width: 140px;
                // }
                > span {
                  margin-left: 5px;
                }
              }

              .final-sum {
                border: 0;
                width: initial;

                .price.price-direction {
                  > div {
                    font-size: 0.8125rem;
                    line-height: 1rem;
                    font-weight: 400;
                    white-space: nowrap;

                    .current-price span,
                    .with-discount-price-color {
                      font-family: Roboto-Medium, sans-serif;
                      font-weight: 500;
                    }
                  }

                  .old-price {
                    white-space: nowrap;
                  }
                }
              }
            }

            > span {
              @include font(Roboto-Medium, 0.875rem, 400, normal, normal, 1.125rem, normal);
              color: $txt_clr4;
              margin: 0 0 9px 21px;
              display: block;
            }

            .guests-count-block {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-left: 21px;

              .guest-count {
                @include font(Roboto-Light, 0.8125rem, 400, normal, normal, 1rem, normal);
                color: $txt_clr22;
              }

              .final-sum {
                border: 0;
              }
            }
          }

          .transfer-block {
            justify-content: space-between;
            display: flex;
            @include font(Roboto-Medium, 0.875rem, 400, normal, normal, 1.125rem, normal);
            color: $txt_clr4;
            padding: 12px 0;
            border-top: 1px dashed $bg_clr19;

            > :first-child {
              display: flex;

              > span {
                margin-left: 5px;
              }
            }
          }

          .final-price {
            display: flex;
            flex-direction: row;
            align-items: center;
            @include font(Roboto-Medium, 0.875rem, 400, normal, normal, 1.125rem, normal);
            color: $txt_clr4;
            padding: 12px 0;
            border-top: 1px dashed $brdr_clr22;

            .final-sum {
              border: 0;
            }
          }

          .footer-btns {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 0;
            margin-top: auto;

            .custom-btn-md {
              height: 48px;
              width: initial;
              margin-top: 12px;
            }

            > a .custom-empty-btn {
              width: 100%;
              height: 48px;
            }

            .minimal-days {
              margin-top: 12px;
              @include font(Roboto-Regular, 0.875rem, 400, normal, normal, 1.125rem, normal);
              color: $txt_clr4;
              text-align: center;
            }
          }
        }
      }

      .img-container {
        position: relative;
        min-width: 380px;
        max-width: 546px;

        .banner-profit,
        .banner-attention-container {
          z-index: 2;
          display: flex;
        }

        .banner-attention-container + .banner-profit {
          top: initial;
          bottom: 24px;
        }

        &:hover {
          .slide-counter {
            opacity: 1;
            transition: opacity 0.3s ease-out;
            -webkit-transition: opacity 0.3s ease-out;
          }
        }

        .slider-wrapper {
          &:hover {
            .slider-controllers {
              opacity: 1;
              transition: opacity 0.3s ease-out;
              -webkit-transition: opacity 0.3s ease-out;
            }
          }

          .slider-controllers {
            transition: opacity 0.3s ease-out;
            opacity: 0;
          }
        }

        .slide-counter {
          opacity: 0;
          transition: opacity 0.3s ease-out;
          -webkit-transition: opacity 0.3s ease-out;
        }
      }
    }
  }
}

@media only screen and (max-width: $tabletScreen) {
  .grid-suggestions .suggestions-results .results-card {
    width: initial;
    min-height: 400px;

    .direction-wrapper {
      .banner-attention-container .banner-attention {
        display: flex;
        flex-direction: row;
      }

      .left {
        min-width: initial;
        max-width: 434px;
        padding: 16px;
        min-height: 400px;

        .slick-slide {
          // width: 304px !important;
          width: 100% !important;
          max-width: 401px;
          opacity: 1;
        }

        .slick-list {
          padding: 0 !important;
        }

        .img-container {
          width: initial;
          max-width: initial;

          .slider-wrapper .slick-track > div {
            padding-right: 0;
          }
        }
      }

      .right {
        padding: 16px;
        min-width: 292px;
        max-width: 292px;
        min-height: 400px;
      }
    }
  }

  .suggestions-header-wrapper {
    .suggestions-header {
      width: $tabletBlock;
    }
  }
  .grid-suggestions {
    width: $tabletBlock;

    .suggestions-results {
      .results-card {
        .direction-wrapper {
          .img-container {
            .slider-wrapper {
              .slider-controllers {
                opacity: 1;
              }
            }
          }
        }
      }

      .pagination-block {
        width: 100%;
        min-width: initial;
        padding: 0 12px;
        margin-bottom: 40px;

        .figure-block {
          padding: 0 12px;
          width: 100%;
          display: flex;
          flex-direction: row;
        }
      }
    }
  }
}

@media only screen and (max-width: $mobileScreen) {
  .grid-suggestions .suggestions-results .results-card {
    width: 100%;
    min-height: initial;
    max-height: initial;

    .block-txt {
      display: none;
    }

    .pad.rooms-gallery-wrapper,
    .slider-wrapper.mobile-gallery.rooms,
    .ant-carousel {
      max-height: 91px;
    }

    .ant-carousel {
      min-height: initial;
      max-height: 91px;
    }

    .slider-controllers {
      display: none;
    }

    &.discount-info {
      border-radius: 0 0 6px 6px;
    }

    .direction-wrapper {
      flex-direction: column;

      .banner-attention-container {
        width: 100%;
        border-left: 2px solid $brdr_clr20;
        border-right: 2px solid $brdr_clr20;

        .banner-attention {
          display: flex;
          flex-direction: row;
        }
      }

      .left {
        min-width: 100%;
        padding: 12px 12px 0 12px;
        border-right: 0;
        min-height: initial;
        max-height: initial;

        .room-name {
          margin: 12px 0;
        }

        .slick-slide {
          min-height: 91px;
          max-height: 91px;
          min-width: 162px;
          margin-right: 4px;
        }
      }

      .right {
        width: 100%;
        min-width: initial;
        max-width: initial;
        min-height: initial;
        max-height: initial;
        padding: 0 12px 12px 12px;

        .footer-btns {
          position: initial;
          padding: 0;

          .minimal-days {
            margin-top: 14px;
          }
        }
      }
    }
  }

  .suggestions-header-wrapper {
    .suggestions-header {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      .items-sort {
        display: none;
      }

      .mobile-view {
        display: flex;
        font-family: Roboto-Regular, sans-serif;
        justify-content: space-between;
        width: 100%;
        margin: 16px 0;

        > div {
          display: flex;
          align-items: center;

          > svg {
            margin-left: 11px;
          }
        }

        & :last-child {
          svg {
            width: 7px;
            height: 12px;
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  .grid-suggestions {
    width: 100%;
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    grid-template-areas: 'results';

    .suggestions-results {
      .results-card {
        //padding: 10px 0 10px 0;

        .img-container {
          display: none;
        }

        .direction-wrapper {
          position: relative;

          .footer-btns {
            padding: 0;
          }
        }

        .footer-btns {
          padding-left: 12px;
          padding-right: 12px;
          font-size: 1.125rem;
          line-height: 1em;

          button {
            overflow: hidden;
          }

          &.tablet {
            display: flex;
            flex-direction: column;

            > :first-child {
              margin-bottom: 20px;
            }

            > :last-child {
              margin-bottom: 0;
            }

            a {
              width: 100% !important;

              .show-all {
                width: 100%;
              }
            }

            .enter-data,
            a {
              width: 100%;
            }
          }
        }
      }
    }

    .suggestions-options {
      display: none;
    }
  }
}

@media only screen and (max-width: 321px) {
  .grid-suggestions .suggestions-results .results-card .direction-wrapper .banner-attention-container .banner-attention {
    flex-direction: column;
  }
  .grid-suggestions .suggestions-results .results-card .direction-wrapper {
    padding-top: 20px;
  }
}
