@import '../../../variables.scss';

.hotel-rooms {
  .masked-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    height: 2px;
  }

  @include centerItems();
  flex-direction: column;
  background-color: $bg_clr1;

  .empty-results {
    margin-top: 80px;
  }

  .order-options-info {
    padding-top: 24px;
  }

  .catering {
    margin-bottom: 0px;
  }

  .hotels-search-block {
    h3.form-header {
      @include font(Roboto-Medium, 1.25rem, normal, normal, normal, 1.437em, normal);
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 10px;

      > :first-child {
        margin-right: 10px;
      }

      > :last-child {
        font-family: Roboto-Regular, sans-serif;
        line-height: 1.75rem;
        color: $txt_clr1;
      }
    }

    .main-inputs-form {
      &.short-form .form {
        justify-content: space-between;

        .rangepicker-wrapper {
          margin-right: 0;
        }

        .period-inputs {
          margin-right: 20px;
          width: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;

          .guests-form-wrapper {
            width: 100px;
          }

          .default-select-list.md {
            order: 4;
            width: 260px;
            margin-right: 0;
          }
        }
      }

      .transfer-options {
        margin: 0;
      }
    }

    .preloader-bg {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      min-height: initial;
      margin: 0;
      cursor: not-allowed;
      border-radius: $inputRadius;
      background: #d9d9d991;
      z-index: 4;
    }

    .search-results {
      .empty-rooms {
        padding: 20px;
        text-align: center;
      }
      .count-rooms {
        display: flex;
        align-items: center;
        justify-content: center;
        @include font(Roboto-Medium, 1.5rem, 500, normal, normal, 1.75rem, normal);
        margin-top: 24px;
        white-space: pre-wrap;
        @media only screen and(max-width: $mobileScreen) {
          font-size: 1rem;
          line-height: 1.25rem;
        }
      }
    }
  }

  .room-gallery-wrapper {
    @include centerItems();
    flex-direction: column;
    width: 100%;
    background: $bg_clr0;
    padding-top: 24px;
    overflow: hidden;

    .fixedNav {
      .ant-affix {
        width: 100% !important;
        left: 0;
        top: 0 !important;
        display: flex;
        justify-content: center;
        box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.13);

        .tabs-slider-block {
          width: $pcBlock;
        }
      }
    }

    .room-gallery {
      width: $pcBlock;
    }

    .header-block {
      display: flex;
      flex-direction: row;
      width: 1200px;

      .description-block {
        position: relative;
        flex-grow: 1;
        flex-basis: 45%;
        padding-right: 24px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > div {
          .mark-block {
            display: none;
          }
        }

        h1 {
          font-size: 1.5rem;
          max-width: 720px;
          margin-bottom: 0;

          > span {
            position: relative;

            .stars-block {
              display: inline-block;
              margin-left: 5px;

              .custom-icon-star {
                position: relative;
                top: -12px;
              }
            }
          }
        }

        .description-address {
          @include font(Roboto-Regular, 0.75rem, normal, normal, normal, 1.5em, normal);
          color: $txt_clr16;
          flex-wrap: wrap;
          margin-bottom: 24px;

          .address-wrapper {
            margin-right: 50px;
          }

          .map-pointer {
            margin-right: 6px;
          }

          .custom-icon {
            margin: 0 8px;
          }

          > :last-child {
            font-family: Roboto-Medium, sans-serif;
            color: $txt_clr9;
          }
        }
      }

      .btns-block {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .mark-block {
          .mark-good {
            margin-right: 8px;
          }
        }
      }
    }

    .room-preview {
      min-height: 396px;
      height: 396px;
      max-height: 396px;
      margin-bottom: 10px;
      position: relative;

      .slider-wrapper.md .ant-carousel .slick-slide {
        width: 696px !important;
      }

      .filter-block {
        height: 100%;
        width: 100vw;
        position: absolute;
        z-index: 1;
        display: flex;
        flex-direction: row;

        > div {
          flex-grow: 1;
        }

        > :nth-child(1),
        > :nth-child(3) {
          background-color: rgba(255, 255, 255, 0.5);
        }

        > :nth-child(2) {
          position: relative;
          width: 696px;
          max-width: 696px;

          .banner-profit,
          .banner-attention-container {
            z-index: 3;
            border-radius: 3px;
            font-size: 1rem;
          }
        }

        .images-count {
          > i {
            margin-bottom: 12px;
          }

          position: absolute;
          bottom: 20px;
          right: 22px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          @include font(Roboto-Regular, 0.875rem, normal, normal, normal, 1.5em, normal);
          color: #fff;
        }
      }
    }
  }

  .hotels-gallery {
    background: $bg_clr0;
  }

  .tabs-slider-block {
    > :first-child {
      padding: 0;
    }
    > :last-child {
      padding: 0;
    }
    > div {
      padding-bottom: 16px;
    }
    .ant-tabs-tab {
      @include font(Roboto-Regular, 1rem, normal, normal, normal, 1.5em, normal);
      padding-top: 6px;
      padding-bottom: 6px;
      text-align: center;
      width: 240px;
      margin: 0;
      cursor: initial;
      display: flex;
      justify-content: center;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    .hotel-attributes {
      .tab-description {
        display: inline-block;
        font-size: 1rem;
        color: $txt_clr4;
        p {
          margin-bottom: 32px;
        }
      }
    }

    .custom-collapse.text-block {
      border: none;

      .hotel-languages {
        font-size: 1.1rem;
        margin-top: 12px;
      }

      > .ant-collapse-item {
        > .ant-collapse-header {
          padding: 0;
          margin-top: 0;
          color: $txt_clr9;
          font-size: 1rem;
          margin-bottom: 24px;
          text-decoration: underline;
        }
      }

      .ant-collapse-content-box {
        color: $txt_clr4;
        @include font(Roboto-Light, 1rem, normal, normal, normal, 1.5em, normal);
      }
    }
    .tabs-slider-content:nth-child(2) {
      margin-top: 0;
    }
    .tabs-slider-content:nth-child(3) {
      .services-block .service-row {
        margin-bottom: 16px;
      }
    }

    .tabs-slider-content {
      margin-top: 32px;
      color: $txt_clr4;
      @include font(Roboto-Light, 1rem, normal, normal, normal, 1.5em, normal);
      padding-bottom: 0;

      .description-txt {
        font-family: Roboto-Light, sans-serif;
        font-size: 1rem !important;
        margin-bottom: 16px;

        &.pad-top {
          margin-bottom: 0;
          margin-top: 12px;
        }
      }

      .color-txt {
        color: $txt_clr9;
        text-decoration: underline;
      }

      h3 {
        @include font(Roboto-Medium, 1.5rem, 500, normal, normal, 1.75rem, normal);
        margin-bottom: 16px;

        &.pad-top {
          margin-top: 12px;
        }
      }

      .collapse-box {
        padding-right: 2px;
        p {
          margin-bottom: 32px;
        }
      }

      .services-block {
        &.with-description {
          .service-row {
            margin-bottom: 16px;
          }
        }
        &.top-mg {
          margin-top: 20px;
        }

        & + .custom-collapse.text-block {
          margin-top: 16px;
        }

        .service-row {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 32px;

          &.bot-mg {
            margin-bottom: 5px !important;
          }

          &.pad {
            padding-top: 27px;
          }

          > div {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            flex-grow: 1;
            max-width: 25%;
            min-width: 25%;
            word-break: break-word;
            padding-bottom: 4px;

            .custom-icon {
              background-size: cover;
              margin-top: 3px;
            }

            > span {
              @include font(Roboto-Light, 1rem, normal, normal, normal, 1.5rem, normal);
              padding: 0 15px 0 8px;
              color: $txt_clr6;
            }
          }
        }

        > :last-child:not(.tab-description) {
          padding-bottom: 0;
        }

        .room-notice {
          margin-top: 0;
          line-height: 1.6em;
        }

        &.service-icons {
          div {
            > span {
              font-family: Roboto-Medium, sans-serif;
            }
          }
          .circle-container.service-row {
            margin-bottom: 1px;
            > div {
              padding-bottom: 15px;
              span {
                font-family: Roboto-Regular, sans-serif;
              }
            }
          }
        }
      }

      .services-block.size-3 {
        &:last-of-type {
          .service-row {
            margin-bottom: 0;
          }
        }
        .service-row {
          > div {
            max-width: 33.3%;
            min-width: 33.3%;
            width: 100%;
          }
        }
      }

      .grid-container {
        width: $pcBlock;
        display: grid;
        grid-template-columns: 222px 954px;
        grid-auto-rows: auto;
        grid-column-gap: 24px;
        grid-row-gap: 16px;
        font-family: Roboto-Light, sans-serif;
        padding-top: 20px;

        .right {
          display: flex;
          flex-direction: row;

          .circle-block {
            box-shadow: none;
            min-width: 16px;
            max-width: 16px;
            min-height: 16px;
            max-height: 16px;
            margin-right: 12px;
          }

          .title {
            font-family: Roboto-Medium, sans-serif;
            font-size: 1rem;
            line-height: 1.125em;
          }
        }

        .left {
          .tourist-tax {
            margin-bottom: 0;
          }

          &.time-line {
            margin-bottom: 16px;
          }
          p.no-pad {
            margin-bottom: 0;
          }

          .popup-action {
            text-decoration: underline;

            &:hover {
              color: $txt_clr8;
            }
          }

          .txt-delimiter {
            display: inline-block;
            margin-bottom: 14px;
          }

          &.payments-block {
            display: flex;
            flex-direction: column;
          }

          .payments {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 12px;

            .payment {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-right: 32px;
              > :first-child {
                margin-right: 8px;
              }
              .visa-electron {
                height: 44px;
                width: 28px;
              }
            }
          }
        }
      }
    }
  }

  .hotels-search-wrapper {
    @include centerItems();
    padding: 32px 0 48px 0;
    width: 100%;
    background: $bg_clr0;
  }

  .footer-img-container,
  .hotels-cards-block {
    background: $bg_clr0;
    width: 100%;
  }

  .hotels-cards-block {
    padding-bottom: 80px;
  }

  .time-line {
    width: 100%;
    position: relative;
    margin-bottom: 36px;

    .time-zone {
      position: relative;
      z-index: 3;
      width: 100%;
      display: flex;
      flex-direction: row;

      li {
        position: relative;
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        .time-figure {
          position: absolute;
          right: -12px;
          bottom: 20px;
          font-family: Roboto-Light, sans-serif;
          color: $txt_clr1;
          font-size: 0.625rem;
          line-height: 1.4em;
        }

        .zone-delimiter {
          display: block;
          width: 1px;
          height: 18px;
          border-right: 1px dotted $brdr_clr6;
        }
      }
    }

    .sides-wrapper {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 12px;
      border-radius: $inputRadius;

      .empty-side {
        border-top: 0;
        position: absolute;
        border-radius: $inputRadius;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: $bg_clr1;
      }

      .color-side {
        border-top: 0;
        z-index: 2;
        position: absolute;
        height: 100%;
        background-color: $bg_clr8;

        .time-figure {
          position: relative;
          top: 30px;
          font-family: Roboto-Medium, sans-serif;
          font-size: 1rem;
          line-height: 1.125em;
        }

        .zone-delimiter {
          position: absolute;
          top: -6px;
          height: 48px;
          border-right: 2px solid $brdr_clr1;
        }
      }
    }
  }

  .time-line.from-right {
    .sides-wrapper {
      .color-side {
        right: 0;
        text-align: left;
        border-radius: 0 6px 6px 0;

        .time-figure {
          padding-left: 5px;
        }

        .zone-delimiter {
          left: -1px;
        }
      }
    }
  }

  .time-line.from-left {
    .sides-wrapper {
      .color-side {
        left: 0;
        text-align: right;
        border-radius: 6px 0 0 6px;

        .time-figure {
          padding-right: 5px;
        }

        .zone-delimiter {
          right: -1px;
        }
      }
    }
  }

  .mobile-controls {
    display: none;
  }

  .custom-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 0;
  }

  .mobile-slider-container {
    position: relative;

    .mark-block {
      position: absolute;
      right: 20px;
      z-index: 10;
      top: 10px;

      .block-txt {
        display: none;
      }
    }

    .banner-profit,
    .banner-attention-container {
      z-index: 3;
      left: 26px;
      border-radius: 3px;
      font-size: 1rem;
    }
  }
}

@media only screen and (max-width: $tabletScreen) {
  .hotel-rooms {
    .wrapper-card-room {
      &:not(:first-child) {
        margin-top: 12px;
      }
    }

    .hotels-search-block {
      width: $tabletBlock;
    }

    .room-gallery-wrapper {
      .header-block {
        width: 975px;

        .description-block {
          .description-address {
            width: 100%;
            display: flex;
            //flex-direction: column;
            position: initial;

            .location-wrapper {
              cursor: pointer;

              .custom-icon {
                margin: 0 8px 0 0;
              }
            }
          }
        }

        .btns-block {
          button {
            width: 205px;
          }
        }
      }

      .fixedNav {
        .ant-affix {
          .tabs-slider-block {
            width: $tabletBlock;
          }
        }
      }

      .room-gallery {
        width: $tabletBlock;
      }
    }

    .tabs-slider-block {
      .ant-tabs-tab {
        width: 195px;
      }

      .tabs-slider-content {
        padding: 0 16px;

        .grid-container {
          width: $tabletBlock;
          grid-template-columns: 222px 729px;
        }
      }
    }
  }
}

@media only screen and (max-width: $mobileScreen) {
  .hotel-rooms {
    .empty-results {
      margin-top: 40px;
    }

    .hotel-location-menu {
      display: none;
    }

    .room-gallery-wrapper {
      .room-preview {
        display: none;
      }

      .fixedNav {
        .ant-affix {
          .tabs-slider-block {
            width: 100%;
          }
        }
      }

      .header-block {
        width: 100%;
        padding: 0 16px;

        .description-block {
          padding: 0;

          > div {
            .mark-block {
              display: none;
            }
          }

          h1 {
            position: relative;
            font-size: 1.125rem;
            line-height: 1.1666em;
            max-width: initial;

            > span {
              display: flex;
              flex-direction: column;

              .stars-block {
                @include centerItems();
                justify-content: flex-start;
                margin: 0;
                padding: 8px 0;

                .custom-icon-star {
                  top: initial;
                }
              }
            }
          }

          p {
            font-size: 0.875rem;
            line-height: 1.714em;

            .address-wrapper {
              margin-right: 14px;
            }

            .location-wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
            }
          }
        }

        .btns-block {
          display: none;
        }
      }

      .room-gallery {
        width: 100%;
      }
    }

    .hotels-search-block {
      width: 100%;

      h3 {
        font-size: 1.125rem;
        line-height: 1.1666em;

        :last-child {
          font-size: 1.125rem;
          line-height: 1.25em;
        }
      }

      h3.form-header {
        font-size: 1.125rem;
        line-height: 1.1666em;

        :last-child {
          font-size: 1.125rem;
          line-height: 1.1666em;
        }
      }
    }

    .tabs-slider-block {
      .tabs-slider-content {
        //padding: 0 16px;

        .grid-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-top: 0;

          .right {
            margin-bottom: 20px;

            .mega-sm {
              display: none;
            }
          }

          .left {
            margin-bottom: 20px;

            .color-side {
              .time-figure {
                top: 23px;
              }
            }
          }
        }

        .collapse-box {
          font-size: 0.875rem;
        }

        h3 {
          font-family: Roboto-Medium, sans-serif;
          font-size: 1rem;
          line-height: 1.187em;
          margin: 20px 0 20px 0;

          > :last-child {
            font-family: Roboto-Medium, sans-serif;
          }
        }

        .services-block {
          &.size-3 .service-row > div {
            max-width: 100%;
          }

          .service-row {
            flex-direction: column;
            padding: 0;

            & > div {
              margin-bottom: 15px;
              max-width: 100%;
            }
          }

          > :last-child {
            > :last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .mobile-controls {
      display: flex;
      padding: 24px 16px 40px 16px;
      flex-direction: column;

      button {
        width: 100%;
      }

      > :first-child {
        margin-bottom: 24px;
      }
    }

    .hotels-search-wrapper {
      padding: 23px 0 40px 0;

      .hotels-search-block {
        h3 {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          margin-bottom: 16px;

          :last-child {
            font-family: 'Roboto-Medium', sans-serif;
            color: $txt_clr4;
          }
        }

        .pad-16 {
          padding: 16px 16px 0;

          .custom-empty-btn {
            font-weight: bold;
          }
        }

        .room-btns {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .custom-empty-btn {
            flex-basis: 50%;
            padding: 0 8px;
            height: 40px;
            font-size: 0.8rem;
            border-radius: $smallInputRadius;

            &:not(:last-child) {
              margin-right: 8px;
            }
          }
        }

        .search-results {
          padding: 0 16px;
        }
      }
    }
  }
}

.hotel-rooms {
  &.offer {
    margin: 0 auto;
    display: block;
    width: 1200px;
    background-color: white;
    overflow: hidden;

    @media only screen and (max-width: $tabletScreen) {
      width: 975px;
    }

    @media only screen and (max-width: $mobileScreen) {
      width: 100%;
    }

    & .fixedNav {
      padding-top: 15px;
    }

    & .offer-wrapper {
      padding-top: 30px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      @media only screen and (max-width: $mobileScreen) {
        padding: 15px 15px 0;
        flex-direction: column;
      }
    }

    & .offer-left {
      width: 36%;

      @media only screen and (max-width: $mobileScreen) {
        width: 100%;
        margin-bottom: 20px;
      }

      & .slider-container {
        position: relative;
        width: 100%;
        max-height: 250px;

        @media only screen and (max-width: $mobileScreen) {
          max-height: 200px;
        }

        .slider-wrapper.mobile-gallery {
          @media only screen and (max-width: $mobileScreen) {
            height: 100%;
          }
        }

        .slider-wrapper.mobile-gallery.room-view .ant-carousel .slick-slide {
          @media only screen and (max-width: $mobileScreen) {
            height: 200px;
          }
        }

        .slider-wrapper.mobile-gallery.room-view .slider-item {
          @media only screen and (max-width: $mobileScreen) {
            height: 200px;
          }
        }

        & .banner-profit {
          z-index: 1;
        }

        & .images-count {
          > i {
            margin-bottom: 12px;
          }

          position: absolute;
          bottom: 20px;
          right: 22px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          @include font(Roboto-Regular, 0.875rem, normal, normal, normal, 1.5em, normal);
          color: #fff;
        }
      }

      & .offer-hotel-name {
        padding-top: 15px;
        margin-bottom: 25px;

        & .offer-hotel-services .circle-container {
          padding: 15px 10px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;

          & svg {
            > path {
              fill: $bg_clr14;
            }
          }
        }
      }

      & .hotel-name-rating {
        margin-bottom: 2px;
      }

      & .description-address {
        display: flex;
        flex-direction: column;
        font-size: 0.75rem;
      }

      & .location-wrapper {
        padding-top: 5px;
        color: $txt_clr9;
        font-weight: bold;

        & svg {
          margin-right: 3px;
        }
      }

      & .offer-service-wrapper {
        padding: 10px 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 15px 5px;
      }

      & .offer-room-amenities-total {
        padding-top: 30px;
        border-top: 1px solid #0b83c7;

        & .offer-room-title {
          margin-bottom: 10px;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;

          span span {
            font-size: 0.8125rem;
            text-decoration: underline;
          }
        }
      }

      & .offer-room-preview-images {
        margin-bottom: 15px;

        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      & .offer-room-preview-images-grid {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: repeat(2, max-content);
        gap: 5px;

        & .offer-room-image {
          line-height: 0;
        }

        & .offer-room-image:first-child {
          grid-row-end: span 2;
        }
      }

      & .offer-room-amenities-title {
        display: block;
        font-size: 1.25rem;
        font-weight: 600;
      }

      & .offer-room-amenities {
        padding-top: 25px;
        border-top: 1px solid #0b83c7;

        & .offer-room-amenities-list {
          padding-top: 15px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 15px;

          & span {
            text-decoration: underline;
          }
        }
      }

      & .offer-service {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 0.8rem;

        & svg {
          margin-right: 5px;
          width: 20px;
          height: 20px;

          > path {
            fill: $bg_clr14;
          }
        }
      }

      & .offer-room-services-block {
        padding-bottom: 30px;
      }
    }

    .offer-right {
      width: 60%;

      @media only screen and (max-width: $mobileScreen) {
        width: 100%;
      }

      & #hotel-rooms {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: $mobileScreen) {
  .hotels-search-wrapper {
    padding-top: 0;
  }
}
