@import '../../../variables';

.main-footer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  min-height: 307px;
  background: linear-gradient(to right, $bg_clr1 0%, $bg_clr1 50%, $bg_clr5 50%, $bg_clr0 50%, $bg_clr0 100%);
  width: 100%;

  .footer-right,
  .footer-left {
    padding-top: 26px;
    padding-bottom: 8px;
  }

  .footer-right {
    flex-grow: 1;
    max-width: 815px;
    padding-top: 0;
    padding-left: 24px;
    background: $bg_clr0;
    height: 100%;
    @include font(Roboto-Light, 0.875rem, normal, normal, normal, 2em, normal);

    nav {
      border-top: 1px solid $brdr_clr2;
      border-bottom: 1px solid $brdr_clr2;
      padding: 26px 0;
      display: flex;
      flex-direction: row;

      .tabs-list {
        flex-grow: 1;

        > :first-child {
          font-size: 1rem;
          font-family: Roboto-Regular, sans-serif;
        }

        li {
          span {
            display: inline-block;
            padding: 2px 4px;
          }

          @media only screen and (min-width: $tabletScreen) {
            &:hover {
              background-color: $bg_clr13;
            }
          }
        }
      }
    }

    .private-rules {
      margin-top: 10px;
      font-size: 0.75rem;
      line-height: 1.333em;
    }
  }

  .footer-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 360px;
    height: 100%;
    color: $txt_clr10;

    .custom-icon {
      height: 25px;
      min-width: 200px;
      max-width: 200px;
      margin-right: auto;
    }

    address {
      margin: 32px 0 0 50px;

      .circle-block {
        box-shadow: none;
      }

      .phone-number {
        @include font(Roboto-Medium, 1.437rem, normal, normal, normal, 1.173em, 0.04px);
      }

      .working-time {
        margin-top: 5px;
      }

      .email-address {
        margin: 22px 0 27px 0;
        @include font(Roboto-Light, 1rem, normal, normal, normal, 1.5em, normal);
        color: $txt_clr10;
      }

      .social-network {
        display: flex;
        flex-direction: row;

        i {
          margin-right: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: $tabletScreen) {
  .main-footer {
    .footer-left {
      width: 285px;
    }

    .footer-right {
      max-width: 642px;
    }
  }
}

@media only screen and (max-width: $mobileScreen) {
  .main-footer {
    background: none;
    min-height: initial;

    .footer-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 24px 0;
      background-color: $bg_clr1;

      > :first-child {
        margin-bottom: 20px;
      }

      address {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;

        .email-address {
          margin: 20px 0;
        }
      }
    }

    .footer-right {
      display: none;
    }
  }
}
